import axios from 'axios';

// Use for local development
const localBaseUrl = 'https://product.hertex-staging.net';

const localCartBaseUrl = 'https://cart.hertex-staging.net';

// Use for production
// ! When the redirect happens in keycloak it adds it's own parameters to the url. The logic below strips out those parameters and makes the urls dynamic
const url = window.location.href;

const dotIndex = url.indexOf('.');

const slashIndex = url.indexOf('/', dotIndex);

const result = url.substring(dotIndex + 1, slashIndex);

const prodUrl = `https://product.${result}/`;

const cartProdUrl = `https://cart.${result}/`;

export const baseURL = axios.create({
    baseURL: prodUrl,
});

export const cartBaseURL = axios.create({
    baseURL: cartProdUrl,
});
