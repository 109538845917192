import { baseURL } from './baseUrl';

export const apiFilterProducts = async (filters: object) => {
    const response = await baseURL.post(`products/multi_filter`, filters, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiProductProperties = async (filters: object) => {
    const response = await baseURL.post(`products/stats`, filters, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiGetProductItemCount = async (filters: object) => {
    const response = await baseURL.post(`products/stats`, filters, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};
