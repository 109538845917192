export const faqsData = [
  {
      title: 'How do I apply for a position?',
      questions: [
          {
              text_1: 'If you are interested and meet the requirements, we would love to hear from you! Please send your CV to careers@hertex.co.za',
              text_2: '',
          },
      ],
  },

  {
      title: 'What happens once I’ve submitted my application?',
      questions: [
          {
              text_1: 'You will receive an email notification confirming that we have received your application. Be sure to check your inbox regularly or wait for a call from us.',
              text_2: '',
          },
      ],
  },
  {
      title: 'When can I expect to hear from you?',
      questions: [
          {
              text_1: 'From the minute you submit your application, please know that we’re working as fast as we can. A typical recruitment process, from application to offer, varies, and depends on the role you’ve applied for. Typically, it takes two months.',
              text_2: 'If you haven’t heard from us within 14 days, please consider your application as unsuccessful.',
          },
      ],
  },
  {
      title: 'When will I know if I have an interview?',
      questions: [
          {
              text_1: 'The process shouldn’t take longer than two weeks, but this depends on the position.',
              text_2: '',
          },
      ],
  },
  {
      title: 'What is the recruitment process like?',
      questions: [
          {
              text_1: 'You can expect a rigorous but fair and professional selection and interview process. We do our due diligence to make sure we choose the right people for the job. If you are selected as a possible candidate for a position, you will undergo a thorough screening and competency-based interview process. ',
              text_2: 'Depending on the role, this initial screening may also be followed by assessments and further interviews.',
          },
      ],
  },
  {
      title: 'What sort of salary and benefits package does Hertex offer?',
      questions: [
          {
              text_1: 'Details vary depending on the position. You can, however, expect a competitive salary and an attractive benefits package, including medical aid and provident fund contributions, staff discounts and more.',
              text_2: '',
          },
      ],
  },
  {
      title: 'What kind of career opportunities does Hertex offer?',
      questions: [
          {
              text_1: 'Hertex offers a wide range of exciting career opportunities for individuals who are passionate about the textile and homeware luxury industry. With a strong focus on innovation, quality and customer satisfaction, Hertex provides a dynamic and supportive work environment that fosters growth and development.',
              text_2: 'From sales and operations to finance, marketing, administration and management, Hertex offers various roles that cater to different skills and interests. Whether you’re a recent graduate or an experienced professional, Hertex provides opportunities for advancement, training and mentorship, allowing you to build a rewarding and challenging career in a company that values its employees and prioritises excellence.',
          },
      ],
  },
  {
      title: 'What if I don’t come across a job that suits me?',
      questions: [
          {
              text_1: 'If you don’t find an ideal opportunity right away, don’t worry. We still want to get to know you! That way, we can keep you in mind for future opportunities. Continue to keep an eye on this Careers page and be sure to follow us on LinkedIn.',
              text_2: 'You’re also welcome to send your CV to us. Send it to careers@hertex.co.za for Western Cape and Eastern Cape, or to careersinland@hertex.co.za for opportunities in Gauteng, Mpumalanga, KwaZulu-Natal, the Free State and Namibia.',
          },
      ],
  },
  {
      title: 'Does Hertex offer internships? ',
      questions: [
          {
              text_1: 'Yes, we do offer internships in various departments throughout the year, providing hands-on experience and valuable insight into the industry.',
              text_2: 'If you’re interested in doing an internship with us, send your CV along with a letter detailing why, where and when you would like to intern, to careers@hertex.co.za. In the subject line, please include the word “INTERNSHIP” followed by the region in which you’d like to be placed (for example, INTERNSHIP DURBAN).',
          },
      ],
  },
];
