import { useParams } from "react-router-dom";
import PageLayout from "../layout/PageLayout";
import { useQuery } from "react-query";
import { apiGetApplicationPageData } from "../../../api/apiApplications";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import MissingPage from "../errorMessages/MissingPage";
import CollectionsGrid from "../grid/CollectionsGrid";



const ApplicationTypeCategoryTemplate = () => {
  const { item_group, application_type, category_name } = useParams();

  let item_group_name;

  const argumentDataStructure = {
    category: { application_type: application_type, item_group: item_group },
    application_type_category : {category_name: category_name}
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: applicationData,
  } = useQuery<any, Error>(
    [`application_type-${application_type}`],
    () => apiGetApplicationPageData(argumentDataStructure),
    { enabled: !!application_type }
  );

  
  switch (category_name) {
    case '000':
      item_group_name = 'Homeware';
      break;
    case '001':
      item_group_name = 'Wallpaper';
      break;
    case '004':
      item_group_name = 'Rugs';
      break;
    case '005':
      item_group_name = 'Fabric';
      break;
    case '006':
      item_group_name = 'Bedroom';
      break;
    case '007':
      item_group_name = 'Illumination';
      break;
    case '008':
      item_group_name = 'Tableware';
      break;
    case '009':
      item_group_name = 'Furniture';
      break;
    case '010':
      item_group_name = 'Decorative';
      break;
    case '011':
      item_group_name = 'Tradehaus Rugs';
      break;
    case '013':
      item_group_name = 'Throws, Quilts and Furs';
      break;
    case '014':
      item_group_name = 'Wall Decor';
      break;
    case '015':
      item_group_name = 'Curtains';
      break;
    case '016':
      item_group_name = 'Bathroom';
      break;
    case '018':
      item_group_name = 'Scatters';
      break;
    default:
      item_group_name = undefined;
  }
  
  return (
    <PageLayout>
      <>
        {isError && <MissingPage />}
        {!isLoading && isSuccess ? (
          <>
            <CollectionsGrid
                data={applicationData?.section_collections_in_application.filter(
                  (collection: { collection_name: string }, index: number, self: Array<{ collection_name: string }>) => 
                    self.findIndex(t => t.collection_name === collection.collection_name) === index
                )}
                applicationName={`${item_group_name} ${application_type && (application_type?.charAt(0)?.toUpperCase() + application_type?.slice(1) ?? '')}`}
                marginTop={5}
                marginBottom={5}
              />
          </>
        ) : <LoadingScreen />}
      </>
    </PageLayout>
  );
};

export default ApplicationTypeCategoryTemplate;
