import React from "react";
import PaymentLayout from "../../components/shared/layout/PaymentLayout";
import PaymentContent from "../../components/shared/payment/PaymentContent";
import Tick from "../../assets/check_pay.svg";

const PaymentSuccess = () => {
  return (
    <PaymentLayout>
      <PaymentContent
        statusImage={Tick}
        header="Thank You -"
        subHeader="Your Payment Was Successful"
        paragraghHeader="Your order is currently being processed. "
        firstParagraph="You will receive a confirmation email shortly with details about your purchase and estimated delivery date."
        secondParagraph="Forheader queries regarding your order, feel free to contact our customer support team."
      />
    </PaymentLayout>
  );
};

export default PaymentSuccess;
