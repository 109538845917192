import { LayersOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface DashboardCardProps {
  title: string;
  value: number;
  link: string;
  displayViewBtn?: boolean;
}

const DashboardCard = ({ title, value, link, displayViewBtn }: DashboardCardProps) => {
  const navigate = useNavigate();
  const mobileDevice = useMediaQuery("(max-width:1199px)");
  return (
    <Box
      onClick={() => {
        if (mobileDevice) {
          navigate(link);
        }
      }}
      sx={{
        border: "1px solid var(--Gray-200, #EAECF0)",
        borderRadius: "12px",
        pt: { xs: "16px", lg: "24px" },
        pb: "16px",
        mb: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          px: "24px",
          pb: { xs: "0", lg: "24px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LayersOutlined />
          </Box>
          <Typography
            variant="caption"
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "32px",
              color: "var(--Gray-900, #101828)",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "32px",
            color: "var(--Gray-900, #101828)",
          }}
        >
          {value}
        </Typography>
      </Box>
      <Divider
        sx={{
          display: { xs: "none", lg: "block" },
        }}
      />
      <Box
        sx={{
          display: { xs: "none", lg: displayViewBtn ? "block"  : "none"},
          pt: "16px",
          px: "24px",
        }}
      >
        <Button
          variant="text"
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            color: "var(--Prmary-700, #030303)",
            textTransform: "capitalize",
          }}
          onClick={() => navigate(link)}
        >
          View
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardCard;
