export const pickUpPointsData = [
    {
        title: 'Western Cape',
        stores: [
            {
                title: 'Bellville - Distribution Centre',
                address: '17 Bell Street Stikland',
                suburb: 'Bellville',
                city: 'Cape Town',
                province: 'Western Cape',
                country: 'South Africa',
                postalCode: '7530',
            },
            {
                title: 'Bellville',
                address: '12 Bella Rosa Street',
                suburb: 'Bellville',
                city: 'Cape Town',
                province: 'Western Cape',
                country: 'South Africa',
                postalCode: '7550',
            },
            {
                title: 'Cape Town',
                address: '187 Upper Buitenkant Street',
                suburb: 'Gardens',

                city: 'Cape Town',
                province: 'Western Cape',
                country: 'South Africa',
                postalCode: '8001',
            },
            {
                title: 'George Showroom',
                address: '85 Meade St',
                suburb: '85 Meade St',
                city: 'George',
                province: 'Western Cape',
                country: 'South Africa',
                postalCode: '6530',
                phone: '044 884 1501',
            },
            {
                title: 'Somerset West Showroom',
                address: '10 Bright St',
                suburb: 'Somerset West',
                city: 'Cape Town',
                province: 'Western Cape',
                country: 'South Africa',
                postalCode: '7130',
                phone: '021 852 8152',
            },

            {
                title: 'HAUS Waterstone Store',
                address: 'Shop 33, Waterstone Village Cnr R44 &, Main Rd',
                suburb: 'Somerset',

                city: 'Cape Town',
                province: 'Western Cape',
                country: 'South Africa',
                postalCode: '7130',
                phone: '021 879 5869',
            },
            {
                title: 'Wynberg Showroom',
                address: '1 Carr Hill Rd, Wynberg, 7800',
                suburb: 'Wynberg',
                city: 'Cape Town',
                province: 'Western Cape',
                country: 'South Africa',
                postalCode: '7800',
                phone: '021 761 7734',
            },
        ],
    },

    {
        title: 'Eastern Cape',
        stores: [
            {
                title: 'Port Elizabeth',
                address: 'Cnr 45 Prospect Rd',
                suburb: 'Walmer',
                city: 'Gqeberha',
                province: 'Eastern Cape',
                country: 'South Africa',
                postalCode: '6064',

                phone: '041 373 2887',
            },
        ],
    },

    {
        title: 'Free State',
        stores: [
            {
                title: 'Bloemfontein Showroom',
                address: '11 Brill St',
                suburb: 'Westdene',
                city: 'Bloemfontein',
                province: 'Free State',
                country: 'South Africa',
                postalCode: '9301',
                phone: '051 430 2673',
            },
        ],
    },

    {
        title: 'Gauteng',
        stores: [
            {
                title: 'Midrand - Distribution Center',
                address: '28 De Winnaar Street',
                suburb: 'Midrand',
                city: 'Midrand',
                province: 'Gauteng',
                country: 'South Africa',
                postalCode: '1685',

                phone: '010 276 0100',
            },
            {
                title: 'Fourways Showroom',
                address: '82 The Straight Street',
                suburb: 'Pineslopes, Fourways Ext 68',
                city: 'Sandton',
                province: 'Gauteng',
                country: 'South Africa',
                postalCode: '2055',

                phone: '010 276 0100',
            },

            {
                title: 'Kramerville Showroom',
                address: '16 Kramer Rd',
                suburb: 'Kramerville',
                city: 'Sandton',
                province: 'Gauteng',
                country: 'South Africa',
                postalCode: '2090',

                phone: '011 262 4108',
            },
            {
                title: 'Parkhurst Showroom',
                address: '74 6th St',
                suburb: 'Parkhurst',
                city: 'Randburg',
                province: 'Gauteng',
                country: 'South Africa',
                postalCode: '2120',
                phone: '011 025 8483',
            },
            {
                title: 'HAUS Waterfall Store',
                address: 'Shop 51, Waterfall Corner',
                suburb: 'Waterfall Dr',
                city: 'Midrand',
                province: 'Gauteng',
                country: 'South Africa',
                postalCode: '2090',

                phone: '010 157 4121',
            },
            {
                title: 'Pretoria Showroom',
                address: '869 Justice Mahomed St',
                suburb: 'Brooklyn',
                city: 'Pretoria',
                province: 'Gauteng',
                country: 'South Africa',
                postalCode: '0011',
                phone: '012 346 4331',
            },

            {
                title: 'HAUS Hazelwood Store',
                address: '8 Hazelwood Rd',
                suburb: 'Menlo Park',
                city: 'Pretoria',
                province: 'Gauteng',
                country: 'South Africa',
                postalCode: '0081',
                phone: '012 880 1895',
            },
        ],
    },

    {
        title: 'KwaZulu Natal',
        stores: [
            {
                title: 'Durban Showroom',
                address: '327 Florida Rd',
                suburb: 'Morningside',
                city: 'Durban',
                province: 'KwaZulu Natal',
                country: 'South Africa',
                postalCode: '4001',
                phone: '031 312 0632',
            },

            {
                title: 'Umhlanga Showroom',
                address:
                    'Shop 13, Beacon Rock, 21 Lighthouse Rd',
                suburb: 'Umhlanga Rocks',
                city: 'uMhlanga',
                province: 'KwaZulu Natal',
                country: 'South Africa',
                postalCode: '4319',

                phone: '031 561 8505',
            },
        ],
    },

    {
        title: 'Mpumalanga',
        stores: [
            {
                title: 'Nelspruit Showroom',
                address:
                    'Unit 2, Lifestyle Centre, 6 Amanzi St',
                suburb: 'Riverside Park',
                city: 'Mbombela',
                province: 'Mpumalanga',
                country: 'South Africa',
                postalCode: '1200',

                phone: '013 757 0556',
            },
        ],
    },

    {
        title: 'Namibia',
        stores: [
            {
                title: 'Windhoek Showroom',
                address: 'Unit 2, City Plaza',
                suburb: 'Sam Nujoma Street',
                city: 'Windhoek',
                province: 'Windhoek',
                country: 'Nambia',
                postalCode: '10005',

                phone: '+264 61 219 5600',
            },
        ],
    },

    // {
    //     title: 'Mauritius',
    //     stores: [
    //         {
    //             title: 'Haus Hertex Mauritius',
    //             address: 'MU, Twenty-Foot Rd, Grand Baie 30513',
    //             suburb: 'MU, Twenty-Foot Rd, Grand Baie 30513',
    //             city: 'Mauritius',
    //             province: 'Mauritius',
    //             country: 'Mauritius',
    //             postalCode: '30513',

    //             phone: '+230 269 0200',
    //         },
    //     ],
    // },
];
