import { Typography, Grid, Box, Button, capitalize } from '@mui/material';

function TextHeader({
    heading,
    content,
    subContent,
    isCentered,
    width,
    marginTop,
    marginBottom,
    hasButton,
    buttonText,
    isColor,
    isWidthChange
}: any) {
    return (
        <>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>

<Grid
    mt={{xs:5, lg: marginTop}}
    mb={marginBottom}
    p={3}
    container
    justifyContent='center'
    alignItems='center'

    style={{ width: isWidthChange ? '80%' : '100%',
    background: isColor ? '#FFF' : 'transparent',


    
}}
>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: {xs: 'start', lg:'center'},
                        alignItems: {xs: 'start', lg:'center'},
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', sm: width },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: isCentered
                                ? 'center'
                                : 'flex-start',
                            alignItems: isCentered ? 'center' : 'flex-start',
                        }}
                    >
                        <Typography
                                mb={3}
                                variant='h3'
                                fontWeight={'500'}
                                textTransform={'none'}
                                sx={{
                                    fontSize: {xs: '44px', lg: '56px'}
                                }}
                        >
                            {heading.charAt(0).toUpperCase() + heading.slice(1)}

                        </Typography>

                        <Typography
                                fontSize={{xs: '16px', lg:'20px'}}
                                fontWeight={'400'}
                                lineHeight={'26px'}
                            sx={{ textAlign: isCentered ? 'center' : '', textTransform: 'none' }}
                        >
                            {content}
                        </Typography>

                        <Typography
                            mt={2}
                            fontSize={{xs: '16px', lg:'20px'}}
                            fontWeight={'400'}
                            lineHeight={'26px'}

                            sx={{ textAlign: isCentered ? 'center' : '', textTransform: 'none'  }}
                        >
                            {subContent}
                        </Typography>

                        {hasButton && <Button variant='outlined' sx={{borderRadius: '0',}}>
                            {buttonText}
                        </Button>}
                        
                    </Box>
                </Box>
            </Grid>
            </Box>

        </>
    );
}

export default TextHeader;
