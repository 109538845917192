import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { apiGetApplicationPageData } from '../../../api/apiApplications';
import PageLayout from '../layout/PageLayout';
import CarouselBanner from '../banner/CarouselBanner';
import TextHeader from '../textHeader/TextHeader';
import ApplicationsGrid from '../grid/ApplicationsGrid';
import NewArrivalsGrid from '../grid/NewArrivalsGrid';
import FilterComponent from '../filter/FilterComponent';

import LoadingScreen from '../loadingScreen/LoadingScreen';
import FeaturedGallery from '../gallery/FeaturedGallery';
import { capitalizeString } from '../../../utilities/TextManipulation';
import BrowseByTitle from '../search/BrowseByTitle';
import { useEffect, useState } from 'react';
import MissingPage from '../errorMessages/MissingPage';
import CatalogueGrid from '../grid/CatalogueGrid';
import CatalogueImage from '../../../assets/catalogueImage.svg';
import ItemGrid from '../grid/ItemGrid';
import CollectionsGrid from '../grid/CollectionsGrid';
import { catalogueData } from '../../../api/tempData';
import ZipDownloader from '../fileDownloads/ZipDownloader/ZipDownloader';
import { captureAnalytics } from '../../../utilities/Analytics';

const brands = [
    { title: 'Hertex' },
    { title: 'Stone Haus' },
    { title: 'Care Free' },
    { title: 'Couture' },
    { title: 'Studio H' },
    { title: 'Internationals' },
];

const catalogueImage = [
    { id: '01', imgRoute: CatalogueImage },
    { id: '02', imgRoute: CatalogueImage },
    { id: '03', imgRoute: CatalogueImage },
];

function ApplicationsTemplate() {
    const [applicationString, setApplicationString] = useState<string>();
    const { item_group, application } = useParams();
    const location = useLocation();

    const argumentDataStructure = {
        category: { application: application, item_group: item_group },
    };

    const {
        isSuccess,
        isError,
        isLoading,
        data: applicationData,
    } = useQuery<any, Error>(
        [`application-${application}`],
        () => apiGetApplicationPageData(argumentDataStructure),
        { staleTime: 600000 }
    );
    //! In progress - working in tandem with the multifilter
    const getInitialApplication = () => {
        switch (application) {
            case 'curtaining':
                return ['Curtaining', 'Dual Purpose'];
            case 'dual purpose':
                return ['Dual Purpose'];
            case 'upholstery':
                return ['Upholstery', 'Dual Purpose'];
            case 'compact runners':
                return ['Compact Runners'];
            case 'hallway runners':
                return ['Hallway Runners'];
            case 'kitchen runners':
                return ['Kitchen Runners'];
            case 'rugs':
                return ['Rugs'];
            case 'hertex wallpaper':
                return ['Hertex Wallpaper'];
            default:
                return [];
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        // ! This capitilization needs to be done on the get-all-cms call on the backend

        setApplicationString(capitalizeString(application));
    }, [application]);

    useEffect(() => {
        captureAnalytics(
            'pageview',
            location.pathname,
            `application-${application}`
        );
    }, [location.pathname, application]);

    return (
        <>
            <PageLayout>
                <>
                    {isLoading && <LoadingScreen />}
                    {isError && <MissingPage />}
                    {isSuccess && (
                        <>
                            <CarouselBanner
                                images={applicationData?.section_hero}
                                video={
                                    applicationData?.section_hero_video
                                        ?.video_url
                                }
                            />

                            <TextHeader
                                heading={applicationData?.page_title}
                                content={applicationData?.description}
                                isCentered={false}
                                width='70%'
                                marginTop={10}
                                marginBottom={5}
                            />

                            {/* {
                                <ApplicationsGrid
                                    data={
                                        applicationData.section_application_cards
                                    }
                                    hasTitles={false}
                                    hasButtons={false}
                                    hasBackgroundText={true}
                                    spacingValue={4}
                                    marginTop={15}
                                    marginBottom={30}
                                />
                            } */}

                            {/* <BrowseByTitle
                                title={brands}
                                heading='Discover By Brand'
                                marginTop={15}
                                marginBottom={15}
                            /> */}

                            <FeaturedGallery
                                images={
                                    applicationData?.section_featured_collection
                                }
                                hasVideo={false}
                                invert={false}
                                marginTop={5}
                                marginBottom={5}
                                title={
                                    applicationData?.featured_collection_title
                                        ?.collection_name
                                }
                                description={
                                    applicationData?.featured_collection_description
                                }
                                itemGroup={
                                    applicationData?.featured_collection_title
                                        ?.item_group_code
                                }
                                application={
                                    applicationData?.featured_collection_title
                                        ?.application_name
                                }
                                collection={
                                    applicationData?.featured_collection_title
                                        ?.collection_name
                                }
                            />

                            <FilterComponent
                                itemGroup={item_group}
                                title={applicationString}
                                initialApplications={getInitialApplication()}
                            />

                            <CollectionsGrid
                                data={
                                    applicationData?.section_collections_in_application
                                }
                                applicationName={`${application}`}
                                marginTop={5}
                                marginBottom={5}
                            />

                            <NewArrivalsGrid
                                header='New arrivals'
                                data={applicationData?.section_new_arrivals}
                                hasTitles={true}
                                hasButtons={true}
                                hasBackgroundText={false}
                                spacingValue={4}
                                marginTop={'5rem'}
                                marginBottom={'5rem'}
                            />
                            <CatalogueGrid
                                header='View our latest catalogues'
                                catalogueData={catalogueData}
                            />
                        </>
                    )}
                </>
            </PageLayout>
        </>
    );
}

export default ApplicationsTemplate;
