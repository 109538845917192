import { Box, Grid, Typography } from '@mui/material';

interface ResourcesCatalogueGridProps {
    header: string;
    data: { id?: string; img: string; url: string }[];
}

const ResourcesCatalogueGrid = ({
    header,
    data,
}: ResourcesCatalogueGridProps) => {
    return (
        <Box
            sx={{
                py: { xs: '20px', lg: '50px' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
            }}
        >
            <Typography
                fontWeight='600'
                lineHeight={'24px'}
                fontSize={'18px'}
                mb={'20px'}
            >
                {header}
            </Typography>

            <Grid container spacing={2}>
                {data?.map((elem, index) => (
                    <Grid item xs={12} sm={6} md={2} key={index}>
                        <Box
                            sx={{
                                width: {
                                    xs: '100%',
                                    md: '100%',
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={() => {
                                window.open(elem?.url, '_blank');
                            }}
                        >
                            <img
                                src={elem?.img}
                                alt={`Catalogue ${index + 1}`}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ResourcesCatalogueGrid;
