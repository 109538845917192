// import AdminPortalPageLayout from '../../components/shared/adminPortal/AdminPortalPageLayout';
import AdminPortalPageLayout from "../components/AdminPortalPageLayout";
import DashboardHeader from "../components/header/DashboardHeader";
import SupportCard from "../components/card/SupportCard";
import { Box } from "@mui/material";

function SupportPage() {
  return (
    <AdminPortalPageLayout>
      <DashboardHeader
        title="Support"
        subTitle="Having trouble with your account?"
      />
      <Box
        sx={{
          mt: { xs: "20vh", lg: "0" },
        }}
      >
        <SupportCard
          title="Email Us"
          value="orders@hertex.co.za"
          link="orders@hertex.co.za"
        />
        <SupportCard title="Call Us " value="0219484764" />
      </Box>
    </AdminPortalPageLayout>
  );
}

export default SupportPage;
