import { baseURL } from "./baseUrl";

export const apiGetAllCategories = async () => {
  const response = await baseURL.get("get-all-categories");
  return response.data;
};

export const apiGetCategoriesByLimit = async () => {
  const response = await baseURL.get(
    "get-products-by-cat-limited?cat=fabric&start=0&stop=3"
  );
  return response.data;
};

export const apiGetApplications = async (category: string) => {
  const response = await baseURL.get(`get-applications-by-cat?cat=${category}`);
  return response.data;
};

export const apiGetApplicationNamesByItemGroup = async (
  itemGroup: string
): Promise<{ appliactions: string[] }> => {
  const response = await baseURL.get(
    `products/item_group/get_all_applications/${itemGroup}`
  );
  const data = response.data as { appliactions: string[] };
  return data;
};

export const apiGetCategoryMainPageData = async (category: object) => {
  const response = await baseURL.post(`get-all-cms-data`, category, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
