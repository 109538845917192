import { Button, CircularProgress } from '@mui/material';
import JSZip from 'jszip';
import { useMutation } from 'react-query';
import { apiGetImage } from '../../../../api/fileServer';
import { useState } from 'react';

interface FileData {
    filename: string;
    url: string;
}

interface ZipDownloaderProps {
    fileData: FileData[];
}

const ZipDownloader = ({ fileData }: ZipDownloaderProps) => {
    const mutation = useMutation((data: string) => {
        return apiGetImage(data);
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const zip = new JSZip();

    const zipData = async () => {
        const fileDataList = fileData;

        const urlList: string[] = [];

        fileDataList.map((image: any) => {
            urlList.push(image.filename);
        });

        const fetchImages = urlList.map(
            async (filename: string, index: number) => {
                const result = await mutation.mutateAsync(filename);

                const fileName = result.filename;

                const fileUrl = result.url;

                const response = await fetch(fileUrl);

                const blob = await response.blob();

                return { blob, index, fileName };
            }
        );

        try {
            setIsLoading(true);
            const results = await Promise.all(fetchImages);

            results.forEach(({ blob, index, fileName }) => {
                zip.file(`${fileName}`, blob);
            });

            const zipFiles = await zip.generateAsync({
                type: 'blob',
                streamFiles: true,
            });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(zipFiles);

            link.download = 'Hertex-Images.zip';

            link.click();

            setIsLoading(false);
        } catch (error) {
            console.error('Error creating zip file', error);
        }
    };

    return (
        <>
            <Button
            
            disableElevation
                sx={{ mt: 3, }}
                variant='contained'
                endIcon={
                    isLoading ? (
                        <CircularProgress color='secondary' size={25} />
                    ) : (
                        ''
                    )
                }
                onClick={() => {
                    zipData();
                }}
            >
                Download All
            </Button>
        </>
    );
};

export default ZipDownloader;
