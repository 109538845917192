import { Breadcrumbs, Typography, Link, Box } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type ResourcesHeaderProps = {
    productDataItems: any;
    application: any;
    itemGroup: any;
    collection: any;
    itemGroupName: any;
};

const ResourcesHeader = ({
    productDataItems,
    application,
    itemGroupName,
    collection,
}: ResourcesHeaderProps) => {
    return (
        <Box>
            <Breadcrumbs
                sx={{
                    display: {
                        xs: 'block',
                    },
                }}
                separator={<NavigateNextIcon fontSize='small' />}
                aria-label='breadcrumb'
            >
                <Link
                    color='inherit'
                    sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                    }}
                >
                    {itemGroupName}
                </Link>
                <Link
                    color='inherit'
                    sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                    }}
                >
                    {application}
                </Link>
                <Link
                    color='inherit'
                    sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                    }}
                >
                    {collection}
                </Link>
            </Breadcrumbs>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 'auto',
                }}
            >
                <Box>
                    <Typography
                        mt={1}
                        variant='h1'
                        fontSize={'56px'}
                        fontWeight={'500'}
                        lineHeight={'60px'}
                    >
                        {productDataItems?.page_title}
                    </Typography>
                </Box>
            </Box>
            <Typography
                mt={2}
                fontSize={'16px'}
                fontWeight={'400'}
                lineHeight={'25px'}
            >
                {productDataItems?.description}
            </Typography>
        </Box>
    );
};

export default ResourcesHeader;
