import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { CheckBox } from "@mui/icons-material";

function OrderItem({ setOpenModal, openModal, orderListData, itemId }: any) {
  const handleClose = () => setOpenModal(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // ! May revert later
  // const handleNext = () => {
  //     const isLastItem = currentIndex === orderListData.length - 1;
  //     const newIndex = isLastItem ? 0 : currentIndex + 1;

  //     setCurrentIndex(newIndex);
  // };

  // const handlePrevious = () => {
  //     const isFirstItem = currentIndex === 0;
  //     const newIndex = isFirstItem
  //         ? orderListData.length - 1
  //         : currentIndex - 1;

  //     setCurrentIndex(newIndex);
  // };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", lg: 800 },
    height: { xs: "auto", lg: "auto" },
    bgcolor: "background.paper",

    boxShadow: 24,
    borderRadius: "12px",
  };


  const handleDateTimeStampConversion = (timeStamp: number) => {
    const date = new Date(timeStamp);
    const dateString = date.toLocaleDateString();
    return dateString;
  };
  useEffect(() => {
    orderListData.filter((elem: any) => {
      if (elem?.items[0]?.order_number === itemId) {
        setCurrentIndex(orderListData.indexOf(elem));
      }
    });
  }, [itemId, orderListData]);

  const orderDetails = [
    {
      id: "1",
      title: "Order No.",
      value: orderListData[currentIndex]?.items[0]?.order_number,
    },
    {
      id: "2",
      title: "Name",
      value: `${orderListData[currentIndex]?.order?.name} ${orderListData[currentIndex]?.order?.surname}`,
    },
    {
      id: "3",
      title: "Order Date",
      value: handleDateTimeStampConversion(
        orderListData[currentIndex]?.items[0]?.dt_stamp
      ),
    },
    {
      id: "4",
      title: "Status",
      value: orderListData[currentIndex]?.order?.order_status,
    },
  ];

  const orderInfo = [
    {
      id: "1",
      title: "Stock Link",
      value: orderListData[currentIndex]?.items[0]?.stock_link,
    },
    {
      id: "2",
      title: "Price",
      value: `R ${orderListData[currentIndex]?.items[0]?.price}`,
    },
    {
      id: "3",
      title: "Quantity",
      value: orderListData[currentIndex]?.items[0]?.qty_sold,
    },
    {
      id: "4",
      title: "SO Number",
      value: orderListData[currentIndex]?.order?.so_number,
    },
    {
      id: "5",
      title: "Custom Order Number",
      value: orderListData[currentIndex]?.order?.customer_order_num,
    },
  ];

  const deliveryDetails = [
    { id: "1", value: orderListData[currentIndex]?.order?.shipping_address },
    { id: "2", value: orderListData[currentIndex]?.order?.shipping_address2 },
    { id: "3", value: orderListData[currentIndex]?.order?.shipping_address3 },
    { id: "4", value: orderListData[currentIndex]?.order?.shipping_address4 },
  ];


  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            // @ts-ignore
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Box>
              <Grid container sx={{ p: 2 }} justifyContent="space-between">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <Typography
                    id="transition-modal-title"
                    variant="caption"
                    sx={{
                      color: "#101828",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Order No.{" "}
                    {orderListData[currentIndex]?.items[0]?.order_number}
                  </Typography>
                  {orderListData[currentIndex]?.order?.order_status ===
                  "PROCESSING" ? (
                    <Button
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "10px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        background: "#FFC452",
                        borderRadius: "16px",
                        color: "#AD4900",
                        px: '10px',
                        height: '10px'
          
                      }}
                      size="small"
                    >
                      {orderListData[currentIndex]?.order?.order_status}
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "10px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        background: "#99EFA7",
                        borderRadius: "16px",
                        color: "#027A48",
                        px: '10px',
                        height: '10px'
          
                      }}
                      size="small"
                    >
                      {orderListData[currentIndex]?.order?.order_status}
                    </Button>
                  )}
                </Box>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
              </Grid>

              <Divider />
              <Box
                sx={{
                  p: "16px",
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: "space-between",
                  height: "100%",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        alignItems: "center",
                        py: "16px",
                      }}
                    >
                      <DescriptionOutlinedIcon />

                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#101828",
                        }}
                      >
                        Order Details
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        background: "#F9F9F9",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          py: "10px",
                        }}
                      >
                        {orderDetails.map((item) => (
                          <Box
                            key={item.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              px: "10px",
                            }}
                          >
                            <Typography variant="caption">
                              {item.title}
                            </Typography>{" "}
                            <Typography variant="caption">
                              {item.value}
                            </Typography>{" "}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                          alignItems: "center",
                          py: "16px",
                        }}
                      >
                        <FormatListNumberedIcon />

                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                            color: "#101828",
                          }}
                        >
                          Your Order
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          background: "#F9F9F9",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            py: "10px",
                          }}
                        >
                          {orderInfo.map((item) => (
                            <Box
                              key={item.id}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                px: "10px",
                              }}
                            >
                              <Typography variant="caption">
                                {item.title}
                              </Typography>{" "}
                              <Typography variant="caption">
                                {item.value}
                              </Typography>{" "}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        alignItems: "center",
                        py: "16px",
                      }}
                    >
                      <LocationOnOutlinedIcon />

                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#101828",
                        }}
                      >
                        Delivery Details
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        background: "#F9F9F9",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          py: "10px",
                        }}
                      >
                        {orderListData[currentIndex]?.order
                          ?.shipping_address === "" ? (
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: "cneter",
                              p: "16px",
                            }}
                          >
                            Address details unavailable.
                          </Typography>
                        ) : (
                          <>
                            {deliveryDetails.map((item) => (
                              <Box
                                key={item.id}
                                sx={{
                                  px: "16px",
                                }}
                              >
                                <Typography variant="caption">
                                  {item.value}
                                </Typography>{" "}
                              </Box>
                            ))}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Divider
                sx={{
                  mt: 2,
                  mb: 2,
                  width: "100%",
                }}
              />
              {/* !May revert later */}
              {/* <Grid sx={{ width: '100%' }}>
                                <Button onClick={handleNext}>prev</Button>
                                <Button onClick={handlePrevious}>next</Button>
                            </Grid> */}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default OrderItem;
