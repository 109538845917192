import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import navLogo from "../../../assets/logos/hertex-logo-long.png";
import { Close, Menu } from "@mui/icons-material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

const AdminPortalMobileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const user = JSON.parse(localStorage.getItem("user") || "");
  const [isMenu, setIsMenu] = useState(false);
  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  const orderPages = [
    { id: 1, title: "Dashboard", link: "/admin-portal-dashboard" },
    { id: 2, title: "Open Sales Order", link: "/admin-portal-open-orders" },
    { id: 3, title: "Closed Sales Order", link: "/admin-portal-closed-orders" },
  ];

  const pageLink = [
    { id: 1, title: "Support", link: "/admin-portal-support" },
    { id: 2, title: "Account Settings", link: "/admin-portal-settings" },
    { id: 3, title: "Logout" },
  ];

  const allPageTitles = () => {
    const allPages = [...orderPages, ...pageLink];
    const currentPage = allPages.find(
      (page) => page.link === location.pathname
    );
    return currentPage ? currentPage.title : "Page Not Found";
  };
  return (
    <>
      <AppBar
        position="fixed"
        color="secondary"
        elevation={0}
        sx={{
          display: { xs: "block", lg: "none" },
          width: "100%",
        }}
      >
        <Toolbar disableGutters>
          <Grid
            sx={{
              width: "100%",
              display: { xs: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              m: "0 0",
            }}
          >
            <Box
              onClick={() => navigate("/")}
              sx={{
                width: "150px",
                p: "16px",
              }}
            >
              <img
                src={navLogo}
                alt="nav logo"
                style={{
                  width: "100%",
                  cursor: "pointer",
                }}
              />
            </Box>
          </Grid>
        </Toolbar>
        <Box
          sx={{
            background: "#FFF",
            width: "100%",
            zIndex: 999,
            borderColor: "#FFF",
          }}
        >
          <Box
            sx={{
              background: "#000",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "#FFF",
                p: "16px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "FFF",
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                Trade Portal
              </Typography>
              <Button onClick={toggleMenu}>
                <Menu
                  style={{
                    color: "#FFF",
                  }}
                />
              </Button>
            </Box>
            <Drawer
              anchor="left"
              open={isMenu}
              onClose={toggleMenu}
              sx={{
                "& .MuiDrawer-paper": {
                  backgroundColor: "#FFF",
                  width: "100%",
                },
              }}
            >
              <Box
                sx={{
                  p: "16px",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <Button onClick={toggleMenu}>
                  <Close />
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "100vh",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    gap: "36px",
                    px: "8px",
                  }}
                >
                  {orderPages.map((item) => (
                    <Box
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: "Inter",
                        color: "#000",
                        fontSize: "22px",
                        fontWeight: "600",
                      }}
                      onClick={() => navigate(item.link)}
                    >
                      {item.title}
                    </Box>
                  ))}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    px: "8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    {pageLink.map((item) => (
                      <Box
                        sx={{
                          textTransform: "capitalize",
                          fontFamily: "Inter",
                          color: "#000",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                        onClick={() => {
                          if (item.title !== "Logout") {
                            if (item.link) {
                              navigate(item.link);
                            }
                          } else {
                            logout();
                          }
                        }}
                      >
                        {item.title}
                      </Box>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {user?.given_name} {user?.family_name}
                    </Typography>{" "}
                    <Typography
                      variant="caption"
                      sx={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {user?.email}{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Drawer>
            <Box
              sx={{
                background: "#383838",
                p: "16px",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "#FFF",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {allPageTitles()}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};

export default AdminPortalMobileMenu;
