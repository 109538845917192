import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { apiGetApplicationPageData } from "../../../api/apiApplications";
import PageLayout from "../layout/PageLayout";
import CarouselBanner from "../banner/CarouselBanner";
import TextHeader from "../textHeader/TextHeader";
import NewArrivalsGrid from "../grid/NewArrivalsGrid";

import LoadingScreen from "../loadingScreen/LoadingScreen";
import FeaturedGallery from "../gallery/FeaturedGallery";
import MissingPage from "../errorMessages/MissingPage";
import CatalogueGrid from "../grid/CatalogueGrid";
import CollectionsGrid from "../grid/CollectionsGrid";
import CategoriesOrCollectionsGrid from "../grid/CategoriesOrCollectionsGrid";
import { catalogueData } from "../../../api/tempData";
import { FileData, ItemGroupCode, apiGetAllItemGroupsInWebType } from "../../../api/apiProducts";
import { useState } from "react";


function ApplicationTypeTemplate() {
  const { item_group, application_type } = useParams();
  const [filteredImages, setFilteredImages] = useState<FileData[]>([]);

  const argumentDataStructure = {
    category: { application_type: application_type, item_group: item_group },
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: applicationData,
  } = useQuery<any, Error>(
    [`application_type-${application_type}`, application_type],
    () => apiGetApplicationPageData(argumentDataStructure),
    { enabled: !!application_type }
  );

  const getItemGroupName = (code: string): string | undefined => {
    const entry = Object.entries(ItemGroupCode).find(([,value]) => value === code);
    return entry ? entry[0] : undefined;
  };

  useQuery({
    queryKey: ['all-item-groups-in-web-type'],
    queryFn: () => apiGetAllItemGroupsInWebType('9'),
    onSuccess: (data) => {
      const itemGroupNames = data?.map((code: string) => getItemGroupName(code));

      const filteredImages = applicationData.section_item_group_images.filter((image: FileData) => {
        return itemGroupNames.some((name: string | undefined) => name?.toLowerCase().includes(image?.item_group?.toLowerCase() ?? ''))
      });

      setFilteredImages(filteredImages);
    }
  });

  return (
    <>
      <PageLayout>
        <>
          {isLoading && <LoadingScreen />}
          {isError && <MissingPage />}
          {isSuccess && (
            <>
              <CarouselBanner
                            images={applicationData?.section_hero}
                            video={applicationData?.section_hero_video?.video_url}
                        />

              <TextHeader
                heading={applicationData?.page_title}
                content={applicationData?.description}
                isCentered={false}
                width="70%"
                marginTop={10}
                marginBottom={5}
              />


              <CategoriesOrCollectionsGrid
                data={filteredImages}
                hasTitles={false}
                hasButtons={false}
                hasBackgroundText={true}
                spacingValue={6}
                hasCollectionLinks={false}
                hasApplicationType={true}
                marginTop={5}
                marginBottom={5}
              />

              <FeaturedGallery
                images={applicationData?.section_featured_collection}
                hasVideo={false}
                invert={false}
                marginTop={5}
                marginBottom={5}
                title={
                  applicationData?.featured_collection_title?.collection_name
                }
                description={applicationData?.featured_collection_description}
                itemGroup={
                  applicationData?.featured_collection_title?.item_group_code
                }
                application={
                  applicationData?.featured_collection_title?.application_name
                }
                collection={
                  applicationData?.featured_collection_title?.collection_name
                }
              />

              <CollectionsGrid
                data={applicationData?.section_collections_in_application.filter(
                  (collection: { collection_name: string }, index: number, self: Array<{ collection_name: string }>) => 
                    self.findIndex(t => t.collection_name === collection.collection_name) === index
                ) ?? []}
                applicationName={`${application_type}`}
                marginTop={5}
                marginBottom={5}
              />
              <NewArrivalsGrid
                header="New arrivals"
                data={applicationData?.section_new_arrivals}
                hasTitles={true}
                hasButtons={true}
                hasBackgroundText={false}
                spacingValue={4}
                marginTop={"5rem"}
                marginBottom={"5rem"}
              />
              <CatalogueGrid
                  header='View our latest catalogues'
                  catalogueData={catalogueData}
              />
            </>
          )}
        </>
      </PageLayout>
    </>
  );
}

export default ApplicationTypeTemplate;
