import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
} from '@mui/material';
import StockTable from '../pageTemplates/ProductTemplate/components/StockTable';

import { Close } from '@mui/icons-material';

function StockRollModal({
    modalIsOpen,
    handleCloseModal,
    stockRollData,
    isSuccess,
    isError,
    isLoading,
}: any) {
    return (
        <>
            <Dialog
                onClose={handleCloseModal}
                open={modalIsOpen}
                fullWidth
                maxWidth='md'
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <DialogTitle>
                        {' '}
                        <Typography
                            variant='caption'
                            sx={{
                                color: '#000',
                                fontSize: { xs: '16px', lg: '24px' },
                                fontWeight: '700',
                            }}
                        >
                            Meters on hand
                        </Typography>
                    </DialogTitle>
                    <Button
                        sx={{
                            px: '0',
                        }}
                        onClick={handleCloseModal}
                    >
                        <Close
                            style={{
                                width: '20px',
                                height: '20px',
                            }}
                        />{' '}
                    </Button>
                </Box>
                <DialogContent sx={{ width: 'auto', pb: '50px' }}>
                    <StockTable
                        stockRollData={stockRollData}
                        isSuccess={isSuccess}
                        isError={isError}
                        isLoading={isLoading}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default StockRollModal;
