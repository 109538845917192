import PageLayout from "../../components/shared/layout/PageLayout";
import TextHeader from "../../components/shared/textHeader/TextHeader";

// import {
//   carouselImages,
//   categoryImages,
//   internationals,
//   carouselVideos,
//   featuredHomeCollectionList,
//   trade,
// } from "../../api/tempData";

import responsibilityImage from "../../assets/responsibiltyCover.png";
import CompanyGrid from "../../components/shared/grid/CompanyGrid";
import CompanyBanner from "../../components/shared/grid/CompanyBanner";

// const data = {
//   heroData: internationals,
//   textHeaderData: {
//     heading: "Home Page",
//     content: "Home page description goes here",
//   },
//   carouselImages: carouselImages,
//   carouselVideos: carouselVideos,
//   categoryImages: categoryImages,
//   featuredCollectionList: featuredHomeCollectionList,
// };

const ResponsibilityPage = () => {
  return (
    <PageLayout>
      <CompanyBanner
        imageData={responsibilityImage}
        hasTitle={true}
        title="Responsibility"
      />

      <TextHeader
        heading="Our View on Responsibilities"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                "
        isCentered={false}
        width="70%"
        marginTop={10}
        marginBottom={5}
      />

      {/* <CompanyGrid
        marginTop={15}
        marginBottom={15}
        image={trade}
        isReverse={true}
        heading="Are you in the trade?"
        hasButtons={true}
        buttonTitle="LEARN MORE"
        buttonTitleTwo="LOGIN"
      /> */}
    </PageLayout>
  );
};

export default ResponsibilityPage;
