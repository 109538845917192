import { useState, useEffect } from 'react';
import UserInformation from './UserInformation';
import Address from './Address';
import EditDetails from './EditDetails';
import FormPageLayout from './FormPageLayout';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import {
    Typography,
    Box,
    Grid,
    Breadcrumbs,
    Container,
    Divider,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BackButton from '../../../components/shared/backButton/backbutton';

function CheckoutFormParent() {
    const { setFormSteps } = useShoppingCart();

    const [isShipping, setIsShipping] = useState<boolean>(false);
    const [isDefaultShip, setIsDefaultShip] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        surname: '',
        address: '',
        additionalAddressPropertyType: '',
        city: '',
        country: '',
        province: '',
        postalCode: '',
        billingAddress: '',
        suburb: '',
    });

    const updateStep = () => {
        setStep((curr) => curr + 1);
    };

    const previousStep = () => {
        setStep((curr) => curr - 1);
    };

    const isInformationComplete = () => {
        return !formData.email && !formData.name && !formData.surname;
    };

    const isAddressComplete = () => {
        return !formData.address && !formData.city && !formData.country;
    };

    const displayFormSteps = () => {
        switch (step) {
            case 0:
                return (
                    <UserInformation
                        updateStep={updateStep}
                        setStep={setStep}
                        formData={formData}
                        setFormData={setFormData}
                        step={step}
                        setIsShipping={setIsShipping}
                        setIsDefaultShip={setIsDefaultShip}
                        isShipping={isShipping}
                        isDefaultShip={isDefaultShip}
                    />
                );
            case 1:
                return (
                    <Address
                        updateStep={updateStep}
                        previousStep={previousStep}
                        formData={formData}
                        setFormData={setFormData}
                        step={step}
                    />
                );
            case 2:
                return <EditDetails previousStep={previousStep} />;
        }
    };

    // ! Keep track of the current step in the form and make said step available in the "ShoppingCartContext"
    // ! This step can then be used inside of of "CartDisplay" to conditionally render the "edit details" section for each item in the cart
    useEffect(() => {
        setFormSteps(step);
    }, [step, setFormSteps]);

    useEffect(() => {}, [formData]);

    return (
        <FormPageLayout>
            <Container sx={{ mt: 5 }} maxWidth='sm'>
                <Typography
                    sx={{
                        fontSize: '22px',
                        fontWeight: '600',
                    }}
                >
                    Hertex Trade
                </Typography>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize='small' />}
                    aria-label='breadcrumb'
                    sx={{ mb: 2, mt: 2 }}
                >
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            fontSize: '12px',
                            letterSpacing: '-0.2px',
                            fontWeight: '400',
                        }}
                        color={step === 0 ? 'text.primary' : 'inherit'}
                        onClick={() => {
                            if (step > 0 && isInformationComplete()) {
                                setStep(0);
                            }
                            // setStep(0);
                        }}
                    >
                        Information
                    </Typography>

                    <Typography
                        sx={{
                            cursor: 'pointer',
                            fontSize: '12px',
                            letterSpacing: '-0.2px',
                            fontWeight: '400',
                        }}
                        color={step === 1 ? 'text.primary' : 'inherit'}
                        onClick={() => {
                            if (step > 1 && isAddressComplete()) {
                                setStep(1);
                            }
                            // setStep(1);
                        }}
                    >
                        Address
                    </Typography>
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            fontSize: '12px',
                            letterSpacing: '-0.2px',
                            fontWeight: '400',
                        }}
                        color={step === 2 ? 'text.primary' : 'inherit'}
                        onClick={() => {
                            if (step === 2) {
                                setStep(2);
                            }
                            // setStep(2);
                        }}
                    >
                        Edit Details
                    </Typography>
                </Breadcrumbs>
                <Divider />
                <Box>{displayFormSteps()}</Box>
                <Divider sx={{ mt: '4rem' }} />
                <Grid container mt={2} mb={5}>
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            mr: 2,
                            fontWeight: '400',
                            fontSize: '12px',
                        }}
                    >
                        Refund Policy
                    </Typography>
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            mr: 2,
                            fontWeight: '400',
                            fontSize: '12px',
                        }}
                    >
                        Shipping Policy
                    </Typography>
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            fontWeight: '400',
                            fontSize: '12px',
                        }}
                    >
                        Privacy Policy
                    </Typography>
                </Grid>
            </Container>
        </FormPageLayout>
    );
}

export default CheckoutFormParent;
