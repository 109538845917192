import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

interface SettingsCardProps {
  title: string;
  children: ReactNode;
}

const SettingsCard = ({ title, children }: SettingsCardProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0",
        p: "24px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        borderRadius: "12px",
        width: { xs: "100%", lg: "80%" },
        my: "20px",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: "#000",
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: "20px",
        }}
      >
        {title}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
};

export default SettingsCard;
