import { Box, Grid } from '@mui/material';
import PageLayout from '../../components/shared/layout/PageLayout';
import TextHeader from '../../components/shared/textHeader/TextHeader';

import brandBanner from '../../assets/internationals/banners/brandBanner.png';

import {
    // carouselVideos,
    carouselImages,
    categoryImages,
    newCataloguesBookData,
} from '../../api/tempData';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function InternationalsBrandPage() {
    const { brandname } = useParams();
    const data = {
        textHeaderData: {
            heading: 'Clarke & Clarke',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, Maxime, accusamus eum cum voluptatum alias dolorem officiis eligendi anothe accusa eum cum voluptatum alias vitae dolorem officiis eligendi consectetur adipisicing accusamus eum cum laboris nisi ut',
            subContent:
                'Ut enim ipsum dolor sit amet consectetur adipisicing elit. accusamus eum cum.',
        },
        carouselImages: carouselImages,
        // carouselVideos: carouselVideos,
        categoryImages: categoryImages,
        featuredCollectionList: newCataloguesBookData,
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <PageLayout>
            <Box sx={{ width: '100%', height: '80vh', position: 'relative' }}>
                <Grid>
                    <img
                        style={{
                            width: '100%',
                            height: '80vh',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            position: 'absolute',
                            inset: 0,
                            zIndex: 0,
                        }}
                        src={brandBanner}
                        alt='about'
                    />
                </Grid>
            </Box>
            {/* <Carousel
        images={data.carouselImages}
        hasVideo={false}
        video={carouselVideos}
      /> */}
            <TextHeader
                heading={brandname}
                content={data.textHeaderData.content}
                subContent={data.textHeaderData.subContent}
                isCentered={false}
                width='70%'
            />
            {/* <FeaturedGallery
                images={collectionGallery}
                hasVideo={false}
                invert={false}
                marginTop={5}
                marginBottom={5}
                title={'Title'}
                description={'Description'}
                // itemGroup={
                //     categoryData?.featured_collection_title
                //         ?.item_group_code
                // }
                // application={
                //     categoryData?.featured_collection_title
                //         ?.application_name
                // }
                // collection={
                //     categoryData?.featured_collection_title
                //         ?.collection_name
                // }
            /> */}
            {/* <FeaturedGallery images={collectionGallery} hasVideo={false} /> */}
            {/* <NewArrivalsGrid
                header='View new catalogues'
                data={data.featuredCollectionList}
                hasTitles={false}
                hasButtons={true}
                spacingValue={4}
            /> */}
        </PageLayout>
    );
}

export default InternationalsBrandPage;
