import React from "react";
import PaymentLayout from "../../components/shared/layout/PaymentLayout";
import PaymentContent from "../../components/shared/payment/PaymentContent";
import Error from "../../assets/error_pay.svg";

const PayfastPaymentFail = () => {
  return (
    <PaymentLayout>
      <PaymentContent
        statusImage={Error}
        header=" It seems there was an issue"
        subHeader="processing your order."
        secondParagraph="We're here to ensure your shopping experience goes smoothly."
      />
    </PaymentLayout>
  );
};

export default PayfastPaymentFail;
