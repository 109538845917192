import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { apiPostProductStats } from '../../../../api/apiProducts';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CategorySelection = ({
  availableCategories,
  filterByCategory,
  setCategory,
  category,
  homewareCategoryList,
  setHomewareCategoryList,
  selectedHomewareCategory,
  setSelectedHomewareCategory,
  isHomeware,
  setIsHomeware

}: any) => {

  const arguementDataStructure = {
    data: {
      items_groups_stats: [],
    }
  }

  const {
    data: homewareData,
  } = useQuery<any, Error>(
    [`application-homeware`],
    () => apiPostProductStats(arguementDataStructure), {
    onSuccess: () => {
      createHomewareList()
    }
  }
  );

  const createHomewareList = () => {
    const homewareKeys = Object.keys(homewareData.homeware);
    const updatedList: any = [];

    homewareKeys.forEach((key) => {
      switch (key) {
        case 'Bathroom':
          updatedList.push({ category: "Bathroom", itemGroup: '016' });
          break;
        case 'Bedroom':
          updatedList.push({ category: "Bedroom", itemGroup: '006' });
          break;
        case 'Curtains':
          updatedList.push({ category: "Curtains", itemGroup: '015' });
          break;
        case 'Decorative':
          updatedList.push({ category: "Decorative", itemGroup: '010' });
          break;
        case 'Furniture':
          updatedList.push({ category: "Furniture", itemGroup: '009' });
          break;
        case 'Illumination':
          updatedList.push({ category: "Illumination", itemGroup: '007' });
          break;
        case 'Scatters':
          updatedList.push({ category: "Scatters", itemGroup: '018' });
          break;
        case 'Tableware':
          updatedList.push({ category: "Tableware", itemGroup: '008' });
          break;
        case 'Throws, Quilts and Furs':
          updatedList.push({ category: 'Throws, Quilts and Furs', itemGroup: '013' });
          break;
        case 'Wall Decor':
          updatedList.push({ category: 'Wall Decor', itemGroup: '014' });
          break;
      }
    });

    setHomewareCategoryList(updatedList);

  }

  useEffect(() => {
    filterByCategory(availableCategories[0].itemGroup);
    setCategory(availableCategories[0].itemGroup)
  }, []);

   return (
    <>
      <Divider sx={{ backgroundColor: '#000000', mb: '1rem' }} />
      <Typography fontWeight='bold' fontSize='20px' mb={2}>
        Categories
      </Typography>

      <Box sx={{ overflowY: 'auto', maxHeight: '20.5rem' }}>

        <FormGroup>
          {availableCategories
            ?.sort()
            .map((elem: any, index: number) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={category === elem.itemGroup}
                        onChange={() => {
                          setCategory(elem.itemGroup);
                          if (elem.category !== 'Homeware') {
                            filterByCategory(elem.itemGroup)
                            setIsHomeware(false)
                          }
                          if (elem.category === 'Homeware') {
                            setIsHomeware(true)
                            setSelectedHomewareCategory('')
                          }
                        }}
                      />
                    }
                    label={elem.category}
                  />
                </FormGroup>
                {elem.category === 'Homeware' && !isHomeware ? <ArrowRightIcon sx={{ ml: -2 }} /> : elem.category === 'Homeware' && isHomeware ? <ArrowDropDownIcon sx={{ ml: -2 }} /> : null
                }
              </Box>
            ))}
        </FormGroup>

        {isHomeware && (
          <FormGroup sx={{ ml: 3.5 }}>
            {homewareCategoryList
              ?.sort()
              .map((elem: any, index: number) => (
                <FormGroup key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedHomewareCategory === elem.itemGroup}
                        onChange={() => {
                          setSelectedHomewareCategory(elem.itemGroup);
                          filterByCategory(elem.itemGroup)
                        }}
                      />
                    }
                    label={elem.category}
                  />
                </FormGroup>
              ))}
          </FormGroup>
        )}
      </Box>
    </>
  );
};

export default CategorySelection;
