import PageLayout from '../layout/PageLayout';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import {
    apiGetCollectionPageData,
    apiGetDesignsInCollection,
} from '../../../api/apiCollections';
import LoadingScreen from '../loadingScreen/LoadingScreen';
import TextHeader from '../textHeader/TextHeader';
import FeaturedGallery from '../gallery/FeaturedGallery';
import NewArrivalsGrid from '../grid/NewArrivalsGrid';
import collectionHeader from '../../../assets/tempHeaderImages/A.jpg';
import DesignsGrid from '../grid/DesignsGrid';
import Gallery from '../gallery/Gallery';

// import {
//     carouselImages,
//     categoryImages,
//     collectionGallery,
//     internationals,
//     // carouselVideos,
//     featuredHomeCollectionList,
// } from '../../../api/tempData';
import { useEffect } from 'react';
import RecommendedGrid from '../grid/RecommendedGrid';
import MissingPage from '../errorMessages/MissingPage';
import DesignsInCollectionGrid from '../grid/DesignsInCollectionGrid';
import CarouselBanner from '../banner/CarouselBanner';
import { captureAnalytics } from '../../../utilities/Analytics';

function CollectionTemplate() {
    const { item_group, application, collection } = useParams();
    const location = useLocation();

    const argumentDataStructure = {
        category: {
            application: application,
            item_group: item_group,
            collection: collection,
        },
    };

    const designFilters = {
        filter: {
            item_group: item_group,
            application: application,
            range: collection,
        },
        off_setter: {
            start: 0,
            end: 100,
        },
    };

    const {
        isSuccess,
        isError,
        isLoading,
        data: collectionData,
    } = useQuery<any, Error>([`collection-${collection}`], () =>
        apiGetCollectionPageData(argumentDataStructure)
    );

    const { data: designsData } = useQuery(
        [`collection-designs-${collection}`],
        () => apiGetDesignsInCollection(designFilters)
    );
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        captureAnalytics(
            'pageview',
            location.pathname,
            `Collection - ${collection}`
        );
    }, [location.pathname, collection]);

    return (
        <PageLayout>
            {isError && <MissingPage />}
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    {' '}
                    {isSuccess && (
                        <>
                            <CarouselBanner
                                images={collectionData?.section_hero}
                                video={
                                    collectionData?.section_hero_video
                                        ?.video_url
                                }
                            />
                            <TextHeader
                                heading={collectionData?.page_title}
                                content={collectionData?.description}
                                isCentered={false}
                                width='70%'
                                marginTop={10}
                                marginBottom={5}
                            />

                            <Gallery
                                images={collectionData?.section_gallery}
                                galleryLayout={'masonry'}
                                columns={2}
                                title={'title'}
                                secondaryText={'2bd'}
                                hasTitle={false}
                                hasSecondaryText={false}
                            />

                            {/* !Client has requested a change on this component, we may need to revert the DesignsGrid component */}
                            <DesignsInCollectionGrid
                                data={designsData}
                                marginTop='5rem'
                                marginBottom='5rem'
                            />
                            {/* <DesignsGrid
                                data={collectionData?.section_designs}
                                marginTop='5rem'
                                marginBottom='5rem'
                            /> */}

                            {/* Client request to hide - relook at a later stage */}
                            {/* <RecommendedGrid
                                header='You may also like'
                                data={collectionData?.section_you_may_also_like}
                                hasTitles={true}
                                hasButtons={true}
                                hasBackgroundText={false}
                                spacingValue={4}
                                marginTop={'5rem'}
                                marginBottom={'5rem'}
                            /> */}
                        </>
                    )}
                </>
            )}
        </PageLayout>
    );
}

export default CollectionTemplate;
