import internationalsImage from '../assets/internationals_image.png';
import tradeImage from '../assets/trade_image.jpeg';

import bookImg from '../assets/internationals/newCatalogues/bookImg.png';

import fabricCategoryImg from '../assets/mainCategories/Fabric.jpg';
import homeCategoryImg from '../assets/mainCategories/Homeware.jpg';
import rugsCategoryImg from '../assets/mainCategories/Rugs.jpg';
import wallpaperCategoryImg from '../assets/mainCategories/Wallpaper.jpg';

import customerImage from '../assets/company/about/hertex_1.jpg';
import hausImage from '../assets/company/about/hertex_2.jpg';
import responsibilityImage from '../assets/company/about/Rectangle 36.jpg';
import directorImages from '../assets/directors.png';
import mobileImage from '../assets/mobile.png';
import showroomImage from '../assets/company/explore/explore_1.jpg';
import storesImage from '../assets/company/explore/explore_2.jpg';
import caffeImage from '../assets/company/explore/explore_3.jpg';
import categoryImage from '../assets/Category.png';
import hausLogo from '../assets/hausLogo.png';
import vidaImage from '../assets/vida.png';
import directorsImagesTwo from '../assets/directorsImgsTwo.png';
import hertexLogo from '../assets/logos/hertex-logo-long.png';

import lemieux from '../assets/internationals/newCatalogues/HXI_Lemieux Et Cie_York_ISSUU Pages_Cover.jpg';
import grassclothNatural from '../assets/internationals/newCatalogues/HXI_Grasscloth & Natural Resources_York_ISSUU Pages_Cover.jpg';
import urbanDigest from '../assets/internationals/newCatalogues/HXI_Urban Digest_York_ISSUU Pages_Cover.jpg';
import breeganJane from '../assets/internationals/newCatalogues/HXI_Breegan Jane, Paradiso, Secret Garden, Evora, Marina_C&C_ISSUU Pages_Cover.jpg';
import vividoUrban from '../assets/internationals/newCatalogues/HXI_Vivido Urban Whitworth_C&C_Cover.jpg';
import alfresco from '../assets/internationals/newCatalogues/HXI_Alfresco William Morris_C&C_Cover.jpg';
import midori from '../assets/internationals/newCatalogues/HXI_Midori_C&C_ISSUU pages_Cover.jpg';
import wallDesigns from '../assets/internationals/newCatalogues/HXI_Wall Designs 4_Masureel_ISSUU Pages_Cover.jpg';
import masureelJoy from '../assets/internationals/newCatalogues/Masureel_Joy.jpg';
import masureelRock from '../assets/internationals/newCatalogues/Masureel_Rock.jpg';
import masureelTerra from '../assets/internationals/newCatalogues/Masureel_Terra.jpg';
import harlequinSophieRobinson from '../assets/internationals/newCatalogues/Harlequin_Sophie Robinson.jpg';
import reflect from '../assets/internationals/newCatalogues/HXI_Reflect_Harlequin_Cover.jpg';
import sheers1 from '../assets/internationals/newCatalogues/HXI_Sheers_Harlequin_ISSUU Pages_Cover.jpg';
import editeurLinenQuarter from '../assets/internationals/newCatalogues/Editeur_Linen Quarter.jpg';
import carnabyStreet from '../assets/internationals/newCatalogues/HXI_Carnaby Street_Cover.jpg';
import gilesDeacon from '../assets/internationals/newCatalogues/HXI_Giles Deacon_Sanderson_ISSUU Pages_Cover.jpg';
import perfectPlains from '../assets/internationals/newCatalogues/HXI_Perfect Plains_Brewster_Cover.jpg';

import collection1 from '../assets/resources/Collection_one_Eco_Journey.jpg';
import collection3 from '../assets/resources/Collection_three_Eco_Journey.jpg';

export const HertexLogo = [
    {
        img: hertexLogo,
    },
];

export const newCataloguesBookData = [
    { img: bookImg, title: 'animal attraction' },
    { img: bookImg, title: 'animal attraction' },
    { img: bookImg, title: 'animal attraction' },
];

export const carouselImages = [
    {
        url: 'https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1616486338812-3dadae4b4ace?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1556228453-efd6c1ff04f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1540574163026-643ea20ade25?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1600121848594-d8644e57abab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
];

export const categoryImages = [
    {
        img: fabricCategoryImg,
        title: 'Fabrics',
        item_group: '005',
        url_title: 'fabric',
        secondaryText: 'Secondary 1',
        id: '1',
    },
    {
        img: wallpaperCategoryImg,
        title: 'Wall Coverings',
        item_group: '001',
        url_title: 'wallpaper',
        secondaryText: 'Secondary 2',
        id: '2',
    },
    {
        img: rugsCategoryImg,
        title: 'Rugs',
        item_group: '004',
        url_title: 'rugs',
        secondaryText: 'Secondary 3',
        id: '3',
    },
    {
        img: homeCategoryImg,
        title: 'Homeware',
        item_group: '005',
        url_title: 'homeware',
        secondaryText: 'Secondary 3',
        id: '4',
    },
];

export const featuredHomeCollectionList = [
    {
        img: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
        title: 'Featured Wall Covering 1',
        url_title: 'featured_wall_covering_1',
        secondaryText: 'Secondary 1',
        id: '1',
    },
    {
        img: 'https://images.unsplash.com/photo-1591195854242-8804547cdcab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        title: 'Featured Wall Covering 2',
        url_title: 'featured_wall_covering_2',
        secondaryText: 'Secondary 2',
        id: '2',
    },
    {
        img: 'https://images.unsplash.com/photo-1605635543678-af6dd29fad9e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80',
        title: 'Featured Wall Covering 3',
        url_title: 'featured_wall_covering_3',
        secondaryText: 'Secondary 3',
        id: '3',
    },
];

export const directorsPageImages = [
    {
        img: directorsImagesTwo,
        title: 'Name Surname',
        subTitle: 'DESIGNATION',
        secondaryText:
            'lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas 1',
        id: '1',
    },
    {
        img: directorsImagesTwo,
        title: 'Name Surname',
        subTitle: 'DESIGNATION',
        secondaryText:
            'lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas 2',
        id: '2',
    },
    {
        img: directorsImagesTwo,
        title: 'Name Surname',
        subTitle: 'DESIGNATION',
        secondaryText:
            'lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas 3',
        id: '3',
    },
];

export const aboutUsData = [
    {
        img: categoryImage,
        title: 'Our Partners',
        buttonText: 'VIEW NOW',
        paragraph:
            'Founded by Hertex, HAUS was created to give customers instant access to an expertly curated collection of homeware to complete their interior schemes',
        id: '1',
        link: '../directors',
    },
    {
        img: categoryImage,
        paragraph:
            'Founded by Hertex, HAUS was created to give customers instant access to an expertly curated collection of homeware to complete their interior schemes',
        buttonText: 'FIND A STORE',

        title: 'Explore Our Spaces',
        id: '2',
        link: '../store-locator',
    },
];

export const featuredFabricCollectionList = [
    {
        img: 'https://images.unsplash.com/photo-1591195854242-8804547cdcab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        title: 'Featured Fabric 1',
        url_title: 'featured_fabric_1',
        secondaryText: 'Secondary 1',
        id: '1',
    },
    {
        img: 'https://images.unsplash.com/photo-1604690078253-f73b6fbfcbd1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80',
        title: 'Featured Fabric 2',
        url_title: 'featured_fabric_2',
        secondaryText: 'Secondary 2',
        id: '2',
    },
    {
        img: 'https://images.unsplash.com/photo-1513506003901-1e6a229e2d15?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        title: 'Featured Fabric 3',
        url_title: 'featured_fabric_3',
        secondaryText: 'Secondary 3',
        id: '3',
    },
];

export const featuredWallpaperCollectionList = [
    {
        img: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
        title: 'Featured Wall Covering 1',
        url_title: 'featured_wall_covering_1',
        secondaryText: 'Secondary 1',
        id: '1',
    },
    {
        img: 'https://images.unsplash.com/photo-1606744888344-493238951221?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
        title: 'Featured Wall Covering 2',
        url_title: 'featured_wall_covering_2',
        secondaryText: 'Secondary 2',
        id: '2',
    },
    {
        img: 'https://images.unsplash.com/photo-1605635543678-af6dd29fad9e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80',
        title: 'Featured Wall Covering 3',
        url_title: 'featured_wall_covering_3',
        secondaryText: 'Secondary 3',
        id: '3',
    },
];

export const fabricCategoriesList = [
    {
        img: 'https://images.unsplash.com/photo-1518019671582-55004f1bc9ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        title: 'Fabric Category 1',
        url_title: 'fabric_Category_1',
        secondaryText: 'Secondary 1',
        id: '1',
    },
    {
        img: 'https://images.unsplash.com/photo-1611242319342-ed0d65b1a0f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        title: 'Fabric Category 2',
        url_title: 'fabric_Category_2',
        secondaryText: 'Secondary 2',
        id: '2',
    },
    {
        img: 'https://plus.unsplash.com/premium_photo-1673502750959-bc29600bc189?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80',
        title: 'Fabric Category 3',
        url_title: 'fabric_category_3',
        secondaryText: 'Secondary 3',
        id: '3',
    },
    {
        img: 'https://images.unsplash.com/photo-1604690078253-f73b6fbfcbd1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80',
        title: 'Fabric Category 4',
        url_title: 'fabric_category_4',
        secondaryText: 'Secondary 4',
        id: '4',
    },
    {
        img: 'https://images.unsplash.com/photo-1591195854242-8804547cdcab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        title: 'Fabric Category 5',
        url_title: 'wallpaper_category_5',
        secondaryText: 'Secondary 5',
        id: '5',
    },
    {
        img: 'https://images.unsplash.com/photo-1611007725470-6b50a627e3c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        title: 'Fabric Category 6',
        url_title: 'wallpaper_category_6',
        secondaryText: 'Secondary 6',
        id: '6',
    },
];

export const WallpaperCategoriesList = [
    {
        img: 'https://images.unsplash.com/photo-1582174570676-65b65dbf5ac5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        title: 'Wallpaper Category 1',
        url_title: 'wallpaper_category_1',
        secondaryText: 'Secondary 1',
        id: '1',
    },
    {
        img: 'https://images.unsplash.com/photo-1605635543678-af6dd29fad9e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80',
        title: 'Wallpaper Category 2',
        url_title: 'wallpaper_category_2',
        secondaryText: 'Secondary 2',
        id: '2',
    },
    {
        img: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
        title: 'Wallpaper Category 3',
        url_title: 'wallpaper_category_3',
        secondaryText: 'Secondary 3',
        id: '3',
    },
    {
        img: 'https://images.unsplash.com/photo-1614155432292-c642ff94b8f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
        title: 'Wallpaper Category 4',
        url_title: 'wallpaper_category_4',
        secondaryText: 'Secondary 3',
        id: '4',
    },
    {
        img: 'https://images.unsplash.com/photo-1487700160041-babef9c3cb55?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80',
        title: 'Wallpaper Category 5',
        url_title: 'wallpaper_category_4',
        secondaryText: 'Secondary 3',
        id: '5',
    },
    {
        img: 'https://images.unsplash.com/photo-1606744888344-493238951221?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
        title: 'Wallpaper Category 6',
        url_title: 'wallpaper_category_6',
        secondaryText: 'Secondary 3',
        id: '6',
    },
];

export const WallpaperProductsList = [
    {
        img: 'https://images.unsplash.com/photo-1632494425431-e8b9bc198390?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGF0dGVybnN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        title: 'Product 1',
        url_title: 'wallpaper_product_1',
        secondaryText: 'Secondary 1',
        id: '1',
    },
    {
        img: 'https://images.unsplash.com/photo-1632494425431-e8b9bc198390?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGF0dGVybnN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        title: 'Product 2',
        url_title: 'wallpaper_product_2',
        secondaryText: 'Secondary 2',
        id: '2',
    },
    {
        img: 'https://images.unsplash.com/photo-1632494425431-e8b9bc198390?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGF0dGVybnN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        title: 'Product 3',
        url_title: 'wallpaper_product_3',
        secondaryText: 'Secondary 3',
        id: '3',
    },
    {
        img: 'https://images.unsplash.com/photo-1632494425431-e8b9bc198390?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGF0dGVybnN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        title: 'Product 4',
        url_title: 'wallpaper_product_4',
        secondaryText: 'Secondary 3',
        id: '4',
    },
    {
        img: 'https://images.unsplash.com/photo-1632494425431-e8b9bc198390?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGF0dGVybnN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        title: 'Product 5',
        url_title: 'wallpaper_product_4',
        secondaryText: 'Secondary 3',
        id: '5',
    },
    {
        img: 'https://images.unsplash.com/photo-1632494425431-e8b9bc198390?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGF0dGVybnN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        title: 'Product 6',
        url_title: 'wallpaper_product_6',
        secondaryText: 'Secondary 3',
        id: '6',
    },
];

export const collectionImages = [
    {
        img: 'https://images.unsplash.com/photo-1580460848000-d3ed1051700f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1627052045672-be78a58fcd37?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1537726235470-8504e3beef77?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1595595183083-d40937b04cf3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
    },
];

export const galleryImages = [
    {
        img: 'https://images.unsplash.com/photo-1618220179428-22790b461013?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1616046229478-9901c5536a45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1556702571-3e11dd2b1a92?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1503174971373-b1f69850bded?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1213&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1615971677499-5467cbab01c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1513161455079-7dc1de15ef3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1596204976717-1a9ff47f74ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
];

export const collectionGallery = [
    { collectionName: 'Collection Name', tag: 'Category Tag' },
    {
        url: 'https://images.unsplash.com/photo-1618220179428-22790b461013?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1546448396-6aef80193ceb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
        tag: 'Category Tag',
        name: 'Collection Name',
        text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',
    },
    {
        url: 'https://images.unsplash.com/photo-1616046229478-9901c5536a45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1618221710640-c0eaaa2adb49?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
];

export const uniformGallery = [
    {
        img: 'https://images.unsplash.com/photo-1598928506311-c55ded91a20c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGludGVyaW9yJTIwZGVzaWdufGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
    },
    {
        img: 'https://images.unsplash.com/photo-1618221710640-c0eaaa2adb49?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1618221469555-7f3ad97540d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1633110187937-6e3b2f36dfca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1617104424032-b9bd6972d0e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1092&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1617104611622-d5f245d317f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1184&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1618220924273-338d82d6b886?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1542928658-22251e208ac1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1617104678098-de229db51175?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1214&q=80',
    },
];

export const fabricsGallery = [
    {
        img: 'https://images.unsplash.com/photo-1604690078253-f73b6fbfcbd1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1600713127008-3badfa9e29f5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1073&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1611007725470-6b50a627e3c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1619043518800-7f14be467dca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1510575188413-04020c9623d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1565&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1582592641306-277abdcdbb7a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1631089819675-ce4528460a32?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1593250816874-8edf4f732edb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1533561679034-ad5a6d4d65f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    },
];

export const imageBanner = [
    {
        img: 'https://images.unsplash.com/photo-1612743847346-b8e7313694c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80',
    },
];

const callToActionData = [
    {
        img: 'https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        title: 'Fabrics Call to Action',
    },
];

export const brands = [
    { title: 'stone haus' },
    { title: 'studio h' },
    { title: 'couture' },
    { title: 'internationals' },
    { title: 'carefree' },
    { title: 'brand x' },
];

export const application = [
    { title: 'Upholstery' },
    { title: 'Curtaining' },
    { title: 'Dual Purpose' },
    { title: 'Outdoor' },
    { title: 'Easy to Clean' },
    { title: 'Earth Friendly' },
];

export const categories = [
    { title: 'Rugs' },
    { title: 'Wallpaper' },
    { title: 'Fabric' },
];

// export const carouselVideos = [
//     {
//         id: 1,
//         title: 'Welcome',
//         src: carouselVideo,
//         description: 'To the Hertex website',
//     },
// ];

export const footerLinks = [
    { title: 'Hertex' },
    {
        title: 'Fabrics',
        // links: ['Link 1', 'Link ', 'Link 3', 'Link 4', 'Link 5'],
    },
    {
        title: 'Collections',
        // links: ['Link 1', 'Link ', 'Link 3', 'Link 4', 'Link 5'],
    },
    {
        title: 'Brands',
        // links: [
        //     'Stone Haus',
        //     'Studio H',
        //     'Couture',
        //     'Internationals',
        //     'CareFree',
        // ],
    },
    {
        title: 'Company',
        // links: ['Link 1', 'Link ', 'Link 3', 'Link 4', 'Link 5'],
    },
];

export const internationals = [
    {
        img: internationalsImage,
    },
];

export const trade = [
    {
        img: tradeImage,
    },
];

//Company Images
export const customerImgs = [
    {
        img: customerImage,
    },
];

export const hausImgs = [
    {
        img: hausImage,
    },
];

export const responsibilityImgs = [
    {
        img: responsibilityImage,
    },
];

export const directorImgs = [
    {
        img: directorImages,
    },
];

export const mobileImgs = [
    {
        img: mobileImage,
    },
];

export const showroomsImg = [
    {
        img: showroomImage,
    },
];
export const storesImg = [
    {
        img: storesImage,
    },
];

export const caffeImg = [
    {
        img: caffeImage,
    },
];

export const hausLogoImg = [
    {
        img: hausLogo,
    },
];

export const vidaImg = [
    {
        img: vidaImage,
    },
];

export const resourceImageData = [
    {
        id: '01',
        img: collection1,
        url: '../../../assets/resources/Banner_Main_Eco_Journey.jpg',
        collection_name: 'Image 1',
    },
    {
        id: '02',
        img: collection3,
        url: 'https://issuu.com/hertexhaus/docs/hxi_grasscloth_natural_resources_york_issuu_page?fr=xKAE9_zU1NQ',
        collection_name: 'Image 1',
    },
    {
        id: '03',
        img: collection1,
        url: 'https://issuu.com/hertexhaus/docs/hxi_urban_digest_york_issuu_pages?fr=xKAE9_zU1NQ',
        collection_name: 'Image 1',
    },
    {
        id: '04',
        img: collection3,
        url: 'https://issuu.com/hertexhaus/docs/hxi_breegan_jane_paradiso_secret_garden_evora_?fr=xKAE9_zU1NQ',
        collection_name: 'Image 1',
    },
];


export const pricelistData = [
    {
        id: '01',
        title: 'Pricelist 1',
        brand: 'Hertex',
        date: '30/07/2028',
        img: collection1,
        url: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    },
    {
        id: '02',
        title: 'Pricelist 2',
        brand: 'Hertex',
        date: '30/07/2028',
        img: collection3,
        url: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    },
    {
        id: '03',
        title: 'Pricelist 3',
        brand: 'Hertex',
        date: '30/07/2028',
        img: collection3,
        url: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    },
    {
        id: '04',
        title: 'Pricelist 4',
        brand: 'Hertex 4',
        date: '30/07/2028',
        img: collection1,
        url: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    },

];

export const generalInfoData = [
    {
        id: '01',
        title: 'General Info 1',
        brand: 'Hertex',
        date: '30/07/2028',
        img: collection1,
        url: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    },
    {
        id: '02',
        title: 'General Info 2',
        brand: 'Hertex',
        date: '30/07/2028',
        img: collection3,
        url: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    },
    {
        id: '03',
        title: 'General Info 3',
        brand: 'Hertex',
        date: '30/07/2028',
        img: collection3,
        url: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    },
    {
        id: '04',
        title: 'General Info 4',
        brand: 'Hertex 4',
        date: '30/07/2028',
        img: collection1,
        url: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    },

];

export const catalogueData = [
    {
        id: '01',
        img: lemieux,
        url: 'https://issuu.com/hertexhaus/docs/hxi_lemieux_et_cie_york_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '02',
        img: grassclothNatural,
        url: 'https://issuu.com/hertexhaus/docs/hxi_grasscloth_natural_resources_york_issuu_page?fr=xKAE9_zU1NQ',
    },
    {
        id: '03',
        img: urbanDigest,
        url: 'https://issuu.com/hertexhaus/docs/hxi_urban_digest_york_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '04',
        img: breeganJane,
        url: 'https://issuu.com/hertexhaus/docs/hxi_breegan_jane_paradiso_secret_garden_evora_?fr=xKAE9_zU1NQ',
    },
    {
        id: '05',
        img: vividoUrban,
        url: 'https://issuu.com/hertexhaus/docs/hxi_vivido_urban_whitworth_c_c_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '06',
        img: alfresco,
        url: 'https://issuu.com/hertexhaus/docs/hxi_alfresco_william_morris_c_c_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '07',
        img: midori,
        url: 'https://issuu.com/hertexhaus/docs/hxi_midori_c_c_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '08',
        img: wallDesigns,
        url: 'https://issuu.com/hertexhaus/docs/hxi_wall_designs_4_masureel_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '09',
        img: masureelJoy,
        url: 'https://issuu.com/hertexhaus/docs/hxi_joy_masureel_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '10',
        img: masureelRock,
        url: 'https://issuu.com/hertexhaus/docs/hxi_rock_masureel_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '11',
        img: masureelTerra,
        url: 'https://issuu.com/hertexhaus/docs/hxi_terra_masureel_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '12',
        img: harlequinSophieRobinson,
        url: 'https://issuu.com/hertexhaus/docs/hxi_sophie_robinson_harlequin_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '13',
        img: reflect,
        url: 'https://issuu.com/hertexhaus/docs/hxi_reflect_harlequin_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '14',
        img: sheers1,
        url: 'https://issuu.com/hertexhaus/docs/hxi_sheers_harlequin_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '15',
        img: editeurLinenQuarter,
        url: 'https://issuu.com/hertexhaus/docs/hx_linen_quarter_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '16',
        img: carnabyStreet,
        url: 'https://issuu.com/hertexhaus/docs/hx_carnaby_street_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '17',
        img: gilesDeacon,
        url: 'https://issuu.com/hertexhaus/docs/hxi_giles_deacon_sanderson_issuu_pages?fr=xKAE9_zU1NQ',
    },
    {
        id: '18',
        img: perfectPlains,
        url: 'https://issuu.com/hertexhaus/docs/hxi_perfect_plains_brewster_issuu_pages?fr=xKAE9_zU1NQ',
    },
];
