import { Box } from "@mui/material";

function AdminPortalPageSpacer({ children }: any) {
  return (
    <Box
      component="main"
      sx={{
        width: "100%",
        flexGrow: { xs: 0, lg: 1 },
        bgcolor: "background.default",
        p: { xs: 1, lg: 3 },
      }}
    >
      {children}
    </Box>
  );
}

export default AdminPortalPageSpacer;
