import { Box, Typography } from "@mui/material";
import React from "react";

interface DashboardHeaderProps {
  title: string;
  subTitle: string;
}

const DashboardHeader = ({ title, subTitle }: DashboardHeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontSize: "22px",
          fontWeight: "600",
          lineHeight: "38px",
          color: "var(--Gray-900, #101828)",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
        }}
      >
        {subTitle}
      </Typography>
    </Box>
  );
};

export default DashboardHeader;
