import PageLayout from '../../components/shared/layout/PageLayout';
import TextHeader from '../../components/shared/textHeader/TextHeader';

import careersFAQ from '../../assets/faq-page/Careers_FAQ.jpg';
import careersLinkedin from '../../assets/faq-page/Careers_LinkedIn.jpg';
import careersQuestions from '../../assets/faq-page/Careers_Questions.jpg';
import careersHero from '../../assets/faq-page/careershero.jpg';
import CompanyGrid from '../../components/shared/grid/CompanyGrid';
import CompanyBanner from '../../components/shared/grid/CompanyBanner';
import AccordionComponent from '../../components/shared/accordion/AccordionComponent';
import { faqsData } from './companyStoreData/faqsData';
import { useQuery } from 'react-query';
import { apiGetCareersData } from '../../api/apiCareersCsv';
import CareersAccordion from '../../components/shared/accordion/CareersAccordion';
import { Box, Typography } from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { captureAnalytics } from '../../utilities/Analytics';

const CareerPage = () => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const location = useLocation();

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const {
        isSuccess,
        isError,
        data: careersData,
    } = useQuery<any, Error>([`careers`], () => apiGetCareersData(), {});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        captureAnalytics('pageview', location.pathname, 'Careers');
    }, [location.pathname]);

    return (
        <PageLayout>
            <CompanyBanner imageData={careersHero} />

            <TextHeader
                heading='Join our team'
                content='What started in 1987 as a tiny family-owned business, has since grown into a thriving enterprise with more than 300 permanent employees. We are constantly growing, developing new collections, opening new stores and expanding our operations.'
                subContent='Our talent teams are always searching for the most suitable people to join us. See our current vacancies below, listed according to region:'
                isCentered={false}
                width='70%'
                marginTop={10}
                marginBottom={15}
            />

            {isSuccess ? (
                <Box mb={'10rem'} mt={'-3rem'}>
                    <CareersAccordion careersData={careersData} />
                </Box>
            ) : isError ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',

                        mb: 8,
                    }}
                >
                    <AnnouncementIcon fontSize={'large'} />
                    <Typography>
                        {' '}
                        Job listing cannot be displayed at this time
                    </Typography>
                    <Typography> Please try again later</Typography>
                </Box>
            ) : null}

            <CompanyBanner imageData={careersFAQ} hasTitle={false} />

            <TextHeader
                heading='Frequently asked questions'
                isCentered={false}
                width='70%'
                marginTop={10}
                marginBottom={0}
            />

            {faqsData.map((faq: any, index: any) => (
                <AccordionComponent
                    key={index}
                    panelNumber={index}
                    expanded={expanded}
                    handleChange={handleChange}
                    {...faq}
                    displayFAQs={true}
                    isTop={'20px'}
                />
            ))}

            <CompanyGrid
                marginTop={15}
                marginBottom={15}
                image={careersQuestions}
                isReverse={true}
                isContained={true}
                heading='Still have Questions?'
                paragraph1='Can’t find the answer you’re looking for? Please chat to our friendly team'
                hasExternalLink={true}
                externalLink='mailto:careers@hertex.co.za'
                padTop={15}
                hasButton={true}
                buttonTitle='CAREERS@HERTEX.CO.ZA'
            />

            <CompanyGrid
                marginTop={15}
                marginBottom={15}
                image={careersLinkedin}
                isReverse={false}
                isContained={true}
                heading='Follow us on LinkedIn'
                paragraph1='Join our LinkedIn community to stay updated with the latest job opportunities, company news, and industry insights.'
                padTop={15}
                hasButton={true}
                hasExternalLink={true}
                externalLink='https://www.linkedin.com/company/hertexfabrics/mycompany/'
                buttonTitle='FOLLOW NOW'
            />
        </PageLayout>
    );
};

export default CareerPage;
