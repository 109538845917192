import { baseURL } from './baseUrl';
export const apiGetCollections = async (
    mainCategory: string,
    applicationName: string
) => {
    const response = await baseURL.get(
        `get-collection-by-cat-application?cat=${mainCategory}&application=${applicationName}&start=0&count=5`
    );
    return response.data;
};

export const apiGetCollectionPageData = async (data: object) => {
    const response = await baseURL.post(`get-all-cms-data`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiGetYouMayAlsoLikeData = async (stock_link: number) => {
    const response = await baseURL.get(`you-may-also-like/${stock_link}`);
    return response.data;
};

export const apiGetDesignsInCollection = async (filters: object) => {
    const response = await baseURL.post(`products/multi_filter`, filters, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};
