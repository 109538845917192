import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

function PageLayout({ children }: any) {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}

export default PageLayout;
