import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';

interface StockItem {
  on_hand: string;
  dye_lot: string;
  on_reserve: string;
  soh_available: string;
  stock_code: string;
  last_update: number;
  stock_roll_id: number;
}

interface StockTableProps {
  stockRollData: [StockItem];
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
}

const StockTable = ({
  stockRollData,
  isSuccess,
  isError,
  isLoading,
}: StockTableProps) => {
  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 315,
            width: 650,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {isError && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 315,
            width: 650,
          }}
        >
          <Typography fontWeight={'bold'}>
            {' '}
            This info is currently unavailable, please try again
            later
          </Typography>
        </Box>
      )}
      {isSuccess && (
        <TableContainer sx={{ maxHeight: 315 }} component={Paper}>
          <Table
            sx={{
              minWidth: 650,
            }}
            aria-label='stock roll table'
          >
            <TableHead sx={{ backgroundColor: '#F2F4F7' }}>
              <TableRow>
                <TableCell align='center'>Roll ID</TableCell>
                <TableCell align='center'>On Hand</TableCell>
                <TableCell align='center'>Dye Lot</TableCell>
                <TableCell align='center'>On Reserve</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockRollData?.map((row, index: number) => (
                <TableRow key={index}>
                  <TableCell align='center'>
                    {row.stock_roll_id}
                  </TableCell>
                  <TableCell align='center'>
                    {parseFloat(row.on_hand).toFixed(2)}
                  </TableCell>
                  <TableCell align='center'>
                    {row.dye_lot}
                  </TableCell>
                  <TableCell align='center'>
                    {parseFloat(row.on_reserve).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default StockTable;
