import {
    Box,
    Grid,
    Typography,
    Breadcrumbs,
    Link,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Divider,
} from '@mui/material';

import Products from "../../../../../assets/products_svg.svg"

const ProductDetails = ({ productDetailsList }: any) => {
    return (
        <>
            <Grid
                sx={{ width: '100%' }}
                container
                justifyContent='center'
                alignItems='center'
            >
                {/* <Divider color='black' sx={{ width: '100%', m: 3 }} /> */}
            </Grid>
            <Grid container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        mt: 3,
                        pl: {xs: '0', lg:'50px'},
                        width: '100%'

                    }}
                >
                    <Typography mb={2} variant='h5' fontSize={'40px'} fontWeight={'500'} lineHeight={'48px'}>
                        Product Details
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {productDetailsList.map((elem: any, index: number) => (
                            <Box key={index}>
                               <Box
                                    sx={{
                                        display:
                                            elem?.value?.props?.children
                                                ?.length === 0 ||
                                            elem.value === 'N/A' ||
                                            elem.value === '-' ||
                                            elem.value === ''
                                                ? 'none'
                                                : 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '50%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {elem.key}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '50%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {elem.value}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider
                                    sx={{
                                        mt: 1,
                                        mb: 1,
                                        display:
                                            elem?.value?.props?.children
                                                ?.length === 0 ||
                                            elem.value === 'N/A' ||
                                            elem.value === '-' ||
                                            elem.value === ''
                                                ? 'none'
                                                : 'flex',
                                    }}
                                    variant='fullWidth'
                                    color='black'
                                />
                            </Box>
                        ))}
                    </Box>
                    
          <Box
            sx={{
              display: "none",
              flexDirection: "row",
              gap: "10px",
            }}
          >
           
            <img src={Products} alt="/" width={'100%'} />
          </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: {
                            xs: 'none',
                            sm: 'block',
                        },
                    }}
                ></Box>
            </Grid>
        </>
    );
};

export default ProductDetails;
