import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { useAuth } from '../../../context/AuthContext';
import {
    Box,
    Button,
    AppBar,
    IconButton,
    Badge,
    Typography,
    Grid,
    Menu,
    MenuItem,
    Toolbar,
    ListItemIcon,
    ListItemText,
    MenuList,
    Tooltip,
    Slide,
} from '@mui/material';

import navLogo from '../../../assets/logos/hertex-logo-long.png';
import MenuIcon from '@mui/icons-material/Menu';

import {
    Clear,
    DashboardOutlined,
    KeyboardArrowDown,
    KeyboardArrowUp,
    LogoutOutlined,
    SearchOutlined,
    PersonOutlined,
    SettingsOutlined,
    ShoppingBagOutlined,
} from '@mui/icons-material';
import SearchModal from '../modal/SearchModal';
import { useQuery, useQueryClient } from 'react-query';
import {
    ProductStatResponseModel,
    ProductStatsModel,
    apiPostProductStats,
} from '../../../api/apiProducts';
import { apiGetApplicationNamesByItemGroup } from '../../../api/apiCategories';

function Navbar() {
    const { openCart, cartItemList } = useShoppingCart();

    const { login, logout, isLogin } = useAuth();

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [displayMegaMenu, setDisplayMegaMenu] = useState<boolean>();
    const [displayMobileItemMenu, setDisplayMobileItemMenu] =
        useState<boolean>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [productStats, setProductStats] =
        useState<ProductStatResponseModel | null>(null);
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    const [menuContent, setMenuContent] = useState<any[]>([]);
    const [selectedPage, setSelectedPage] = useState<string | null>(null);
    const [rugsContent, setRugsContent] = useState<string[]>([]);
    const [fabricsContent, setFabricsContent] = useState<string[]>([]);
    const [wallpaperContent, setWallpaperContent] = useState<string[]>([]);
    const [brandsContent, setBrandsContent] = useState<string[]>([]);

    enum ItemGroupCode {
        FABRIC = '005',
        WALLPAPER = '001',
        RUGS = '004',
        HOMEWARE = '000',
        OUTDOOR = '9001',
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        queryClient.invalidateQueries('search-term');
    };

    const productStatsData = {
        data: {
            items_groups_stats: [],
        },
    };

    useQuery<ProductStatResponseModel, Error>(
        'productStats',
        () => apiPostProductStats(productStatsData),
        {
            onSuccess: (data) => {
                setProductStats(data);
            },
            onError: (error) => {
                console.error(error);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    useQuery<ProductStatResponseModel, Error>(
        'brands',
        () =>
            apiPostProductStats({
                data: {
                    items: {
                        get_count: false,
                        item_group: '',
                        required_item_info: ['brand'],
                    },
                },
            } as ProductStatsModel),
        {
            onSuccess: (data) => {
                const apiArray: string[] | undefined = data.brand;
                const internationals = 'Internationals';
                setBrandsContent([...(apiArray ?? []), internationals].sort());
            },
            onError: (error) => {
                console.error(error);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    useQuery(
        'rugsContent',
        () => apiGetApplicationNamesByItemGroup(ItemGroupCode.RUGS),
        {
            onSuccess: (response) => {
                setRugsContent(response.appliactions || []);
            },
            onError: (err) => {
                console.error(err);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    useQuery(
        'fabricsContent',
        () => apiGetApplicationNamesByItemGroup(ItemGroupCode.FABRIC),
        {
            onSuccess: (response) => {
                setFabricsContent(response.appliactions);
            },
            onError: (err) => {
                console.error(err);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    useQuery(
        'wallpaperContent',
        () => apiGetApplicationNamesByItemGroup(ItemGroupCode.WALLPAPER),
        {
            onSuccess: (response) => {
                setWallpaperContent([
                    'Wallpaper Calculator',
                    ...response.appliactions,
                ]);
                // setWallpaperContent(response.appliactions);
            },
            onError: (err) => {
                console.error(err);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    const homewareContent = productStats?.homeware
        ? [...Object.keys(productStats.homeware), 'Outdoor']
        : [];

    const pages = [
        'Fabric',
        'Wallpaper',
        'Rugs',
        'Homeware',
        'Outdoor',
        'Brands',
        'Company',
    ];

    const companyContent = ['About Us', 'Explore Our Spaces', 'Careers'];

    const handlePageClick = (page: string) => {
        if (selectedPage === page) {
            setSelectedPage(null);
        } else {
            setSelectedPage(page);
            setDisplayMobileItemMenu(true);
            getMenuItemContent(page);
        }
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const getMenuItemContent = (page: string) => {
        switch (page) {
            case 'Fabric':
                setMenuContent(fabricsContent);
                break;
            case 'Company':
                setMenuContent(companyContent);
                break;
            case 'Homeware':
                setMenuContent(homewareContent);
                break;
            case 'Brands':
                setMenuContent(brandsContent);
                break;
            case 'Wallpaper':
                setMenuContent(wallpaperContent.sort());
                break;
            case 'Rugs':
                setMenuContent(rugsContent);
                break;
        }
    };

    const handleNavigate = (_: string, brand?: string) => {
        switch (brand) {
            case 'Curtaining':
            case 'Upholstery':
            case 'Dual Purpose':
                const formattedCategory = brand.toLowerCase().replace('-', ' ');
                navigate(`../item_group/005/application/${formattedCategory}`);
                break;

            // !Company

            case 'About Us':
                navigate('../about-us');
                break;
            case 'Explore Our Spaces':
                navigate('../store-locator');
                break;
            case 'Careers':
                navigate('../careers');
                break;

            // ! Homeware

            case 'Bedroom':
                navigate('../item_group/006');
                break;
            case 'Illumination':
                navigate('../item_group/007');
                break;
            case 'Tableware':
                navigate('../item_group/008');
                break;
            case 'Furniture':
                navigate('../item_group/009');
                break;
            case 'Decorative':
                navigate('../item_group/010');
                break;
            case 'Tradehaus Rugs':
                navigate('../item_group/011');
                break;
            case 'Throws, Quilts and Furs':
                navigate('../item_group/013');
                break;
            case 'Wall Decor':
                navigate('../item_group/014');
                break;
            case 'Curtains':
                navigate('../item_group/015');
                break;
            case 'Bathroom':
                navigate('../item_group/016');
                break;
            case 'Scatters':
                navigate('../item_group/018');
                break;
            case 'Outdoor':
                navigate(
                    '../item_group/9001/application_type/outdoor/category_name/000'
                );
                break;

            // !Wallpaper
            case 'Hertex Wallpaper':
                navigate('../item_group/001/application/hertex wallpaper');
                break;
            case 'Wallpaper Calculator':
                navigate('../wallpaper-calculator');
                break;
            // !Rugs
            case 'Compact Runners':
                navigate('../item_group/004/application/compact runners');
                break;

            case 'Hallway Runners':
                navigate('../item_group/004/application/hallway runners');
                break;

            case 'Kitchen Runners':
                navigate('../item_group/004/application/kitchen runners');
                break;

            case 'Rugs':
                navigate('../item_group/004/application/rugs');
                break;

            case 'Internationals':
                navigate('/internationals');
                break;

            default:
                if (brand) {
                    const encodedBrand = encodeURIComponent(brand);
                    navigate(`../item_group/9000/brand/${encodedBrand}`);
                }
                break;
        }
    };

    return (
        <>
            <AppBar position='sticky' color='secondary' elevation={0}>
                {/* Relative positioning for mega menu*/}
                <Box sx={{ position: 'relative' }}>
                    <Toolbar disableGutters>
                    <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    lg: 'flex',
                                    alignItems: 'left',
                                },
                                ml: "2%",
                                width: {md:"15%", lg:"10%"},
                            }}
                        >
                            <img
                                style={{
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                                src={navLogo}
                                alt='Nav logo'
                                onClick={() => {
                                    navigate('/');
                                }}
                            />
                        </Box>

                        {/* Mobile View */}
                        <Box
                            sx={{
                                width: '100%',
                                px: '10px',
                                display: { xs: 'flex', lg: 'none' },
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box
                                sx={{
                                    display: {
                                        xs: 'flex',
                                        lg: 'none',
                                    },
                                    width: '150px',
                                    justifySelf: 'center',
                                    alignSelf: 'center',
                                    mr: 2,
                                }}
                            >
                                <img
                                    style={{
                                        width: '100%',
                                        cursor: 'pointer',
                                    }}
                                    src={navLogo}
                                    alt='Nav logo'
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                />
                            </Box>
                            <Box>
                                <IconButton
                                    sx={{ padding: '1rem' }}
                                    size='large'
                                    aria-label='search'
                                    color='inherit'
                                    onClick={() => handleOpenModal()}
                                >
                                    <SearchOutlined
                                        style={{
                                            width: '27px',
                                            height: '23px',
                                        }}
                                    />
                                </IconButton>
                                {isLogin && (
                                    <IconButton
                                        size='large'
                                        aria-label='show cart quantity'
                                        color='inherit'
                                        onClick={() => {
                                            openCart();
                                        }}
                                    >
                                        <Badge
                                            badgeContent={cartItemList.length}
                                            color='primary'
                                        >
                                            <ShoppingBagOutlined
                                                style={{
                                                    width: '23px',
                                                    height: '23px',
                                                }}
                                            />{' '}
                                        </Badge>
                                    </IconButton>
                                )}

                                <IconButton
                                    size='large'
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={handleOpenNavMenu}
                                    color='inherit'
                                >
                                    {anchorElNav ? <Clear /> : <MenuIcon />}
                                </IconButton>
                            </Box>

                            {/* Mobile Menu */}
                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorElNav}
                                marginThreshold={0}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {
                                        xs: 'block',
                                        lg: 'none',
                                    },
                                }}
                                elevation={0}
                                PaperProps={{
                                    style: {
                                        width: '100%',
                                        maxWidth: '100%',
                                        height: '100%',
                                        maxHeight: 'calc(100vh - 50px)',
                                        marginTop: '50px',
                                        left: 0,
                                        right: 0,
                                        borderRadius: '0',
                                        overflow: 'auto',
                                    },
                                }}
                                TransitionComponent={Slide}
                                transitionDuration={300}
                            >
                                <Box>
                                    <MenuList>
                                        {pages.map((page) => (
                                            <MenuItem
                                                key={page}
                                                // onClick={handleCloseNavMenu}
                                                onClick={() =>
                                                    handlePageClick(page)
                                                }
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                    >
                                                        <Box>
                                                            <ListItemText>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Sans-serif',
                                                                        fontWeight:
                                                                            'semibold',
                                                                        fontSize:
                                                                            '14px',
                                                                        textTransform:
                                                                            'uppercase',
                                                                        letterSpacing:
                                                                            '1.68px',
                                                                    }}
                                                                >
                                                                    {page}
                                                                </Typography>
                                                            </ListItemText>
                                                        </Box>
                                                        <Box>
                                                            <ListItemIcon>
                                                                <KeyboardArrowDown fontSize='small' />
                                                            </ListItemIcon>
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            background:
                                                                '#EEEEEE',
                                                            width: '1000px',
                                                            pl: '50px',
                                                            ml: '-50px',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        {selectedPage ===
                                                            page && (
                                                            <MenuList>
                                                                {menuContent.map(
                                                                    (
                                                                        elem: string
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                elem
                                                                            }
                                                                            // onClick={handleCloseNavMenu}
                                                                            onClick={() => {
                                                                                handleNavigate(
                                                                                    selectedPage!,
                                                                                    elem
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                p: '0',
                                                                            }}
                                                                        >
                                                                            <ListItemText>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Sans-serif',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        textTransform:
                                                                                            'uppercase',
                                                                                        fontSize:
                                                                                            '10px',
                                                                                        textAlign:
                                                                                            'left',
                                                                                        width: '100%',
                                                                                        whiteSpace:
                                                                                            'nowrap',
                                                                                        letterSpacing:
                                                                                            '1.68px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        elem
                                                                                    }
                                                                                </Typography>
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </MenuList>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                    {isLogin ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '10px',
                                                px: '10px',
                                            }}
                                        >
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                disableElevation
                                                sx={{
                                                    borderRadius: '0',
                                                    py: '20px',
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        '/admin-portal-dashboard'
                                                    );
                                                }}
                                            >
                                                Dashboard
                                            </Button>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                disableElevation
                                                sx={{
                                                    borderRadius: '0',
                                                    py: '20px',
                                                }}
                                                onClick={() => {
                                                    logout();
                                                }}
                                            >
                                                Logout
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '10px',
                                                px: '10px',
                                            }}
                                        >
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                disableElevation
                                                sx={{
                                                    borderRadius: '0',
                                                    py: '20px',
                                                }}
                                                onClick={() => {
                                                    login();
                                                }}
                                            >
                                                Login
                                            </Button>{' '}
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                disableElevation
                                                sx={{
                                                    borderRadius: '0',
                                                    py: '20px',
                                                }}
                                                onClick={() =>
                                                    navigate('/register')
                                                }
                                            >
                                                Register
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Menu>
                        </Box>

                        {/* Desktop Menu */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', lg: 'flex' },
                                justifyContent: 'center',
                                gap: '2%',
                            }}
                        >
                            {pages.map((page, index: number) => (
                                <Box key={index}>
                                    <Button
                                        key={page}
                                        onClick={(e) => {
                                            handleCloseNavMenu();
                                            if (page === 'Outdoor') {
                                                navigate(
                                                    `/item_group/9001/application_type/outdoor`
                                                );
                                                return;
                                            }
                                            if (
                                                page === 'Company' ||
                                                page === 'Brands'
                                            ) {
                                                return;
                                            }
                                            if (page === 'Wallpaper') {
                                                navigate(
                                                    '../item_group/001/application/hertex wallpaper'
                                                );
                                                return;
                                            }

                                            navigate(
                                                `/item_group/${
                                                    ItemGroupCode[
                                                        page.toUpperCase() as keyof typeof ItemGroupCode
                                                    ]
                                                }`
                                            );
                                        }}
                                        onMouseEnter={() => {
                                            if (page === 'Outdoor') {
                                                setDisplayMegaMenu(false);
                                            } else {
                                                setDisplayMegaMenu(true);
                                                getMenuItemContent(page);
                                            }
                                        }}
                                        sx={{
                                            fontFamily: 'Sans-serif',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            px: '5px',
                                            letterSpacing: '3px',
                                        }}
                                    >
                                        {page}
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                        <Box
                            sx={{ display: { xs: 'none', lg: 'flex' }, mr: "2%" }}
                        >
                            <Tooltip title='Search'>
                                <Box>
                                    <IconButton
                                        sx={{ padding: '1rem' }}
                                        size='large'
                                        aria-label='search'
                                        color='inherit'
                                        onClick={() => handleOpenModal()}
                                    >
                                        <SearchOutlined
                                            style={{
                                                width: '27px',
                                                height: '23px',
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                            {isLogin && (
                                <Tooltip title='Cart'>
                                    <IconButton
                                        sx={{ padding: '1rem' }}
                                        size='large'
                                        aria-label='show cart quantity'
                                        color='inherit'
                                        onClick={openCart}
                                    >
                                        <Badge
                                            badgeContent={cartItemList.length}
                                            color='primary'
                                        >
                                            <ShoppingBagOutlined
                                                style={{
                                                    width: '23px',
                                                    height: '23px',
                                                }}
                                            />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            )}

                            {isLogin ? (
                                <>
                                    <Tooltip title='Dashboard'>
                                        <IconButton
                                            sx={{ padding: '1rem' }}
                                            size='small'
                                            aria-label='login'
                                            color='inherit'
                                            onClick={() => {
                                                navigate(
                                                    '/admin-portal-dashboard'
                                                );
                                            }}
                                        >
                                            <DashboardOutlined
                                                style={{
                                                    width: '23px',
                                                    height: '23px',
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Logout'>
                                        <IconButton
                                            sx={{ padding: '1rem' }}
                                            size='small'
                                            aria-label='logout'
                                            color='inherit'
                                            onClick={() => {
                                                logout();
                                            }}
                                        >
                                            <LogoutOutlined
                                                style={{
                                                    width: '23px',
                                                    height: '23px',
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                // onClick={() => {
                                //   login();
                                // }}
                                <Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Button
                                        sx={{ borderRadius: '0px', py: 0 }}
                                        disableElevation
                                        variant='contained'
                                        // size="small"
                                        aria-label='login'
                                        onClick={handleClick}
                                        endIcon={
                                            anchorEl ? (
                                                <KeyboardArrowUp />
                                            ) : (
                                                <KeyboardArrowDown />
                                            )
                                        }
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                color: '#FFF',
                                                fontWeight: '700',
                                                textTransform: 'uppercase',
                                                letterSpacing: '1.68px',
                                            }}
                                        >
                                            Trade Portal
                                        </Typography>
                                    </Button>
                                    <Menu
                                        elevation={1}
                                        PaperProps={{
                                            sx: {
                                                width: anchorEl
                                                    ? anchorEl.clientWidth
                                                    : undefined,
                                                borderRadius: '0',
                                            },
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        id='trade-portal-menu'
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby':
                                                'trade-portal-button',
                                        }}
                                    >
                                        <MenuItem onClick={() => login()}>
                                            {' '}
                                            <ListItemIcon>
                                                <PersonOutlined
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: '#000',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    sx={{
                                                        color: '#000',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        textTransform:
                                                            'uppercase',
                                                        letterSpacing: '1.68px',
                                                    }}
                                                >
                                                    Login
                                                </Typography>
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                navigate('/register')
                                            }
                                        >
                                            {' '}
                                            <ListItemIcon>
                                                <SettingsOutlined
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: '#000',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    sx={{
                                                        color: '#000',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        textTransform:
                                                            'uppercase',
                                                        letterSpacing: '1.68px',
                                                    }}
                                                >
                                                    Register
                                                </Typography>
                                            </ListItemText>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            )}
                        </Box>
                    </Toolbar>

                    {/* Mega Menu Content */}
                    {displayMegaMenu && (
                        <Box
                            sx={{
                                width: '100%',
                                position: 'absolute',
                                bottom: '-30',
                                zIndex: '999',
                                backgroundColor: '#F9FAFB',
                                display: 'flex',
                                padding: '2rem',
                            }}
                            onMouseLeave={() => {
                                setDisplayMegaMenu(false);
                            }}
                        >
                            <Grid container justifyContent='center'>
                                {menuContent.map((elem: any, index: number) => (
                                    <Grid
                                        key={index}
                                        item
                                        xs={3}
                                        spacing={2}
                                        sx={{
                                            backgroundColor: '#fff',
                                            height: '2rem',
                                            margin: '0.5rem 0.5rem',
                                            padding: '2rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            textTransform: 'uppercase',
                                            fontSize: '0.8rem',
                                            lineHeight: '1.75rem',
                                            letterSpacing: '0.12rem',
                                        }}
                                        onClick={() => {
                                            handleNavigate(selectedPage!, elem);
                                        }}
                                    >
                                        {elem}
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    )}
                </Box>
            </AppBar>

            <SearchModal
                modalIsOpen={modalIsOpen}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
            />
        </>
    );
}

export default Navbar;
