import TextHeader from '../../components/shared/textHeader/TextHeader';
import ResourcesFilterComponent from '../../components/resources/filter/ResourcesFilterComponent';
import { Container } from '@mui/material';
import ResourcesPageLayout from '../../components/shared/layout/ResourcesPageLayout';

function ResourcesProducts() {
    return (
        <ResourcesPageLayout>
            <Container maxWidth='xl'>
                <TextHeader
                    heading={'Products (multifilter)'}
                    isCentered={false}
                    width='100%'
                    marginTop={10}
                    marginBottom={0}
                />
                <ResourcesFilterComponent />
            </Container>
        </ResourcesPageLayout>
    );
}

export default ResourcesProducts;
