import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

function PrivateRoutes() {
    const { isLogin } = useAuth();

    return isLogin ? <Outlet /> : <Navigate to='/' />;
}

export default PrivateRoutes;
