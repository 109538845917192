import AdminPortalPageLayout from "../components/AdminPortalPageLayout";
import { Box, Typography, CircularProgress } from "@mui/material";
import { apiGetOrdersStats } from "../../../api/apiOrders";
import { useQuery } from "react-query";
import DashboardHeader from "../components/header/DashboardHeader";
import DashboardCard from "../components/card/DashboardCard";

function DashboardPage() {
  const user = JSON.parse(localStorage.getItem("user") || "");

  const authToken = localStorage.getItem("authToken") || null;

  const {
    isError,
    isLoading,
    data: orderStatsData,
  } = useQuery<any, Error>([`order-stats`], () => apiGetOrdersStats(authToken));


  const dashboardData = [
    {
      id: 1,
      title: "Sales Orders - Open",
      value: `${orderStatsData?.stats?.pending_orders}`,
      link: "/admin-portal-open-orders",
    },
    {
      id: 2,
      title: "Sales Orders - Closed",
      value: `${orderStatsData?.stats?.processed_orders}`,
      link: "/admin-portal-closed-orders",
    },
    {
      id: 3,
      title: "Total Orders",
      value: `${orderStatsData?.stats?.total_orders}`,
      link: "/admin-portal-dashboard",
    },
  ];
  return (
    <AdminPortalPageLayout>
      {isError && (
        <>
          <Typography>An error occured, please try again later</Typography>
        </>
      )}
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            width: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DashboardHeader
            title={`Welcome back, ${user.given_name}`}
            subTitle="Track & manage your Sales orders"
          />

          <Box
            sx={{ marginTop: { xs: "20vh", lg: "25px" }, marginBottom: "25px" }}
          >
            {dashboardData.map((data) => (
              <DashboardCard
                title={data?.title}
                value={Number(data?.value)}
                link={data?.link}
                displayViewBtn={!(data?.title === "Total Orders")}
              />
            ))}
          </Box>
        </>
      )}
    </AdminPortalPageLayout>
  );
}

export default DashboardPage;
