import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputField from '../../../components/shared/formFields/InputField';
import SelectField from '../../../components/shared/formFields/SelectField';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import {
    Typography,
    Box,
    Grid,
    Button,
    Stack,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Checkbox,
    Divider,
} from '@mui/material';

import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface User {
    email?: string;
    given_name?: string;
    family_name?: string;
}

interface ShippingAddressFields {
    email?: string;
    name?: string;
    surname?: string;
    address?: string;
    city?: string;
    country?: string;
    province?: string;
    postalCode?: string;
    suburb?: string;
    apartment?: string;
    contact_number?: number;
}

function UserInformation({
    updateStep,
    formData,
    setFormData,
    step,
    setStep,
    setIsShipping,
    isShipping,
    setIsDefaultShip,
    isDefaultShip,
    previousStep,
}: any) {
    const {
        cartItemList,
        setCartItemList,
        setShippingSelected,
        setDefaultShipSelected,
        cartService,
        cartItemData,
        cartAddressDetails,
        cartId,
    } = useShoppingCart();

    const [isPickUp, setIsPickUp] = useState(false);

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<any>({});
    const authToken = cartService.authToken;
    const { user } = useAuth() as {
        user: User | undefined;
    };
    const [deliveryMethod, setDeliveryMethod] = useState(
        formData.deliveryMethod
    );

    const formSubmit = handleSubmit(async (data, e) => {
        e?.preventDefault();

        let shippingAddressFields: ShippingAddressFields | undefined;
        const customerOrderNum = data.customerOrderNum;
        const deliveryNotes = data.deliveryNotes;
        const contactNumber =
            deliveryMethod === 'pickUp' ? '' : data.contactNumber;
        if (deliveryMethod === 'ship') {
            shippingAddressFields = {
                email: user?.email,
                // name: user?.given_name,
                // surname: user?.family_name,
                name: data.name,
                surname: data.surname,
                address: cartAddressDetails.physical1,
                apartment: cartAddressDetails.physical2,
                suburb: cartAddressDetails.physical3,
                city: cartAddressDetails.physical4,
                country: cartAddressDetails.physical5,
                province: '',
                postalCode: cartAddressDetails?.physicalPC,
            };
        } else if (deliveryMethod === 'pickUp') {
            shippingAddressFields = {
                name: data.name,
                surname: data.surname,
                email: user?.email,

                address: 'Pick up selected',
            };
        } else if (deliveryMethod === 'delivery') {
            shippingAddressFields = {
                email: user?.email,
                contact_number: data.contact_number,
                name: data.name,
                surname: data.surname,
                address: data.address,
                city: data.city,
                country: data.country,
                province: data.province,
                postalCode: data.postalCode,
                suburb: data.suburb,
                apartment: data.additionalAddressPropertyType,
            };
        }

        try {
            if (cartItemList.length > 0) {
                setCartItemList(
                    cartItemList.map((item: any) => {
                        return {
                            ...item,
                            altShippingAddress:
                                isShipping || isDefaultShip
                                    ? shippingAddressFields
                                    : {
                                          name: user?.given_name,
                                          email: user?.email,
                                          address: 'Pick up selected',
                                      },
                            email: !data.email ? user?.email : data.email,
                            orderNotes: data.orderNotes || 'None',
                        };
                    })
                );
            }
        } catch (error) {
            console.error('Failed to update cart items:', error);
        }

        // try {
        //     if (cartItemList.length > 0) {
        //         const updatedCartItems = cartItemList.map((item: any) => ({
        //             ...item,
        //             // altShippingAddress:
        //             //     isShipping || isDefaultShip
        //             //         ? shippingAddressFields
        //             //         : { address: 'Pick up selected' },
        //             // email: !data.email ? user?.email : data.email,
        //             // orderNotes: data.orderNotes || 'None',
        //         }));

        //         if (cartItemData.id) {
        //             const updatedCart = cartService.updateCartItems(
        //                 cartItemData.id,
        //                 updatedCartItems
        //             );
        //         } else {
        //             console.error('No cart ID found');
        //         }
        //     }
        // } catch (error) {
        //     console.error('Failed to update cart items:', error);
        // }

        // try {
        //     updateStep();
        //     const updatedBilling = await cartService.updateBillingAddress(
        //         authToken,
        //         cartItemData.id,
        //         shippingAddressFields
        //     );

        //     setFormData({
        //         ...formData,
        //         email: data.email,
        //         name: data.name,
        //         surname: data.surname,
        //         address: data.address,
        //         additionalAddressPropertyType:
        //             data.additionalAddressPropertyType,
        //         city: data.city,
        //         country: data.country,
        //         province: data.province,
        //         postalCode: data.postalCode,
        //     });

        //     // updateStep();
        // } catch (error) {
        //     console.error('Failed to update billing address:', error);
        // }
        // cartService.updateBillingAddress(
        //     authToken,
        //     cartId,
        //     shippingAddressFields
        // );
        // cartService.updateCustomerOrderNum(authToken, cartId, customerOrderNum);
        cartService.updateUserInformation(
            authToken,
            cartId,
            shippingAddressFields,
            customerOrderNum,
            deliveryNotes,
            contactNumber
        );
        updateStep();
    });

    useEffect(() => {
        setDefaultShipSelected(isDefaultShip);

        setShippingSelected(isShipping);
    }, [
        setShippingSelected,
        isShipping,
        isDefaultShip,
        setDefaultShipSelected,
    ]);

    return (
        <>
            <form noValidate onSubmit={formSubmit}>
                <Stack mt={2} mb={1}>
                    <Box
                        sx={{
                            mt: 2,
                            mb: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '27px',
                            }}
                        >
                            Customer Order Number
                        </Typography>

                        <InputField
                            defaultValue={cartItemData?.customer_order_num}
                            marginTop={1}
                            type='customerOrderNum'
                            id='customerOrderNum'
                            name='customerOrderNum'
                            placeholder='Enter customer order number'
                            register={register}
                            errors={errors}
                            errorMsg=''
                        />
                    </Box>

                    <Box
                        sx={{
                            mt: 2,
                            mb: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '27px',
                            }}
                        >
                            Delivery Note
                        </Typography>

                        <InputField
                            defaultValue={cartItemData?.order_notes}
                            marginTop={1}
                            type='deliveryNotes'
                            id='deliveryNotes'
                            name='deliveryNotes'
                            placeholder='Enter delivery note'
                            register={register}
                            errors={errors}
                            errorMsg=''
                            multiline
                        />
                    </Box>
                </Stack>

                <Stack mt={3} mb={3}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: '400',
                            lineHeight: '27px',
                            mb: 2,
                        }}
                    >
                        Delivery Method
                    </Typography>
                    <FormControl id='deliveryMethod'>
                        <RadioGroup
                            aria-labelledby='shipping method'
                            defaultValue={formData.deliveryMethod}
                            {...register(`deliveryMethod`)}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    border: '1px solid #D9D9D9',
                                    pl: '17px',
                                }}
                            >
                                <Box sx={{ flex: { xs: 12, lg: 3 } }}>
                                    <FormControlLabel
                                        value='ship'
                                        control={<Radio />}
                                        label='Deliver'
                                        {...register(`deliveryMethod`)}
                                        onClick={() => {
                                            setIsDefaultShip(true);
                                            setIsShipping(false);
                                            setDeliveryMethod('ship');
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    borderRight: '1px solid #D9D9D9',
                                    borderLeft: '1px solid #D9D9D9',
                                    borderBottom: '1px solid #D9D9D9',

                                    pl: '17px',
                                }}
                            >
                                <Box sx={{ flex: { xs: 12, lg: 3 } }}>
                                    <FormControlLabel
                                        sx={{
                                            width: '100%',
                                        }}
                                        value='pickUp'
                                        control={<Radio />}
                                        label='Collection'
                                        {...register(`deliveryMethod`)}
                                        onClick={() => {
                                            setIsShipping(false);
                                            setIsDefaultShip(false);
                                            setIsPickUp(true);
                                            setDeliveryMethod('pickUp');
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    borderRight: '1px solid #D9D9D9',
                                    borderLeft: '1px solid #D9D9D9',
                                    borderBottom: '1px solid #D9D9D9',

                                    pl: '17px',
                                }}
                            >
                                <Box sx={{ flex: { xs: 12, lg: 3 } }}>
                                    <FormControlLabel
                                        value='delivery'
                                        control={<Radio />}
                                        label='3rd Party Delivery'
                                        {...register(`deliveryMethod`)}
                                        onClick={() => {
                                            setIsShipping(true);
                                            setIsDefaultShip(false);
                                            setDeliveryMethod('delivery');
                                        }}
                                    />
                                </Box>
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </Stack>

                {isDefaultShip && (
                    <Stack>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '27px',
                            }}
                        >
                            Shipping address
                        </Typography>
                        {/* <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <Box sx={{ flex: 1, mr: 2 }}>
                                {' '}
                                <InputField
                                    defaultValue={user?.given_name}
                                    value={user?.given_name}
                                    marginTop={2}
                                    type='text'
                                    id='name'
                                    name='name'
                                    placeholder='Enter name'
                                    // isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter your name'
                                    disabled
                                />
                            </Box>

                            <Box sx={{ flex: 1, ml: 2 }}>
                                {' '}
                                <InputField
                                    defaultValue={user?.family_name}
                                    value={user?.family_name}
                                    marginTop={2}
                                    type='text'
                                    id='surname'
                                    name='surname'
                                    placeholder='Enter last name'
                                    // isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter your surname'
                                    disabled
                                />
                            </Box>
                        </Box> */}

                        <InputField
                            defaultValue={cartAddressDetails.physical1}
                            value={cartAddressDetails.physical1}
                            marginTop={2}
                            type='text'
                            id='address'
                            name='address'
                            placeholder='Enter address'
                            // isRequired={true}
                            register={register}
                            errors={errors}
                            errorMsg='Please enter your address'
                            disabled
                        />

                        <InputField
                            // defaultValue={
                            //     formData.additionalAddressPropertyType
                            // }
                            defaultValue={cartAddressDetails.physical2}
                            value={cartAddressDetails.physical2}
                            marginTop={2}
                            type='text'
                            id='additionalAddressPropertyType'
                            name='additionalAddressPropertyType'
                            placeholder='Enter apartment, suite, etc. (optional)'
                            // isRequired={false}
                            register={register}
                            errors={errors}
                            disabled
                        />

                        <InputField
                            // defaultValue={formData.city}
                            defaultValue={cartAddressDetails.physical3}
                            value={cartAddressDetails.physical3}
                            marginTop={2}
                            type='text'
                            id='suburb'
                            name='suburb'
                            placeholder='Enter suburb'
                            // isRequired={true}
                            register={register}
                            errors={errors}
                            errorMsg='Please enter your suburb'
                            disabled
                        />
                        <InputField
                            // defaultValue={formData.city}
                            defaultValue={cartAddressDetails.physical4}
                            value={cartAddressDetails.physical4}
                            marginTop={2}
                            type='text'
                            id='city'
                            name='city'
                            placeholder='Enter city'
                            // isRequired={true}
                            register={register}
                            errors={errors}
                            errorMsg='Please enter your city'
                            disabled
                        />

                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box sx={{ flex: 4, mr: 1 }}>
                                <InputField
                                    defaultValue={cartAddressDetails.physical5}
                                    value={cartAddressDetails.physical5}
                                    marginTop={2}
                                    type='text'
                                    id='country'
                                    name='country'
                                    placeholder='Enter country'
                                    // isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter your country'
                                    disabled
                                />

                                {/* <SelectField
                                    marginTop={2}
                                    id='country'
                                    register={register}
                                    errors={errors}
                                    // isRequired={true}
                                    errorMsg='Please select a country'
                                    label='Country/Region'
                                    // defaultValue={formData.country}
                                    defaultValue={cartAddressDetails.physical4}
                                    value={cartAddressDetails.physical4}
        
                                    itemList={[
                                        { name: 'South Africa' },
                                        { name: 'Spain' },
                                        { name: 'England' },
                                    ]}
                                    disabled

                                /> */}
                            </Box>
                            {/* <Box sx={{ flex: 4, mr: 1, ml: 1 }}>
                                <SelectField
                                    marginTop={2}
                                    id='province'
                                    register={register}
                                    errors={errors}
                                    // isRequired={true}
                                    errorMsg='Please select a province'
                                    label='Province'
                                    defaultValue={formData.province}
                                    itemList={[
                                        { name: 'Gauteng' },
                                        { name: 'Western Cape' },
                                        { name: 'Eastern Cape' },
                                        { name: 'Limpopo' },
                                        { name: 'North West' },
                                        { name: 'Kwazulu-Natal' },
                                        { name: 'Northern Cape' },
                                        { name: 'Mpumalanga' },
                                    ]}
                                    disabled

                                />
                            
                            </Box> */}
                            <Box sx={{ flex: 4, ml: 0 }}>
                                <InputField
                                    // defaultValue={formData.city}
                                    defaultValue={''}
                                    value={''}
                                    marginTop={2}
                                    type='text'
                                    id='province'
                                    name='province'
                                    placeholder='Enter province'
                                    // isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter your province'
                                    disabled
                                />
                            </Box>
                            <Box sx={{ flex: 4, ml: 1 }}>
                                <InputField
                                    defaultValue={cartAddressDetails.physicalPC}
                                    value={cartAddressDetails.physicalPC}
                                    marginTop={2}
                                    type='text'
                                    id='postalCode'
                                    name='postalCode'
                                    placeholder='Enter postal code'
                                    // isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter your postal code'
                                    disabled
                                />
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '400',
                                    lineHeight: '27px',
                                }}
                            >
                                Recipient Contact Information
                            </Typography>

                            {/* {isShipping && ( */}
                            <InputField
                                defaultValue={cartItemData?.contact_number}
                                marginTop={2}
                                type='number'
                                id='contactNumber'
                                name='contactNumber'
                                placeholder='Enter recipient contact number'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter a valid recipient contact number'
                                validationPattern={
                                    /^(\+)?(0|27)([1-9])([0-9])( )?([0-9]{3})( )?([0-9]{4})$/
                                }
                            />

                            {/* {isDefaultShip && (
                        <InputField
                            defaultValue={cartItemData?.contact_number}
                            marginTop={2}
                            type='number'
                            id='contactNumber'
                            name='contactNumber'
                            placeholder='Enter contact number'
                            isRequired={true}
                            register={register}
                            errors={errors}
                            errorMsg='Please enter a valid contact number'
                            validationPattern={
                                /^(\+)?(0|27)([1-9])([0-9])( )?([0-9]{3})( )?([0-9]{4})$/
                            }
                            // disabled
                        />
                    )} */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <Box sx={{ flex: 1, mr: 2 }}>
                                    {' '}
                                    <InputField
                                        defaultValue={
                                            !cartItemData?.billing_address?.name
                                                ? cartItemData?.billing_address
                                                      ?.name
                                                : ''
                                        }
                                        // value={!cartItemData?.billing_address?.name ? cartItemData?.billing_address?.name: ''}
                                        marginTop={2}
                                        type='text'
                                        id='name'
                                        name='name'
                                        placeholder='Enter recipient name'
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                        errorMsg='Please enter recipient name'
                                        // disabled
                                    />
                                </Box>

                                <Box sx={{ flex: 1, ml: 2 }}>
                                    {' '}
                                    <InputField
                                        defaultValue={
                                            !cartItemData?.billing_address?.name
                                                ? cartItemData?.billing_address
                                                      ?.surname
                                                : ''
                                        }
                                        // value={!cartItemData?.billing_address?.name ? cartItemData?.billing_address?.surname:  ''}
                                        marginTop={2}
                                        type='text'
                                        id='surname'
                                        name='surname'
                                        placeholder='Enter recipient surname'
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                        errorMsg='Please enter recipient surname'
                                        // disabled
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '-10px',
                                mt: 2,
                            }}
                        >
                            <Checkbox defaultChecked />
                            <Typography
                                sx={{
                                    color: '#545454',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                }}
                            >
                                Save this information for next time
                            </Typography>
                        </Box>
                    </Stack>
                )}

                {isShipping && (
                    <Stack>
                        <Typography>3rd Party Delivery address</Typography>
                        {/* <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <Box sx={{ flex: 1, mr: 2 }}>
                                {' '}
                                <InputField
                                    defaultValue={formData.name}
                                    marginTop={2}
                                    type='text'
                                    id='name'
                                    name='name'
                                    placeholder='Enter name'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter your name'
                                />
                            </Box>

                            <Box sx={{ flex: 1, ml: 2 }}>
                                {' '}
                                <InputField
                                    defaultValue={formData.surname}
                                    marginTop={2}
                                    type='text'
                                    id='surname'
                                    name='surname'
                                    placeholder='Enter last name'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter your surname'
                                />
                            </Box>
                        </Box> */}

                        <InputField
                            defaultValue={formData.address}
                            marginTop={2}
                            type='text'
                            id='address'
                            name='address'
                            placeholder='Enter address'
                            isRequired={true}
                            register={register}
                            errors={errors}
                            errorMsg='Please enter your address'
                        />

                        <InputField
                            defaultValue={
                                formData.additionalAddressPropertyType
                            }
                            marginTop={2}
                            type='text'
                            id='additionalAddressPropertyType'
                            name='additionalAddressPropertyType'
                            placeholder='Enter apartment, suite, etc. (optional)'
                            isRequired={false}
                            register={register}
                            errors={errors}
                        />
                        <InputField
                            defaultValue={formData.suburb}
                            marginTop={2}
                            type='text'
                            id='suburb'
                            name='suburb'
                            placeholder='Enter suburb'
                            isRequired={false}
                            register={register}
                            errors={errors}
                        />
                        {/* <InputField
                            // defaultValue={
                            //     formData.additionalAddressPropertyType
                            // }
                            defaultValue={cartAddressDetails.physical2}
                            value={cartAddressDetails.physical2}
                            marginTop={2}
                            type='text'
                            id='additionalAddressPropertyType'
                            name='additionalAddressPropertyType'
                            placeholder='Enter apartment, suite, etc. (optional)'
                            // isRequired={false}
                            register={register}
                            errors={errors}
                            disabled
                        /> */}

                        <InputField
                            defaultValue={formData.city}
                            marginTop={2}
                            type='text'
                            id='city'
                            name='city'
                            placeholder='Enter city'
                            isRequired={true}
                            register={register}
                            errors={errors}
                            errorMsg='Please enter your city'
                        />

                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box sx={{ flex: 4, mr: 1 }}>
                                <SelectField
                                    marginTop={2}
                                    id='country'
                                    register={register}
                                    errors={errors}
                                    isRequired={true}
                                    errorMsg='Please select a country'
                                    label='Country/Region'
                                    defaultValue={formData.country}
                                    itemList={[
                                        { name: 'South Africa' },
                                        // {
                                        //     name: 'Mauritius',
                                        // },
                                        // {
                                        //     name: 'Namibia',
                                        // },
                                    ]}
                                />
                            </Box>
                            <Box sx={{ flex: 4, mr: 1, ml: 1 }}>
                                <SelectField
                                    marginTop={2}
                                    id='province'
                                    register={register}
                                    errors={errors}
                                    isRequired={true}
                                    errorMsg='Please select a province'
                                    label='Province'
                                    defaultValue={formData.province}
                                    itemList={[
                                        { name: 'Gauteng' },
                                        { name: 'Western Cape' },
                                        { name: 'Eastern Cape' },
                                        { name: 'Limpopo' },
                                        { name: 'North West' },
                                        { name: 'Kwazulu-Natal' },
                                        { name: 'Northern Cape' },
                                        { name: 'Mpumalanga' },
                                    ]}
                                />
                            </Box>
                            <Box sx={{ flex: 4, ml: 1 }}>
                                <InputField
                                    defaultValue={formData.postalCode}
                                    marginTop={2}
                                    type='text'
                                    id='postalCode'
                                    name='postalCode'
                                    placeholder='Enter postal code'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter your postal code'
                                />
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '400',
                                    lineHeight: '27px',
                                }}
                            >
                                Recipient Contact Information
                            </Typography>

                            {/* {isShipping && ( */}
                            <InputField
                                defaultValue={cartItemData?.contact_number}
                                marginTop={2}
                                type='number'
                                id='contactNumber'
                                name='contactNumber'
                                placeholder='Enter recipient contact number'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter a valid recipient contact number'
                                validationPattern={
                                    /^(\+)?(0|27)([1-9])([0-9])( )?([0-9]{3})( )?([0-9]{4})$/
                                }
                            />

                            {/* {isDefaultShip && (
                        <InputField
                            defaultValue={cartItemData?.contact_number}
                            marginTop={2}
                            type='number'
                            id='contactNumber'
                            name='contactNumber'
                            placeholder='Enter contact number'
                            isRequired={true}
                            register={register}
                            errors={errors}
                            errorMsg='Please enter a valid contact number'
                            validationPattern={
                                /^(\+)?(0|27)([1-9])([0-9])( )?([0-9]{3})( )?([0-9]{4})$/
                            }
                            // disabled
                        />
                    )} */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <Box sx={{ flex: 1, mr: 2 }}>
                                    {' '}
                                    <InputField
                                        defaultValue={
                                            !cartItemData?.billing_address?.name
                                                ? cartItemData?.billing_address
                                                      ?.name
                                                : ''
                                        }
                                        // value={!cartItemData?.billing_address?.name ? cartItemData?.billing_address?.name: ''}
                                        marginTop={2}
                                        type='text'
                                        id='name'
                                        name='name'
                                        placeholder='Enter recipient name'
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                        errorMsg='Please enter recipient name'
                                        // disabled
                                    />
                                </Box>

                                <Box sx={{ flex: 1, ml: 2 }}>
                                    {' '}
                                    <InputField
                                        defaultValue={
                                            !cartItemData?.billing_address?.name
                                                ? cartItemData?.billing_address
                                                      ?.surname
                                                : ''
                                        }
                                        // value={!cartItemData?.billing_address?.name ? cartItemData?.billing_address?.surname:  ''}
                                        marginTop={2}
                                        type='text'
                                        id='surname'
                                        name='surname'
                                        placeholder='Enter recipient surname'
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                        errorMsg='Please enter recipient surname'
                                        // disabled
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '-10px',
                                mt: 2,
                            }}
                        >
                            <Checkbox defaultChecked />
                            <Typography>
                                Save this information for next time
                            </Typography>
                        </Box>
                    </Stack>
                )}

                <Divider sx={{ mt: 2 }} />

                <Grid container mt={2} justifyContent='space-between'>
                    <Box
                        onClick={() => {
                            navigate('/');
                        }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '0px',
                            cursor: 'pointer',
                        }}
                    >
                        <Typography>Return to cart</Typography>
                    </Box>
                    <Button
                        variant='contained'
                        type='submit'
                        onClick={() => formSubmit()}
                        disableElevation
                        sx={{
                            borderRadius: '0px',
                        }}
                        disabled={!isShipping && !isDefaultShip && !isPickUp}
                    >
                        Next
                    </Button>
                </Grid>
            </form>
        </>
    );
}

export default UserInformation;
