import ResourcesNavbar from '../navbar/ResourcesNavbar';
import Footer from '../footer/Footer';

function ResourcesPageLayout({ children }: any) {
    return (
        <>
            <ResourcesNavbar />
            {children}
            <Footer />
        </>
    );
}

export default ResourcesPageLayout;
