import { Box } from "@mui/material";
import AdminPortalMenu from "./AdminPortalMenu";
import AdminPortalPageSpacer from "./AdminPortalPageSpacer";
import AdminPortalMobileMenu from "./AdminPortalMobileMenu";

function AdminPortalPageLayout({ children }: any) {
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: { xs: "none", lg: "block" },
        }}
      >
        <AdminPortalMenu />
      </Box>

      <AdminPortalPageSpacer>
        <Box
          sx={{
            display: { xs: "block", lg: "none" },
          }}
        >
          <AdminPortalMobileMenu />
        </Box>

        {children}
      </AdminPortalPageSpacer>
      {/* <Box
          sx={{
            display: { xs: "block", lg: "none" },
            position: "fixed",
            mt: '-200px',
            bottom: '0',
            width: "100%",
            background: "#000",
            p: "16px"
          }}
        >
          <Typography variant="caption" sx={{
            fontSize: '14px',
            fontWeight: '600',
            color: "#FFF",
          }}>
            Hertex.co.za
          </Typography>
        </Box> */}
    </Box>
  );
}

export default AdminPortalPageLayout;
