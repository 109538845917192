import { useForm } from 'react-hook-form';
import { Box, Button, Grid, Typography, Paper } from '@mui/material';
import InputField from '../../components/shared/formFields/InputField';

function ForgotPasswordPage() {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<any>({});

    const formSubmit = handleSubmit((data, e) => {
        const values = getValues();
        const dataStructure = {
            email: values.email,
            password: values.password,
        };
        e?.preventDefault();
    });
    return (
        <>
            <Box
                sx={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        flexDirection: 'column',
                    }}
                >
                    <Grid
                        container
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Typography>Logo</Typography>
                        <Typography variant='h4' sx={{ m: '2rem 0' }}>
                            Reset your password
                        </Typography>
                    </Grid>
                    <Paper elevation={3} sx={{ p: 3, width: '500px' }}>
                        <form onSubmit={formSubmit}>
                            <Typography>Email</Typography>
                            <InputField
                                marginTop={1}
                                type='email'
                                id='email'
                                name='email'
                                placeholder='Enter email'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter a valid email address'
                                validationPattern={
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                }
                            />
                            <Typography mt={2}>New Password</Typography>
                            <InputField
                                marginTop={1}
                                type='password'
                                id='password'
                                name='password'
                                placeholder='Enter password'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Password invalid'
                            />

                            <Button
                                type='submit'
                                variant='contained'
                                fullWidth
                                sx={{ borderRadius: '0', p: 2, mt: 2 }}
                            >
                                Reset
                            </Button>
                        </form>
                    </Paper>
                </Box>
            </Box>
        </>
    );
}

export default ForgotPasswordPage;
