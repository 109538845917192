import { useState, useRef } from 'react';
import { apiFilterProducts } from '../../../api/apiMultiFilter';
import ProductGrid from './components/ProductGrid';
import CategorySelection from './components/CategorySelection';
import ApplicationSelection from './components/ApplicationSelection';
import ClearFilters from './components/ClearFilters';
import { Grid, Box, Typography, Container, Button } from '@mui/material';
import { useMutation } from 'react-query';

function ResourcesFilterComponent() {
    const [availableCategories, setAvailableCategories] = useState([
        { itemGroup: '005', category: 'Fabric' },
        { itemGroup: '001', category: 'Wallpaper' },
        { itemGroup: '004', category: 'Rugs' },
        { itemGroup: '000', category: 'Homeware' },
    ]);
    const [selectedHomewareCategory, setSelectedHomewareCategory] =
        useState<string>();
    const [homewareCategoryList, setHomewareCategoryList] = useState([]);
    const [isHomeware, setIsHomeware] = useState<boolean>(false);
    const [category, setCategory] = useState('');
    const [categoriesList, setCategoriesList] = useState<String[]>([]);
    const [availableApplications, setAvailableApplications] = useState([]);
    const [applicationsList, setApplicationsList] = useState<string[]>([]);
    const [productItemCount, setProductItemCount] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;
    const totalPages = Math.ceil(productItemCount / itemsPerPage);
    const anchor = useRef<HTMLDivElement>(null);

    const getProductsMutation: any = useMutation(
        (filters: any) => {
            return apiFilterProducts(filters);
        },
        {
            onSuccess: (data) => {
                setAvailableApplications(data[data.length - 1]?.applications);
                setProductItemCount(data[data.length - 1]?.total_items_count);
            },
        }
    );

    //! Updates the state for all properties that can be filtered - colours,design etc
    const handleCheckboxChange =
        (
            elem: string,
            setList: React.Dispatch<React.SetStateAction<string[]>>,
            list: string[]
        ) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = event.target.checked;

            if (isChecked) {
                setList([...list, elem]);
            } else {
                setList(list.filter((listElem) => listElem !== elem));
            }
        };

    const clearFilters = async () => {
        setApplicationsList([]);
        setCurrentPage((prevPage) => {
            prevPage = 1;

            return prevPage;
        });
    };

    const nextPage = async () => {
        if (currentPage === totalPages) {
            return;
        } else {
            setCurrentPage((prevPage) => {
                const nextPage = prevPage + 1;
                const startOffset = (nextPage - 1) * itemsPerPage;
                const endOffset = startOffset + itemsPerPage;

                getProductsMutation.mutate({
                    filter: {
                        item_group: isHomeware
                            ? selectedHomewareCategory
                            : category,
                    },

                    off_setter: {
                        start: startOffset,
                        end: endOffset,
                    },

                    required_fields: {
                        item_group: isHomeware
                            ? selectedHomewareCategory
                            : category,
                        Applications:
                            applicationsList.length === 0
                                ? []
                                : applicationsList,
                    },
                    available_on_resources: true,

                });

                return nextPage;
            });
        }
    };

    const previousPage = () => {
        if (currentPage === 1) {
            return;
        } else {
            setCurrentPage((prevPage) => {
                const previousPage = prevPage - 1;
                const startOffset = (previousPage - 1) * itemsPerPage;
                const endOffset = startOffset + itemsPerPage;

                getProductsMutation.mutate({
                    filter: {
                        item_group: isHomeware
                            ? selectedHomewareCategory
                            : category,
                    },

                    off_setter: {
                        start: startOffset,
                        end: endOffset,
                    },

                    required_fields: {
                        item_group: isHomeware
                            ? selectedHomewareCategory
                            : category,
                        Applications:
                            applicationsList.length === 0
                                ? []
                                : applicationsList,
                    },
                    available_on_resources: true,

                });

                return previousPage;
            });
        }
    };

    const lastPage = () => {
        if (currentPage === totalPages) {
            return;
        } else {
            setCurrentPage((prev) => {
                prev = totalPages;
                const startOffset = (prev - 1) * itemsPerPage;
                const endOffset = startOffset + itemsPerPage;

                getProductsMutation.mutate({
                    filter: {
                        item_group: isHomeware
                            ? selectedHomewareCategory
                            : category,
                    },

                    off_setter: {
                        start: startOffset,
                        end: endOffset,
                    },
                    required_fields: {
                        item_group: isHomeware
                            ? selectedHomewareCategory
                            : category,
                        Applications:
                            applicationsList.length === 0
                                ? []
                                : applicationsList,
                    },
                    available_on_resources: true,

                });

                return prev;
            });
        }
    };

    const firstPage = () => {
        if (currentPage === 1) {
            return;
        } else {
            setCurrentPage((prev) => {
                prev = 1;
                const startOffset = 0;
                const endOffset = 9;

                getProductsMutation.mutate({
                    filter: {
                        item_group: isHomeware
                            ? selectedHomewareCategory
                            : category,
                    },

                    off_setter: {
                        start: startOffset,
                        end: endOffset,
                    },
                    required_fields: {
                        item_group: isHomeware
                            ? selectedHomewareCategory
                            : category,
                        Applications:
                            applicationsList.length === 0
                                ? []
                                : applicationsList,
                    },
                    available_on_resources: true,

                });

                return prev;
            });
        }
    };

    const filterByCategory = (category: string) => {
        setApplicationsList([]);
        setCurrentPage(1);
        getProductsMutation.mutate({
            filter: {
                item_group: category,
            },
            off_setter: {
                start: 0,
                end: 9,
            },
            required_fields: {
                item_group: category,
                Applications: [],
            },
            available_on_resources: true,
        });
    };

    const filterByApplication = (category: string) => {
        getProductsMutation.mutate({
            filter: {
                item_group: category,
            },
            off_setter: {
                start: 0,
                end: 9,
            },
            required_fields: {
                item_group: category,
                Applications:
                    applicationsList.length === 0 ? [] : applicationsList,
            },
            available_on_resources: true,

        });
    };

    const filterByAllProperties = () => {
        getProductsMutation.mutate({
            filter: {
                item_group: isHomeware ? selectedHomewareCategory : category,
            },
            off_setter: {
                start: 0,
                end: 9,
            },
            required_fields: {
                item_group: isHomeware ? selectedHomewareCategory : category,
                Applications:
                    applicationsList.length === 0 ? [] : applicationsList,
            },
            available_on_resources: true,

        });
    };

    return (
        <Container maxWidth='xl'>
            <Typography
                variant='h3'
                mb={1}
                mt={10}
                ml={2}
                fontWeight={400}
                fontSize={'40px'}
                textTransform={'capitalize'}
            >
                Discover - Resources
            </Typography>
            <Typography fontWeight={400} fontSize='18px' mb={'-4rem'} ml={2}>
                Our inventory features thousands of designs. Use the selection
                bar below to find exactly what you’re looking for.
            </Typography>

            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    height: 'auto',
                    width: '100%',
                    padding: '1rem',
                    m: '4rem 0',
                }}
            >
                <Box
                    sx={{
                        flex: { xs: '12', lg: '4' },
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        mr: { xs: 0, lg: 5 },
                    }}
                >
                    <Box
                        sx={{
                            overflowY: 'auto',
                            maxHeight: '20.5rem',
                        }}
                    ></Box>

                    <ClearFilters clearFilters={clearFilters} />

                    <CategorySelection
                        availableCategories={availableCategories}
                        setAvailableCategories={setAvailableCategories}
                        handleCheckboxChange={handleCheckboxChange}
                        categoriesList={categoriesList}
                        setCategoriesList={setCategoriesList}
                        filterByApplication={filterByApplication}
                        filterByCategory={filterByCategory}
                        setCategory={setCategory}
                        category={category}
                        homewareCategoryList={homewareCategoryList}
                        setHomewareCategoryList={setHomewareCategoryList}
                        selectedHomewareCategory={selectedHomewareCategory}
                        setSelectedHomewareCategory={
                            setSelectedHomewareCategory
                        }
                        isHomeware={isHomeware}
                        setIsHomeware={setIsHomeware}
                    />

                    <ApplicationSelection
                        availableApplications={availableApplications}
                        handleCheckboxChange={handleCheckboxChange}
                        applicationsList={applicationsList}
                        setApplicationsList={setApplicationsList}
                    />

                    <Button
                        variant='contained'
                        onClick={() => {
                            filterByAllProperties();
                            window.scrollTo({
                                top: anchor?.current?.offsetTop! - 65,
                                behavior: 'smooth',
                            });
                        }}
                        sx={{
                            width: { xs: '100%', lg: '50%' },
                        }}
                        disableElevation
                    >
                        Apply
                    </Button>
                </Box>
                <Box
                    flex={{ xs: 12, lg: 8 }}
                    sx={{
                        height: '100%',
                        flexDirection: 'column',
                    }}
                >
                    {getProductsMutation?.data?.length === 1 &&
                    getProductsMutation?.data[0]?.total_items_count === 0 ? (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '80vh',
                                }}
                            >
                                <Typography
                                    fontSize={'1.5rem'}
                                    fontWeight={'bold'}
                                >
                                    No items found
                                </Typography>
                                <Typography
                                    fontSize={'1.5rem'}
                                    fontWeight={'bold'}
                                >
                                    Please try a different selection{' '}
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <>
                            {' '}
                            <ProductGrid
                                productItemCount={productItemCount}
                                getProductsMutation={getProductsMutation}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                lastPage={lastPage}
                                firstPage={firstPage}
                                anchorRef={anchor}
                            />
                        </>
                    )}
                </Box>
            </Grid>
        </Container>
    );
}

export default ResourcesFilterComponent;
