import axios from 'axios';

const fileServerBaseUrl = 'https://file-server.hertex.co.za/api';

const baseURL = axios.create({
    baseURL: fileServerBaseUrl,
});

export const apiGetImage = async (fileName: string) => {
    const response = await baseURL.get(`file?filename=${fileName}`);
    return response.data;
};
