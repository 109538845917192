import AdminPortalPageLayout from "../components/AdminPortalPageLayout";
import TableQuery from "../components/closedOrdersTable/TableQuery";
import DashboardHeader from "../components/header/DashboardHeader";

function ClosedOrdersPage() {
  return (
    <AdminPortalPageLayout>
      <DashboardHeader
        title="Sales Orders - Closed"
        subTitle="Orders that have been completed"
      />

      <TableQuery />
    </AdminPortalPageLayout>
  );
}

export default ClosedOrdersPage;
