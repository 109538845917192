import AdminPortalPageLayout from "../components/AdminPortalPageLayout";
import DashboardHeader from "../components/header/DashboardHeader";
import TableQuery from "../components/openOrdersTable/TableQuery";

function OpenOrdersPage() {
  return (
    <AdminPortalPageLayout>
      <DashboardHeader
        title="Sales Orders - Open "
        subTitle="Orders that have been Submitted, and are currently pending"
      />

      <TableQuery />
    </AdminPortalPageLayout>
  );
}

export default OpenOrdersPage;
