import { Box, Button, Typography } from '@mui/material';
import HertexLogo from '../../../assets/logos/Hertex_Icon.jpg';

interface PaymentContentProps {
    firstParagraph?: string;
    secondParagraph?: string;
    header?: string;
    subHeader?: string;
    statusImage?: string;
    paragraghHeader?: string;
}

const PaymentContent = ({
    firstParagraph,
    secondParagraph,
    header,
    subHeader,
    statusImage,
    paragraghHeader,
}: PaymentContentProps) => {
    const clearAndRedirect = () => {
        window.location.href = '/';
    };

    return (
        <Box
            sx={{
                background: '#FFF',
                width: { xs: 'auto', lg: '400px' },
                display: 'flex',
                padding: '60px 40px 40px 40px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                gap: '10px',
                flexShrink: '0',
                borderRadius: '8px',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        alignSelf: 'start',
                        justifySelf: 'start',
                    }}
                >
                    <img
                        src={HertexLogo}
                        alt='Hertex Logo'
                        style={{
                            width: '72px',
                            height: '72px',
                        }}
                    />
                </Box>
                <Box>
                    <img src={statusImage} alt='status' />
                </Box>
            </Box>
            <Box>
                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '18px',
                        fontWeight: '600',
                        letterSpacing: '-0.2px',
                        textTransform: 'uppercase',
                    }}
                >
                    {header}
                </Typography>
                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '18px',
                        fontWeight: '600',
                        letterSpacing: '-0.2px',
                        textTransform: 'uppercase',
                    }}
                >
                    {subHeader}
                </Typography>
            </Box>

            <Box>
                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '14px',
                        fontWeight: '600',
                        letterSpacing: '-0.2px',
                    }}
                >
                    {paragraghHeader}
                </Typography>
            </Box>
            <Box>
                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '14px',
                        fontWeight: '400',
                        letterSpacing: '-0.2px',
                    }}
                >
                    <p>{firstParagraph}</p>
                    <p>{secondParagraph}</p>
                </Typography>
            </Box>

            <Box>
                <Typography
                    sx={{
                        color: '#000',
                        fontSize: '16px',
                        fontWeight: '600',
                        letterSpacing: '-0.2px',
                        textDecoration: 'underline',
                    }}
                >
                    <a
                        href='mailto:accounts@hertex.co.za'
                        style={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                        accounts@hertex.co.za
                    </a>{' '}
                </Typography>
            </Box>

            <Box sx={{ width: '100%' }}>
                <Button
                    variant='contained'
                    fullWidth
                    disableElevation
                    onClick={clearAndRedirect}
                >
                    Continue Shopping
                </Button>
            </Box>
        </Box>
    );
};

export default PaymentContent;
