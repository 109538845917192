import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import {
    apiGetApplicationNamesByItemGroup,
    apiGetCategoryMainPageData,
} from '../../../api/apiCategories';
import PageLayout from '../../../components/shared/layout/PageLayout';
import CarouselBanner from '../../../components/shared/banner/CarouselBanner';
import TextHeader from '../../../components/shared/textHeader/TextHeader';
import NewArrivalsGrid from '../../../components/shared/grid/NewArrivalsGrid';
import FilterComponent from '../filter/FilterComponent';
import LoadingScreen from '../../../components/shared/loadingScreen/LoadingScreen';
import FeaturedGallery from '../../../components/shared/gallery/FeaturedGallery';
import { useEffect, useState } from 'react';
import MissingPage from '../errorMessages/MissingPage';
import ItemGrid from '../grid/ItemGrid';
import HomewareCategoryGrid from '../grid/HomeWareCategoryGrid';
import { captureAnalytics } from '../../../utilities/Analytics';

function CategoryTemplate() {
    const { item_group } = useParams();
    const [filteredApplicationCards, setFilteredApplicationCards] = useState<
        string[]
    >([]);
    const location = useLocation();

    const itemGroupData = {
        category: { item_group: item_group },
    };

    const {
        isSuccess,
        isError,
        isLoading,
        data: categoryData,
    } = useQuery<any, Error>(
        [`main-category-${item_group}`],
        () => apiGetCategoryMainPageData(itemGroupData),
        { staleTime: 0, cacheTime: 0 }
    );

    const { data: applicationNames } = useQuery<any, Error>(
        [`application-names-${item_group}`],
        () => apiGetApplicationNamesByItemGroup(item_group || ''),
        { staleTime: 600000 }
    );

    useEffect(() => {
        if (isSuccess && categoryData && applicationNames?.appliactions) {
            const filteredApplicationCards =
                categoryData.section_application_cards.filter((card: any) =>
                    applicationNames.appliactions.includes(
                        card.application_name
                    )
                );
            setFilteredApplicationCards(filteredApplicationCards);
        }
    }, [isSuccess, categoryData, applicationNames]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [categoryData]);

    useEffect(() => {
        if (isSuccess && categoryData) {
            const title = categoryData.page_title;
            captureAnalytics('pageview', location.pathname, title);
        }
    }, [location.pathname, item_group, isSuccess, categoryData]);

    return (
        <>
            <PageLayout>
                {isLoading && <LoadingScreen />}
                {isError && <MissingPage />}
                {isSuccess && (
                    <>
                        <CarouselBanner
                            images={categoryData?.section_hero}
                            video={categoryData?.section_hero_video?.video_url}
                        />

                        <TextHeader
                            heading={categoryData?.page_title}
                            content={categoryData?.description}
                            isCentered={false}
                            width='70%'
                            marginTop={10}
                            marginBottom={5}
                        />
                        {item_group === '000' ? (
                            <>
                                {' '}
                                <HomewareCategoryGrid
                                    data={
                                        categoryData?.section_homeware_landing_categories
                                    }
                                    spacingValue={4}
                                    marginTop={5}
                                    marginBottom={5}
                                />
                            </>
                        ) : (
                            <>
                                {' '}
                                <ItemGrid
                                    data={filteredApplicationCards}
                                    hasTitles={false}
                                    hasButtons={false}
                                    hasBackgroundText={true}
                                    spacingValue={4}
                                    hasCollectionLinks={false}
                                    marginTop={5}
                                    marginBottom={5}
                                />
                            </>
                        )}
                        <FeaturedGallery
                            images={categoryData?.section_featured_collection}
                            hasVideo={false}
                            invert={false}
                            marginTop={5}
                            marginBottom={5}
                            title={
                                categoryData?.featured_collection_title
                                    ?.collection_name
                            }
                            description={
                                categoryData?.featured_collection_description
                            }
                            itemGroup={
                                categoryData?.featured_collection_title
                                    ?.item_group_code
                            }
                            application={
                                categoryData?.featured_collection_title
                                    ?.application_name
                            }
                            collection={
                                categoryData?.featured_collection_title
                                    ?.collection_name
                            }
                        />

                        <NewArrivalsGrid
                            header='New arrivals'
                            data={categoryData?.section_new_arrivals}
                            hasTitles={true}
                            hasButtons={true}
                            hasBackgroundText={false}
                            spacingValue={4}
                            marginTop={'5rem'}
                            marginBottom={'5rem'}
                        />
                        {item_group !== '000' && (
                            <FilterComponent
                                itemGroup={item_group}
                                title={categoryData?.page_title}
                                initialApplications={[]}
                            />
                        )}
                    </>
                )}
            </PageLayout>
        </>
    );
}

export default CategoryTemplate;
