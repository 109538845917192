import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useState } from 'react';
import ProductStepper from '../../../stepper/ProductStepper';
import Hertex from '../../../../../assets/logos/Hertex_Icon.jpg';

const ImageSection = ({ productListData, currentIndex }: any) => {
    const [activeImageStep, setActiveImageStep] = useState(0);
    const [imageLoading, setImageLoading] = useState(true);

    const next = () => {
        setActiveImageStep((prevActiveImageStep) => {
            const lastActiveImageStep =
                productListData[currentIndex]?.Product?.additionalImages
                    .length -
                1 +
                1;
            return prevActiveImageStep >= lastActiveImageStep
                ? 0
                : prevActiveImageStep + 1;
        });
    };

    const previous = () => {
        setActiveImageStep((prevActiveImageStep) => {
            const lastActiveImageStep =
                productListData[currentIndex]?.Product?.additionalImages
                    .length -
                1 +
                1;
            return prevActiveImageStep <= 0
                ? lastActiveImageStep
                : prevActiveImageStep - 1;
        });
    };

    // const images = productListData[currentIndex]?.Product?.additionalImages;
    // const imageSrc =
    //   images && images.length > 0
    //     ? [
    //         productListData[currentIndex]?.Product?.primaryImageUrl,
    //         ...images.map((img: any) => img.image_data),
    //       ][activeImageStep]
    //     : productListData[currentIndex]?.Product?.primaryImageUrl;
    return (
        <Box
            sx={{
                flex: 0,
                width: '100%',
                position: 'relative',
                pl: { xs: '0', lg: '50px' },
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: { xs: '100%', lg: '585px' },
                    height: { xs: '300px', lg: '600px' },
                    backgroundColor: '#333',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {/* ! There may be an instance where .Product is empty hence this ternary nightmare below. We need to address this issue */}

                    {imageLoading && (
                        <Skeleton
                            variant='rectangular'
                            width='100%'
                            height='100%'
                            animation='wave'
                        />
                    )}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                            src={
                                productListData[currentIndex]?.Product
                                    ? (
                                          productListData[currentIndex]?.Product
                                              ?.additionalImages || []
                                      ).length === 0
                                        ? productListData[currentIndex]?.Product
                                              ?.primaryImageUrl
                                        : [
                                              productListData[currentIndex]
                                                  ?.Product?.primaryImageUrl,
                                              ...productListData[
                                                  currentIndex
                                              ]?.Product?.additionalImages.map(
                                                  (img: any) => img.image_data
                                              ),
                                          ][activeImageStep]
                                    : ''
                            }
                            onLoad={() => setImageLoading(false)}
                            alt='/'
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        p: { xs: 0, sm: 2 },
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '60px', sm: '90px' },
                            minWidth: { xs: '60px', sm: '90px' },
                            height: { xs: '60px', sm: '90px' },
                            minHeight: { xs: '60px', sm: '90px' },
                            display: 'flex',
                            flexDirection: { xs: 'row', sm: 'column' },
                        }}
                    >
                        {productListData[currentIndex]?.Product?.symbols.map(
                            (symbol: any, index: any) => (
                                <img
                                    key={index}
                                    src={symbol.image_data}
                                    alt={symbol.file_name}
                                    style={{
                                        width: '100%',
                                        minWidth: '100%',
                                        paddingRight: '0',
                                        objectFit: 'contain',
                                        marginTop: '5px',
                                        marginLeft: '5px',
                                    }}
                                />
                            )
                        )}
                    </Box>{' '}
                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        top: { xs: '15%', lg: '30%' },
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                    }}
                >
                    {' '}
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mt: '150px',
                        }}
                    >
                        {productListData[currentIndex]?.Product
                            ?.additionalImages?.length > 0 && (
                            <>
                                <ChevronLeftIcon
                                    onClick={previous}
                                    sx={{
                                        ml: { xs: 4, lg: 4 },
                                        cursor: 'pointer',
                                        fontSize: '2rem',
                                        background: 'rgba(255, 255, 255, 0.80)',
                                        borderRadius: '4px',
                                        color: '#000',
                                    }}
                                />

                                <ProductStepper
                                    length={
                                        productListData[currentIndex]?.Product
                                            ?.additionalImages?.length + 1
                                    }
                                    currentIndex={activeImageStep}
                                />
                                <ChevronRightIcon
                                    onClick={next}
                                    sx={{
                                        mr: { xs: 4, lg: 4 },
                                        cursor: 'pointer',
                                        fontSize: '2rem',
                                        background: 'rgba(255, 255, 255, 0.80)',
                                        borderRadius: '4px',
                                        color: '#000',
                                    }}
                                />
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'relative',
                }}
                mt={3}
                mb={2}
            >
                <Typography
                    sx={{
                        fontSize: '13px',
                        fontWeight: '300',
                        lineHeight: '16px',
                    }}
                >
                    Due to variations in computer screens, we cannot guarantee
                    that colours shown here are truly representative of our
                    products. Please visit a{' '}
                    <a
                        href='/store-locator'
                        target='_self'
                        style={{
                            color: '#000',
                            fontWeight: '600',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        Hertex showroom
                    </a>{' '}
                    to view samples
                </Typography>
            </Box>
        </Box>
    );
};

export default ImageSection;
