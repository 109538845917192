import {
    Typography,
    Grid,
    Button,
    Divider,
    Box,
    Modal,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress,
} from '@mui/material';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import CartService from '../../../service/CartService';
import { useMutation, useQuery } from 'react-query';
import HertexLogo from '../../../assets/logos/Hertex_Icon.jpg';
import { useNavigate } from 'react-router-dom';

interface User {
    given_name: string;
    family_name: string;
    email: string;
}

function EditDetails({ previousStep }: any) {
    const navigate = useNavigate();
    const { user, isLogin } = useAuth();
    const authToken = localStorage.getItem('authToken') || '';
    const customerId = localStorage.getItem('customerId') || '';
    const cartService = useMemo(
        () => new CartService(authToken, customerId),
        [authToken, customerId]
    );

    const { data: getPaymentTypeData, isLoading: isPaymentTypeLoading } =
        useQuery('payment', () => cartService.getPaymentType(), {
            enabled: !!authToken,
        });

    const { data: cartItemData } = useQuery(
        'cart',
        () => cartService.checkAndRetrieveCart(),
        {
            enabled: !!authToken && isLogin,
        }
    );

    const { data: creditData } = useQuery('credit', () =>
        cartService.getCreditUser()
    );

    const { total, loadingItemId } = useShoppingCart();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const paymentMutation = useMutation(
        async () => {
            return await cartService.payForItems(
                (user as User)?.given_name,
                (user as User)?.family_name,
                (user as User)?.email,
                cartItemData?.id,
                total.toFixed(2).toString(),
                cartItemData?.items.map((item: any) => item.sku).join(', ')
            );
        },
        {
            onSuccess: (data) => {},
            onError: (error) => {
                console.error('Payment redirect failed:', error);
            },
        }
    );

    // const handlePayment = async () => {
    //   handleOpen();
    //   paymentMutation.mutate();
    // };

    const handlePayment = async () => {
        handleOpen();

        paymentMutation.mutate();
    };

    const [isLoading, setIsLoading] = useState(false);

    const creditPayMutation = useMutation(
        async () => {
            setIsLoading(true);
            return await cartService.finalizeUserCart(cartItemData?.id);
        },
        {
            onSuccess: async (data) => {
                const checkStatus = async (
                    attempts: number = 40,
                    interval: number = 500
                ) => {
                    try {
                        const status = await cartService.getPaymentStatus(
                            cartItemData?.id
                        );
                        if (status.status === 'FINALIZED') {
                            navigate('/success');
                        } else if (attempts > 0) {
                            setTimeout(
                                () => checkStatus(attempts - 1, interval),
                                interval
                            );
                        } else {
                            throw new Error('Payment status check timed out');
                        }
                    } catch (error) {
                        console.error('Error fetching payment status:', error);
                        navigate('/failed');
                    }
                };

                checkStatus();
            },
            onError: (error) => {
                console.error(error);
                navigate('/failed');
                setIsLoading(true);
            },
        }
    );
    const handleCreditPayment = async () => {
        handleOpen();
    };

    const [paymentMethod, setPaymentMethod] = useState('PIA');
    localStorage.setItem('paymentMethod', paymentMethod);
    const handlePaymentMethodChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPaymentMethod((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
        cartService.updatePaymentType(
            authToken,
            cartItemData.id,
            paymentMethod
        );
    }, [paymentMethod, cartService, authToken, cartItemData?.id]);
    const renderPayWithCreditScreen = () => (
        <>
            <Button
                variant='contained'
                disableElevation
                sx={{
                    px: '10px',
                }}
                onClick={handleCreditPayment}
                disabled={loadingItemId !== null}
            >
                Continue to pay on account
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='payment-modal-title'
                aria-describedby='payment-modal-description'
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '0',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '15px',
                    }}
                >
                    <Box>
                        <img
                            src={HertexLogo}
                            alt='Hertex Logo'
                            style={{ width: '40px', height: '40px' }}
                        />
                    </Box>
                    <Box>
                        {total > Number(creditData?.user_credit) ? (
                            <>
                                <Typography textAlign={'center'}>
                                    Available Credit: R
                                    {Number(creditData?.user_credit).toFixed(2)}
                                </Typography>

                                <Typography color='error' sx={{ mt: 2 }}>
                                    Your credit is insufficient for this
                                    transaction.
                                </Typography>
                            </>
                        ) : (
                            <Typography>
                                Available Credit: R
                                {Number(creditData?.user_credit).toFixed(2)}
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        <Button
                            variant='contained'
                            disableElevation
                            sx={{
                                backgroundColor: '#000',
                                color: '#FFF',
                                padding: '24px',
                                width: '100%',
                                textTransform: 'uppercase',
                                px: '10px',
                            }}
                            disabled={
                                isLoading ||
                                total > Number(creditData?.user_credit)
                            }
                            onClick={() => creditPayMutation.mutate()}
                        >
                            {isLoading ? (
                                <Typography>Processing</Typography>
                            ) : (
                                <Typography>Pay Now</Typography>
                            )}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );

    const renderPayWithPayFastScreen = () => (
        <>
            <Button
                variant='contained'
                disableElevation
                sx={{
                    px: '10px',
                }}
                onClick={handlePayment}
                disabled={loadingItemId !== null}
            >
                Continue to pay with payfast
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='payment-modal-title'
                aria-describedby='payment-modal-description'
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '0',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '15px',
                    }}
                >
                    <Box>
                        <img
                            src={HertexLogo}
                            alt='Hertex Logo'
                            style={{ width: '40px', height: '40px' }}
                        />
                    </Box>
                    <Box sx={{ width: '100%' }} id='payment-container'></Box>
                </Box>
            </Modal>
        </>
    );

    return (
        <>
            {isPaymentTypeLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : getPaymentTypeData?.payment_type === '30E' ? (
                <>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: '400',
                            lineHeight: '27px',
                            my: 1,
                        }}
                    >
                        Please Choose Payment Type
                    </Typography>

                    <FormControl
                        sx={{
                            width: '100%',
                        }}
                    >
                        <RadioGroup
                            aria-labelledby='payment-method-radio-group-label'
                            defaultValue='PIA'
                            name='payment-method-radio-group'
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                        >
                            <FormControlLabel
                                value='30E'
                                control={<Radio />}
                                label='Pay on account'
                                sx={{
                                    width: '100%',
                                    border: '1px solid #D9D9D9',
                                }}
                            />
                            <FormControlLabel
                                value='PIA'
                                control={<Radio />}
                                label='Pay with PayFast'
                                sx={{
                                    width: '100%',
                                    border: '1px solid #D9D9D9',
                                }}
                            />
                        </RadioGroup>
                    </FormControl>
                </>
            ) : (
                <>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: '400',
                            lineHeight: '27px',
                            mb: 2,
                        }}
                    >
                        Please Choose Payment Type
                    </Typography>

                    <FormControl>
                        <RadioGroup
                            aria-labelledby='payment-method-radio-group-label'
                            defaultValue='PIA'
                            name='payment-method-radio-group'
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                        >
                            <FormControlLabel
                                value='PIA'
                                control={<Radio />}
                                label='Pay with PayFast'
                            />
                        </RadioGroup>
                    </FormControl>
                </>
            )}

            <Divider sx={{ mt: 2 }} />
            <Grid container mt={2} justifyContent='space-between'>
                <Box
                    onClick={previousStep}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '0px',
                        cursor: 'pointer',
                    }}
                >
                    <Typography>Back</Typography>
                </Box>

                {paymentMethod === 'PIA'
                    ? renderPayWithPayFastScreen()
                    : renderPayWithCreditScreen()}
            </Grid>
        </>
    );
}

export default EditDetails;
