import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import PageLayout from '../../layout/PageLayout';
import { Grid, Container, Box } from '@mui/material';
import LoadingScreen from '../../loadingScreen/LoadingScreen';
import ImageSection from './components/ImageSection';
import ProductSection from './components/ProductSection';
import ProductDetails from './components/ProductDetails';
import MissingPage from '../../errorMessages/MissingPage';
import { apiGetYouMayAlsoLikeData } from '../../../../api/apiCollections';


import FilterComponent from '../../filter/FilterComponent';
import { apiFilterProducts } from '../../../../api/apiMultiFilter';
import { captureAnalytics } from '../../../../utilities/Analytics';
import ProductPageFilterComponent from './components/filter/FilterComponent';

function ProductPage() {
    let { item_group, application, collection, design, colour }: any =
        useParams();

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentSelectedItem, setCurrentSelectedItem] = useState<any>();
    const [selectedImagePreviewIndex, setSelectedImagePreviewIndex] =
        useState<number>(0);
    const [width, setWidth] = useState('');
    const [lengthcm, setLengthCM] = useState('');
    const location = useLocation();
    const [diameter, setDiameter] = useState('');
    const {
        isSuccess,
        isError,
        data: productData,
        isLoading,
    } = useQuery(
        [
            'product-filter',
            item_group,
            application,
            design,
            colour,
            width,
            lengthcm,
            diameter,
        ],
        () => {
            let filter;

            if (width === '' && lengthcm === '' && diameter === '') {
                filter = {
                    item_group: item_group,
                    application: application,
                    design: design,
                };
            } else if (diameter !== '') {
                filter = {
                    item_group: item_group,
                    application: application,
                    design: design,
                    diameter: diameter,
                };
            } else {
                filter = {
                    item_group: item_group,
                    application: application,
                    design: design,
                    length_cm: lengthcm,
                    width: width,
                };
            }

            return apiFilterProducts({
                filter: filter,
                off_setter: { start: 0, end: 100 },
                focus_keys: ['width', 'length_cm', 'diameter'],
                required_full_key_info: ['width', 'length_cm', 'diameter'],
            });
        },
        {
            enabled: !!item_group && !!application && !!collection && !!design,
            staleTime: 0,
            onSuccess: (data) => {
                if (data.slice(0, -1)?.length === 0) {
                    setWidth('');
                    setLengthCM('');
                    setDiameter('');
                    setCurrentIndex(0);
                    setSelectedImagePreviewIndex(0);
                } else if (colour !== undefined) {
                    const initialColourIndex = data.findIndex(
                        (data: any) => data?.StockMaster?.colour === colour
                    );
                    if (initialColourIndex === -1) {
                        setCurrentIndex(0);
                        setSelectedImagePreviewIndex(0);
                    } else {
                        setCurrentIndex(initialColourIndex);
                        setSelectedImagePreviewIndex(initialColourIndex);
                    }
                } else {
                    setCurrentIndex(0);
                    setSelectedImagePreviewIndex(0);
                }
            },
        }
    );

    const productDataItems = productData?.slice(0, -1) || [];

    //! Client request to hide - relook at a later stage
    // const { isSuccess: youMayAlsoLikeSuccess, data: youMayAlsoLikeData } =
    //     useQuery<any, Error>(
    //         [`you-may-also-like-${design}`],
    //         () =>
    //             apiGetYouMayAlsoLikeData(
    //                 parseInt(productData[currentIndex]?.StockMaster?.stock_link)
    //             ),
    //         {
    //             // This ensures the query runs only if productData is defined and not empty
    //             enabled:
    //                 !!productData &&
    //                 productData.length > 0 &&
    //                 productData[currentIndex]?.StockMaster?.stock_link !==
    //                     undefined,
    //             staleTime: 600000,
    //         }
    //     );

    const productDetailsList =
        productDataItems && productDataItems.length > 0
            ? [
                  {
                      key: 'Product Code (SKU)',
                      value: productDataItems[currentIndex]?.Product?.sku,
                  },
                  {
                      key: 'Description',
                      value: productDataItems[currentIndex]?.StockMaster
                          ?.description_1,
                  },
                  {
                      key: 'Design',
                      value: productDataItems[currentIndex]?.StockMaster
                          ?.design,
                  },
                  {
                      key: 'Colour',
                      value: productDataItems[currentIndex]?.StockMaster
                          ?.colour,
                  },
                  {
                      key: 'Range',
                      value: productDataItems[currentIndex]?.StockMaster?.range,
                  },

                  {
                      key: 'Brand',
                      value: productDataItems[currentIndex]?.StockMaster?.brand,
                  },
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Rugs'
                      ? [
                            {
                                key: 'Size',
                                value: `${productDataItems[currentIndex]?.StockMaster?.width} x ${productDataItems[currentIndex]?.StockMaster?.length_cm}`,
                            },
                        ]
                      : []),
                  ...(productDataItems[currentIndex]?.StockMaster?.brand ===
                  'HAUS'
                      ? [
                            {
                                key: 'Weight',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.c_weight,
                            },
                        ]
                      : []),
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Fabric'
                      ? [
                            {
                                key: 'Width',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.width,
                            },
                        ]
                      : []),
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Wallpaper'
                      ? [
                            {
                                key: 'Roll Dimension',
                                value: `${productDataItems[currentIndex]?.StockMaster?.width} x ${productDataItems[currentIndex]?.StockMaster?.length_cm}`,
                            },
                        ]
                      : []),

                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Fabric' ||
                  productDataItems[currentIndex]?.StockMaster?.group_desc ===
                      'Wallpaper'
                      ? [
                            {
                                key: 'Vertical Repeat',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.repeat_t,
                            },
                        ]
                      : []),

                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Wallpaper' ||
                  productDataItems[currentIndex]?.StockMaster?.group_desc ===
                      'Rugs'
                      ? [
                            {
                                key: 'Composition',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.composition,
                            },
                        ]
                      : []),
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Wallpaper'
                      ? [
                            {
                                key: 'Installation',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.hanging_instructions,
                            },
                        ]
                      : []),
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Fabric'
                      ? [
                            {
                                key: 'Horizontal Repeat',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.repeat_h,
                            },
                        ]
                      : []),
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Fabric'
                      ? [
                            {
                                key: 'Railroaded',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.railroaded,
                            },
                        ]
                      : []),
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Fabric'
                      ? [
                            {
                                key: 'Composition',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.composition,
                            },
                        ]
                      : []),
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Fabric'
                      ? [
                            {
                                key: 'Weight',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.c_weight,
                            },
                        ]
                      : []),

                  {
                      key: 'Care Info',
                      value: Array.isArray(
                          productDataItems[currentIndex]?.Product
                              ?.cleaning_instructions
                      ) ? (
                          <Box>
                              <Box
                                  sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      gap: '7px',
                                  }}
                              >
                                  {productDataItems[
                                      currentIndex
                                  ]?.Product?.cleaning_instructions.map(
                                      (instruction: any, index: any) =>
                                          instruction.image_data ? (
                                              <img
                                                  key={index}
                                                  src={instruction.image_data}
                                                  alt={instruction.file_name}
                                                  style={{
                                                      width: '30px',
                                                      height: '30px',
                                                      paddingRight: '0',
                                                  }}
                                              />
                                          ) : null
                                  )}
                              </Box>
                              <Box>
                              <span>
                                      {`${productDataItems[
                                          currentIndex
                                      ]?.Product?.cleaning_instructions
                                          .filter(
                                              (instruction: any) =>
                                                  !instruction.image_data
                                          )
                                          .map(
                                              (instruction: any) =>
                                                  instruction.cleaning_instruction_name
                                          )
                                          .join('. ')}${
                                              productDataItems[
                                                  currentIndex
                                              ]?.Product?.cleaning_instructions.some(
                                                  (instruction: any) =>
                                                      !instruction.image_data
                                              )
                                                  ? '.'
                                                  : ''
                                          }`}
                                  </span>
                              </Box>
                          </Box>
                      ) : null,
                  },
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Fabric'
                      ? [
                            {
                                key: 'Martindale Rubs',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.martindale,
                            },
                        ]
                      : []),
                  ...(productDataItems[currentIndex]?.StockMaster
                      ?.group_desc === 'Fabric'
                      ? [
                            {
                                key: 'Recommended Use',
                                value: productDataItems[currentIndex]
                                    ?.StockMaster?.application,
                            },
                        ]
                      : []),

                  {
                      key: 'Comments',
                      value: productDataItems[currentIndex]?.Product
                          ?.disambiguating_description,
                  },
                  {
                      key: 'Standards',
                      value: Array.isArray(
                          productDataItems[currentIndex]?.Product?.standards
                      ) ? (
                          <Box
                              sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '10px',
                              }}
                          >
                              {productDataItems[
                                  currentIndex
                              ]?.Product?.standards.map(
                                  (standard: any, index: any) => (
                                      <img
                                          key={index}
                                          src={standard.image_data}
                                          alt={standard.file_name}
                                          style={{
                                              height: '60px',
                                              paddingRight: '0',
                                          }}
                                      />
                                  )
                              )}
                          </Box>
                      ) : null,
                  },
              ]
            : [];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [colour]);

    useEffect(() => {
        captureAnalytics('pageview', location.pathname, `product-${design}`);
    }, [location.pathname, design]);

    return (
        <>
            {isLoading && <LoadingScreen />}
            {isError && <MissingPage />}
            {isSuccess && (
                <>
                    <PageLayout>
                        {isError && <MissingPage />}
                        <Container maxWidth='xl'>
                            <Grid
                                mt={{ xs: 5, sm: 10 }}
                                container
                                sx={{
                                    flexDirection: {
                                        xs: 'column',
                                        lg: 'row',
                                    },
                                }}
                            >
                                <ImageSection
                                    productListData={productDataItems}
                                    currentIndex={currentIndex}
                                    setCurrentIndex={setCurrentIndex}
                                    currentSelectedItem={currentSelectedItem}
                                    selectedImagePreviewIndex={
                                        selectedImagePreviewIndex
                                    }
                                />

                                <ProductSection
                                    productListData={productDataItems}
                                    productData={productData}
                                    currentIndex={currentIndex}
                                    setCurrentIndex={setCurrentIndex}
                                    itemGroup={item_group}
                                    application={application}
                                    collection={collection}
                                    design={design}
                                    colour={colour}
                                    currentSelectedItem={currentSelectedItem}
                                    setCurrentSelectedItem={
                                        setCurrentSelectedItem
                                    }
                                    setSelectedImagePreviewIndex={
                                        setSelectedImagePreviewIndex
                                    }
                                    selectedImagePreviewIndex={
                                        selectedImagePreviewIndex
                                    }
                                    setWidth={setWidth}
                                    setDiameter={setDiameter}
                                    setLengthCM={setLengthCM}
                                    diameter={diameter}
                                    lengthCM={lengthcm}
                                    width={width}
                                />
                            </Grid>

                            <ProductDetails
                                productDetailsList={productDetailsList}
                            />

                            {/* Client request to hide - relook at a later stage */}
                            {/* {youMayAlsoLikeSuccess && (
                                <ProductCarousel
                                    header='You may also like'
                                    marginTop={10}
                                    marginBottom={10}
                                    products={youMayAlsoLikeData}
                                />
                            )} */}

                            <ProductPageFilterComponent
                                itemGroup={item_group}
                                title={application}
                            />
                        </Container>
                    </PageLayout>
                </>
            )}
        </>
    );
}

export default ProductPage;
