import { Box } from "@mui/material";

interface PaymentLayoutProps {
  children: React.ReactNode;
}

const PaymentLayout = ({ children }: PaymentLayoutProps) => {
  return (
    <Box
      sx={{
        background: "#F5F5F5;",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: {xs: '20px', lg: '0px'}
      }}
    >
      <Box>{children}</Box>
    </Box>
  );
};

export default PaymentLayout;
