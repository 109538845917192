import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import {
    Typography,
    Box,
    Button,
    Stack,
    Divider,
    Grid,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { Colors } from '../../../styles/Theme/ThemeStyles';
import { useQuery } from 'react-query';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useAuth } from '../../../context/AuthContext';
import { pickUpPointsData } from '../../../api/pickUpPoints';

type User = {
    given_name: string;
    family_name: string;
    email: string;
};

function Address({ updateStep, previousStep }: any) {
    const {
        cartService,
        shippingSelected,
        defaultShipSelected,
        updateUserInformation,
    } = useShoppingCart();
    const { user } = useAuth() as {
        user: User | undefined;
    };
    const { isLoading, data: cartAddressDetails } = useQuery<any, Error>(
        [`cartAddressDetails`, shippingSelected, defaultShipSelected],
        () => cartService.getCartByUser(),
        {
            enabled: !!cartService.authToken,
        }
    );

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const [isDifferentBillingAddress, setIsDifferentBillingAddress] =
        useState<boolean>(false);
    const { handleSubmit } = useForm<any>();
    const [selectedStore, setSelectedStore] = useState<any>(null);
    const formSubmit = handleSubmit((data, e) => {
        e?.preventDefault();
        if (selectedStore) {
            const billingAddress = {
                name: '',
                surname: '',
                email: user?.email || '',
                address: selectedStore?.address,
                apartment: selectedStore?.address,
                suburb: selectedStore?.suburb,
                city: selectedStore?.city,
                postalCode: selectedStore?.postalCode,
                province: selectedStore?.province,
                country: selectedStore?.country,
            };
            updateUserInformation(billingAddress);
        }
        updateStep();
    });

    useEffect(() => {
        if (!shippingSelected || !defaultShipSelected) {
            setIsDifferentBillingAddress(true);
        }
    }, [shippingSelected, defaultShipSelected]);

    return (
        <Box>
            {shippingSelected || defaultShipSelected ? (
                <form noValidate onSubmit={formSubmit}>
                    <Stack
                        sx={{
                            border: `${Colors.info} 1px solid`,
                            borderTop: 'none',
                            mb: 2,
                        }}
                    >
                        {isLoading ? (
                            <Box
                                sx={{
                                    py: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        p: 2,
                                    }}
                                >
                                    <Box sx={{ flex: 2 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                lineHeight: '27px',
                                            }}
                                        >
                                            Contact:{' '}
                                            {cartAddressDetails?.contact_number}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                {(shippingSelected || defaultShipSelected) && (
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                p: 2,
                                            }}
                                        >
                                            <Box sx={{ flex: 2 }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        fontWeight: '400',
                                                        lineHeight: '27px',
                                                    }}
                                                >
                                                    Ship to:{' '}
                                                    {
                                                        cartAddressDetails
                                                            ?.billing_address
                                                            ?.address
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider />
                                    </>
                                )}
                            </>
                        )}
                    </Stack>
                    {/* Billing Address Select */}
                    {/* <Typography variant='h6'>Billing Address</Typography>
                    {(shippingSelected || defaultShipSelected) && (
                        <>
                            <Typography>
                                Select the address that matches your card of
                                payment method.
                            </Typography>
                            <Stack mt={2} mb={1}>
                                <FormControl id='billingAddress'>
                                    <RadioGroup
                                        aria-labelledby='shipping method'
                                        defaultValue={formData.billingAddress}
                                        {...register(`billingAddress`)}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                border: `${Colors.info} 1px solid`,
                                                p: 2,
                                            }}
                                        >
                                            <Box sx={{ flex: 2 }}>
                                                <FormControlLabel
                                                    value='same as shipping address'
                                                    control={<Radio />}
                                                    label='Same as shipping address'
                                                    {...register(
                                                        `billingAddress`
                                                    )}
                                                    onClick={() =>
                                                        setIsDifferentBillingAddress(
                                                            false
                                                        )
                                                    }
                                                />
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                border: `${Colors.info} 1px solid`,
                                                p: 2,
                                                borderTop: 'none',
                                            }}
                                        >
                                            <Box sx={{ flex: 2 }}>
                                                <FormControlLabel
                                                    value='use a different billing address'
                                                    control={<Radio />}
                                                    label='Use a different billing address'
                                                    {...register(
                                                        `billingAddress`
                                                    )}
                                                    onClick={() =>
                                                        setIsDifferentBillingAddress(
                                                            true
                                                        )
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        </>
                    )} */}

                    {/* Billing Details */}
                    {/* {isDifferentBillingAddress && (
                        <Stack>
                            <Typography>Billing address details</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <Box sx={{ flex: 1, mr: 2 }}>
                                    {' '}
                                    <InputField
                                        defaultValue={formData.name}
                                        marginTop={2}
                                        type='text'
                                        id='billingAddressName'
                                        name='billingAddressName'
                                        placeholder='Enter name'
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                        errorMsg='Please enter your name'
                                    />
                                </Box>

                                <Box sx={{ flex: 1, ml: 2 }}>
                                    {' '}
                                    <InputField
                                        defaultValue={formData.surname}
                                        marginTop={2}
                                        type='text'
                                        id='billingAddressSurname'
                                        name='billingAddressSurname'
                                        placeholder='Enter last name'
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                        errorMsg='Please enter your surname'
                                    />
                                </Box>
                            </Box>

                            <InputField
                                defaultValue={formData.address}
                                marginTop={2}
                                type='text'
                                id='billingAddressAddressLine1'
                                name='billingAddressAddressLine1'
                                placeholder='Enter address'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter your address'
                            />

                            <InputField
                                defaultValue={formData.additionalAddressInfo}
                                marginTop={2}
                                type='text'
                                id='billingAddressPropertyType'
                                name='billingAddressPropertyType'
                                placeholder='Enter apartment, suite, etc. (optional)'
                                isRequired={false}
                                register={register}
                                errors={errors}
                            />

                            <InputField
                                defaultValue={formData.city}
                                marginTop={2}
                                type='text'
                                id='billingAddressCity'
                                name='billingAddressCity'
                                placeholder='Enter city'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter your city'
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box sx={{ flex: 4, mr: 1 }}>
                                    <SelectField
                                        id='billingAddresscountry'
                                        register={register}
                                        errors={errors}
                                        isRequired={true}
                                        errorMsg='Please select a country'
                                        label='Country/Region'
                                        defaultValue={formData.country}
                                        marginTop={2}
                                        itemList={[
                                            { name: 'South Africa' },
                                            { name: 'Spain' },
                                            { name: 'England' },
                                        ]}
                                    />
                                </Box>
                                <Box sx={{ flex: 4, mr: 1, ml: 1 }}>
                                    <SelectField
                                        id='billingAddressProvince'
                                        register={register}
                                        errors={errors}
                                        isRequired={true}
                                        errorMsg='Please select a province'
                                        label='Province'
                                        defaultValue={formData.province}
                                        marginTop={2}
                                        itemList={[
                                            { name: 'Gauteng' },
                                            { name: 'Western Cape' },
                                            { name: 'Eastern Cape' },
                                            { name: 'Limpopo' },
                                            { name: 'North West' },
                                            { name: 'Kwazulu-Natal' },
                                            { name: 'Northern Cape' },
                                            { name: 'Mpumalanga' },
                                        ]}
                                    />
                                </Box>
                                <Box sx={{ flex: 4, ml: 1 }}>
                                    <InputField
                                        defaultValue={formData.postalCode}
                                        marginTop={2}
                                        type='text'
                                        id='billingAddresspPostalCode'
                                        name='billingAddresspPostalCode'
                                        placeholder='Enter postal code'
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                        errorMsg='Please enter your postal code'
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '-10px',
                                }}
                            >
                                <Checkbox defaultChecked />
                                <Typography>
                                    Save this information for next time
                                </Typography>
                            </Box>
                        </Stack>
                    )} */}

                    <Divider sx={{ mt: 2 }} />
                    <Grid container mt={2} justifyContent='space-between'>
                        <Box
                            onClick={previousStep}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: '0px',
                                cursor: 'pointer',
                            }}
                        >
                            <Typography>Back</Typography>
                        </Box>
                        <Button
                            variant='contained'
                            type='button'
                            onClick={() => updateStep()}
                            disableElevation
                            sx={{
                                borderRadius: '0px',
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </form>
            ) : (
                <>
                    <Box>
                        <Box
                            sx={{
                                py: '10px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '18px',
                                }}
                            >
                                Select collection
                            </Typography>
                        </Box>
                        <RadioGroup
                            onChange={(e) => {
                                let selectedStore = null;
                                for (const data of pickUpPointsData) {
                                    selectedStore = data.stores.find(
                                        (store) =>
                                            store.title === e.target.value
                                    );
                                    if (selectedStore) break;
                                }
                                setSelectedStore(selectedStore);
                            }}
                        >
                            {' '}
                            {pickUpPointsData.map((data) => (
                                <Accordion
                                    key={data.title}
                                    elevation={0}
                                    expanded={expanded === data.title}
                                    onChange={handleChange(data.title)}
                                >
                                    <AccordionSummary
                                        expandIcon={<KeyboardArrowDown />}
                                        aria-controls={`${data.title}-content`}
                                        id={`${data.title}-header`}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {data.title}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box>
                                            {data.stores.map((store) => (
                                                <Box
                                                    key={store.title}
                                                    mb={2}
                                                    sx={{
                                                        border: '1px solid #F5F5F5',
                                                        p: '10px',
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value={store.title}
                                                        control={<Radio />}
                                                        label={
                                                            <>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            '500',
                                                                        fontSize:
                                                                            '14px',
                                                                    }}
                                                                >
                                                                    {
                                                                        store.title
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            '400',
                                                                        fontSize:
                                                                            '12px',
                                                                    }}
                                                                >
                                                                    {`${store.address}, ${store.suburb}, ${store.city}, ${store.province}, ${store.postalCode}, ${store.country}`}
                                                                </Typography>
                                                            </>
                                                        }
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </RadioGroup>

                        <Divider sx={{ mt: 2 }} />
                        <Grid container mt={2} justifyContent='space-between'>
                            <Box
                                onClick={previousStep}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '0px',
                                    cursor: 'pointer',
                                }}
                            >
                                <Typography>Back</Typography>
                            </Box>
                            <Button
                                variant='contained'
                                type='submit'
                                onClick={() => formSubmit()}
                                disableElevation
                                sx={{
                                    borderRadius: '0px',
                                }}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default Address;
