import { Box, Container, Grid } from '@mui/material';
import ResourcesImagesGrid from '../components/ResourcesImagesGrid';
import ZipDownloader from '../../../components/shared/fileDownloads/ZipDownloader/ZipDownloader';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiFilterProducts } from '../../../api/apiMultiFilter';
import ResourcesDesignHeader from '../components/ResourcesDesignHeader';
import { useEffect, useState } from 'react';
import ColoursInDesignGrid from '../components/ColoursInDesignGrid';
import LoadingScreen from '../../../components/shared/loadingScreen/LoadingScreen';
import MissingPage from '../../../components/shared/errorMessages/MissingPage';
import ResourcesPageLayout from '../../../components/shared/layout/ResourcesPageLayout';

const ResourcesDesignTemplate = () => {
    const { item_group, application, collection, design, colour } = useParams();
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const {
        isSuccess,
        isError,
        data: productData,
        isLoading,
    } = useQuery(
        [item_group, application, design, colour],
        () => {
            return apiFilterProducts({
                filter: {
                    item_group: item_group,
                    application: application,
                    design: design,
                    colour: colour,
                },
                off_setter: { start: 0, end: 100 },
                focus_keys: [],
                required_full_key_info: [],
            });
        },
        {
            enabled:
                !!item_group &&
                !!application &&
                !!collection &&
                !!design &&
                !!colour,
            staleTime: 0,
        }
    );

    const { data: allColoursProductData } = useQuery(
        [item_group, application, design],
        () => {
            return apiFilterProducts({
                filter: {
                    item_group: item_group,
                    application: application,
                    design: design,
                },
                off_setter: { start: 0, end: 100 },
                focus_keys: [],
                required_full_key_info: [],
                available_on_resources: true,
            });
        },
        {
            enabled: !!item_group && !!application && !!collection && !!design,
            staleTime: 0,
        }
    );
    const allColoursItems = allColoursProductData?.slice(0, -1) || [];
    const productDataItems = productData?.slice(0, -1) || [];
    const primaryImage = productData && {
        url: productData[0]?.Product?.primaryImageUrl,
        filename: productData[0]?.Product?.primaryImage,
    };
    const additionalImages = productData
        ? productData[0]?.Product?.additionalImages.map((image: any) => {
              return { url: image.image_data, filename: image.file_name };
          })
        : [];
    const allProductUrls = [primaryImage, ...additionalImages];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [colour]);

    return (
        <ResourcesPageLayout>
            <>
                {isLoading && <LoadingScreen />}
                {isError && <MissingPage />}
                {isSuccess && (
                    <Container maxWidth='xl'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Box width={'95%'}>
                                <Grid
                                    container
                                    direction={{ xs: 'column', lg: 'row' }}
                                    spacing={5}
                                    py={5}
                                    justifyContent={'space-between'}
                                >
                                    <Grid item xl={8}>
                                        <ResourcesDesignHeader
                                            productDataItems={productDataItems}
                                            currentIndex={currentIndex}
                                            setCurrentIndex={setCurrentIndex}
                                            application={application}
                                            itemGroup={item_group}
                                            collection={collection}
                                        />
                                    </Grid>
                                    <Grid item xl={4}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: {
                                                    xs: 'start',
                                                    xl: 'end',
                                                },
                                            }}
                                        >
                                            <ZipDownloader
                                                fileData={[
                                                    ...allProductUrls,
                                                    ...(allColoursItems?.map(
                                                        (item: any) => ({
                                                            filename:
                                                                item.Product
                                                                    .primaryImage,
                                                            url: item.Product
                                                                .primaryImageUrl,
                                                        })
                                                    ) || []),
                                                ]}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>

                                <ResourcesImagesGrid
                                    header='Images'
                                    data={allProductUrls}
                                />
                                <ColoursInDesignGrid data={allColoursItems} />
                            </Box>
                        </Box>
                    </Container>
                )}
            </>
        </ResourcesPageLayout>
    );
};

export default ResourcesDesignTemplate;
