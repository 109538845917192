import { createContext, useContext, useState, useRef, useEffect } from 'react';
import Keycloak from 'keycloak-js';

import { ReactNode } from 'react';

import { useQuery } from 'react-query';
import { apiKeycloakConfig } from '../api/keycloakConfig';

type User = {
    customAttribute1: string;
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    name: string;
    preferred_username: string;
    sub: string;
};

type AuthContextType = {
    login: () => void;
    logout: () => void;
    setIsLogin: (isLogin: boolean) => void;
    isLogin: boolean;
    setUser: (user: User | {} | undefined) => void;
    user: User | {} | undefined;
    setIsAccountOnHold: (isAccountOnHold: boolean) => void;
    isAccountOnHold: boolean;
    keycloak: any;
};

type AuthProviderProps = {
    children: ReactNode;
};

const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [keycloak, setKeycloak] = useState<any>();

    const { data: configData } = useQuery(
        [`keycloak-config`],
        () => apiKeycloakConfig(),
        {
            onSuccess: (data) => {
                const keycloakInstance = new Keycloak({
                    url: data.url,
                    realm: data.realm,
                    clientId: data.clientId,
                });
                setKeycloak(keycloakInstance);
            },
            refetchOnWindowFocus: false,
        }
    );

    const [isLogin, setIsLogin] = useState<boolean>(false);

    const [user, setUser] = useState<User | {} | undefined>();

    const [isAccountOnHold, setIsAccountOnHold] = useState<boolean>(false);

    // ! We are using use ref here to ensure the useEffect only runs once
    const isRun = useRef(false);

    const token = keycloak?.token;

    localStorage.setItem('authToken', token || '');

    const login = () => {
        keycloak?.login();
    };

    const logout = () => {
        setIsLogin(false);
        keycloak?.logout({ redirectUri: configData?.redirectUri });
    };

    useEffect(() => {
        if (!isRun.current && keycloak) {
            keycloak
                .init({
                    onLoad: 'login-required',
                })
                .then((res: boolean | ((prevState: boolean) => boolean)) => {
                    setIsLogin(res);
                    if (res) {
                        keycloak
                            .loadUserInfo()
                            .then((user: User | {} | undefined) => {
                                setUser(user);
                                localStorage.setItem(
                                    'user',
                                    JSON.stringify(user)
                                );
                            });
                    }
                });
            isRun.current = true;
        }
    }, [keycloak]);

    return (
        <AuthContext.Provider
            value={{
                setIsLogin,
                isLogin,
                login,
                logout,
                setUser,
                user,
                setIsAccountOnHold,
                isAccountOnHold,
                keycloak,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
