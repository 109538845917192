import { Box, Button, Typography } from "@mui/material";

interface SupportCardProps {
  title: string;
  value: string;
  link?: string;
}

const SupportCard = ({ title, value, link }: SupportCardProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        p: "24px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        borderRadius: "12px",
        my: "20px",
        width: { xs: "100%", lg: "80%" },
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: "#000",
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: "20px",
        }}
      >
        {title}
      </Typography>
      <Button
        variant="contained"
        disableElevation
        sx={{
          width: { xs: "100%", lg: "25%" },
          fontFamily: "Inter",
          textTransform: "none",
          fontSize: "14px",
          fontWeight: "600",
          px: '10px',

        }}
        onClick={() => {
          window.location.href = `mailto:${link}`;
        }}
      >
        {value}
      </Button>
    </Box>
  );
};

export default SupportCard;
