import { createTheme } from '@mui/material';

export const ThemeStyles = createTheme({
    typography: {
        fontFamily: '"Inter", sans-serif',
        h1: {
            fontWeight: 'bold',
            fontFamily: '"Perpetua", sans-serif',
        },
        h2: {
            fontWeight: 'bold',
            fontFamily: '"Perpetua", sans-serif',
        },
        h3: {
            fontWeight: 'bold',
            fontFamily: '"Perpetua", sans-serif',
        },
        h4: {
            fontWeight: 'bold',
            fontFamily: '"Perpetua", sans-serif',
        },
        h5: {
            fontWeight: 'bold',
            fontFamily: '"Perpetua", sans-serif',
        },
        h6: {
            fontWeight: 'bold',
            fontFamily: '"Perpetua", sans-serif',
        },
        caption: {
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Inter, sans-serif',
            color: '#344054',
        },
    },

    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#ffffff',
        },
        info: {
            main: '#D9D9D9',
        },
    },

    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: '0px !important',
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    borderRadius: '0',
                    textTransform: 'uppercase',
                    fontWeight: '600',
                    padding: '16px 40px',
                    height: '53px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },
        },
    },
});

export const Colors = {
    primary: '#000000',
    secondary: '#ffffff',
    success: '#4CAF50',
    info: '#D9D9D9',
    danger: '#FF5722',
    warning: '#FFC107',
    dark: '#0e1b20',
    light: '#aaa',
    muted: '#abafb3',
    border: '#DDDFE1',
    inverse: '#2F3D4A',
    shaft: '#333',
    dark_grey: '#475467',
    dim_grey: '#EAECF0',
    white: '#fff',
    black: '#000',
};
