import { Box, Typography } from '@mui/material';
import FileDownloader from '../../../components/shared/fileDownloads/FileDownloader/FileDownloader';

type ResourcesDownloadItemsProps = {
    data: Array<Record<string, any>>;
    fileData?: any;
};

const ResourcesDownloadItems = ({
    data,
}: ResourcesDownloadItemsProps) => {
    return (
        <>
            <Box
                sx={{
                    p: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                }}
            ></Box>

            <Box
                sx={{
                    pb: '50px',
                    display: 'flex',
                    justifyContent: 'end',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                {data?.map((item: any) => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',

                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },

                                justifyContent: 'space-between',
                                gap: 2,
                                width: '100%',

                                borderBottom: '1px solid #000',
                                p: 2,
                            }}
                        >
                            <Box>
                                <Typography>{item.pdf_name}</Typography>
                            </Box>

                            <Box>
                                <FileDownloader
                                    fileData={{
                                        filename: item.file_name,
                                        url: item.image_data,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default ResourcesDownloadItems;
