import { baseURL } from './baseUrl';

// export const apiUserName = '9715f5ce-2798-473a-beff-7408b4fbf7cf';
// export const apiUserPassword = '8f6bb210-994e-4a27-b23a-842db8aa9d7a';
// export const bodyObject = {
//     date_last_updated: '2022-08-16 11:37:25',
// };

// export const apiCallToAction = async () => {
//     const response = await baseURL.get('', {});
//     return response.data;
// };

// export const apiGetProductData = async (
//     mainCategory: string,
//     productId: string
// ) => {
//     const response = await baseURL.get(
//         `get-product-in-cat-by-id?cat=${mainCategory}&id=${productId}`
//     );
//     return response.data;
// };

// export const apiGetProductVariations = async (
//     mainCategory: string,
//     applicationName: string,
//     collectionName: string,
//     designName: string
// ) => {
//     const response = await baseURL.get(
//         `get-products-list-by-design?cat=${mainCategory}&application=${applicationName}&range=${collectionName}&design=${designName}&start=0&count=5`
//     );
//     return response.data;
// };

export type ProductStatsModel = {
    data: {
        items_groups_stats?: string[];
        items?: {
            get_count: boolean;
            item_group: string;
            required_item_info: string[];
        };
    };
};

export type ProductCount = {
    products_count: number;
};

export type ProductStatResponseModel = {
    Fabric: ProductCount;
    Rugs: ProductCount;
    Wallpaper: ProductCount;
    homeware: {
        Bathroom: ProductCount;
        Bedroom: ProductCount;
        Curtains: ProductCount;
        Decorative: ProductCount;
        Furniture: ProductCount;
        Illumination: ProductCount;
        Scatters: ProductCount;
        Tableware: ProductCount;
        ThrowsQuiltsAndFurs: ProductCount;
        WallDecor: ProductCount;
    };
    brand?: string[];
    total_homeware_count: number;
    total_products_count: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

export enum ItemGroupCode {
    HOMEWARE = '000',
    WALLPAPER = '001',
    RUGS = '004',
    FABRIC = '005',
    BEDROOM = '006',
    ILLUMINATION = '007',
    TABLEWARE = '008',
    FURNITURE = '009',
    DECORATIVE = '010',
    TRADEHAUS_RUGS = '011',
    THROWS_QUILTS_AND_FURS = '013',
    WALL_DECOR = '014',
    CURTAINS = '015',
    BATHROOM = '016',
    SCATTERS = '018'
}

export interface FileData {
    filename: string;
    file?: string;
    url: string;
    item_group?: string;
    item_group_code?: string;
    application_name?: string;
    collection_name?: string;
    design_name?: string;
    product_name?: string;
    position?: string;
    brand?: string;
    standards?: string;
    standards_name?: string;
    cleaning_instructions?: string;
    cleaning_instructions_name?: string;
    symbols?: string;
    symbols_name?: string;
    design_styles_name?: string;
  }
  

export const apiGetProducts = async (
    item_group: string,
    application: string,
    collection: string,
    design: string
) => {
    const response = await baseURL.get(
        `products/item_group/design/get_all_products/${item_group}/${application}/${collection}/${design}`
    );
    return response.data;
};

export const apiGetSingleProduct = async (stock_link: string) => {
    const response = await baseURL.get(`product/${stock_link}`);
    return response.data;
};

export const apiPostProductStats = async (
    data: ProductStatsModel
): Promise<ProductStatResponseModel> => {
    const response = await baseURL.post(`/products/stats`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data as ProductStatResponseModel;
};

export const apiGetStockRollData = async (sku: string) => {
    const response = await baseURL.get(`stock-roll/${sku}`);
    return response.data;
};

export const apiGetAllItemGroupsInWebType = async (web_type_id: string): Promise<ItemGroupCode[]> => {
    const response = await baseURL.get(`/get-all-item-groups-in-web-type/${web_type_id}`);
    return response.data as ItemGroupCode[];
};
