import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageLayout from '../../components/shared/layout/PageLayout';
import TextHeader from '../../components/shared/textHeader/TextHeader';
import { captureAnalytics } from '../../utilities/Analytics';

const WallPaperCalculator = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation();

    useEffect(() => {
        captureAnalytics('pageview', location.pathname, 'Wallpaper Calculator');
    }, [location.pathname]);

    return (
        <>
            <PageLayout>
                <TextHeader
                    heading={'Wallpaper Calculator'}
                    content={
                        'Please use the calculator below to measure out your requirements:'
                    }
                    isCentered={false}
                    width='70%'
                    marginTop={10}
                    marginBottom={5}
                />

                <iframe
                    src='data/wallpaper_calculator.html'
                    style={{
                        width: '100%',
                        height: '170vh',
                        border: 'none',
                        marginBottom: '2rem',
                    }}
                    title='wallpaper-calculator'
                />
            </PageLayout>
        </>
    );
};

export default WallPaperCalculator;
