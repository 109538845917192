import { Grid, Box, Typography } from '@mui/material';
import MissingContent from '../errorMessages/MissingContent';

function HeroBanner({ data, hasVideo, video, hasTitle, title }: any) {
    return (
        <>
            {data === null || data.length === 0 ? (
                <MissingContent sectionName='Hero Banner Section' />
            ) : (
                <Grid
                    sx={{ width: '100%', height: '100vh', position: 'relative' }}
                >
                    {hasVideo ? (
                        <video
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: 'center',
                                position: 'absolute',
                                inset: 0,
                                zIndex: 0,
                            }}
                            src={video[0]?.src}
                            autoPlay
                            loop
                            muted
                        />
                    ) : (
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                                minHeight: '100%',
                                objectFit: 'cover',
                            }}
                            src={data[0]?.url}
                            alt='hero'
                        />
                    )}

                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                        }}
                    >
                        <Box
                            sx={{
                                zIndex: 999,
                                color: '#fff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            {hasTitle && (
                                <Typography fontWeight='100' variant='h1'>
                                    {title}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Grid>
            )}
        </>
    );
}

export default HeroBanner;
