import { Box, Container, Grid } from '@mui/material';
import ResourcesHeader from '../components/ResourcesHeader';
import ResourcesCatalogueGrid from '../components/ResourcesCatalogueGrid';
import ResourcesImagesGrid from '../components/ResourcesImagesGrid';
import CarouselBanner from '../../../components/shared/banner/CarouselBanner';
import ZipDownloader from '../../../components/shared/fileDownloads/ZipDownloader/ZipDownloader';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiGetCollectionPageData } from '../../../api/apiCollections';
import MissingPage from '../../../components/shared/errorMessages/MissingPage';
import LoadingScreen from '../../../components/shared/loadingScreen/LoadingScreen';
import { apiFetchCataloguesByCollectionName } from '../../../api/apiResources';
import { ItemGroupCode } from '../../../api/apiProducts';
import { useEffect } from 'react';
import ResourcesPageLayout from '../../../components/shared/layout/ResourcesPageLayout';

const ResourcesCollectionTemplate = () => {
    const { item_group, application, collection, item_group_name } =
        useParams();

    const argumentDataStructure = {
        category: {
            application: application,
            item_group: item_group,
            collection: collection,
        },
    };

    const {
        isSuccess,
        isError,
        isLoading,
        data: collectionData,
    } = useQuery<any, Error>([`collection-${collection}`], () =>
        apiGetCollectionPageData(argumentDataStructure)
    );

    const getItemGroupName = (code: string): string | undefined => {
        const entry = Object.entries(ItemGroupCode).find(
            ([, value]) => value === code
        );
        return entry ? entry[0] : undefined;
    };

    const catalogueDataStructure = {
        collection_name: collection,
        item_group_code: item_group,
        item_group_name: getItemGroupName(item_group as string),
        application_name: application,
    };

    const { data: catalogueData } = useQuery<any, Error>(['catalogue'], () =>
        apiFetchCataloguesByCollectionName(catalogueDataStructure)
    );

    const catalogueItems = catalogueData
        ? catalogueData?.map((image: any) => {
              return {
                  img: image.thumbnail_image_data,
                  url: image.resource_url,
              };
          })
        : [];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [collection]);
    return (
        <ResourcesPageLayout>
            <>
                {isLoading && <LoadingScreen />}
                {isError && <MissingPage />}
                {isSuccess && (
                    <Container maxWidth='xl'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Box width={'95%'}>
                                <Grid
                                    container
                                    direction={{ xs: 'column', lg: 'row' }}
                                    spacing={5}
                                    py={5}
                                    justifyContent={'space-between'}
                                >
                                    <Grid item xl={8}>
                                        <ResourcesHeader
                                            productDataItems={collectionData}
                                            application={application}
                                            itemGroup={item_group}
                                            collection={collection}
                                            itemGroupName={item_group_name}
                                        />
                                    </Grid>
                                    <Grid item xl={4}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: {
                                                    xs: 'start',
                                                    xl: 'end',
                                                },
                                            }}
                                        >
                                            <ZipDownloader
                                                fileData={[
                                                    ...collectionData?.section_hero,

                                                    ...collectionData?.section_gallery,
                                                    ...(catalogueData?.map(
                                                        (item: any) => ({
                                                            filename:
                                                                item.thumbnail_file_name,
                                                            url: item.thumbnail_image_data,
                                                        })
                                                    ) || []),
                                                ]}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <CarouselBanner
                                    images={collectionData?.section_hero || []}
                                />
                                <ResourcesImagesGrid
                                    header='Images'
                                    data={collectionData?.section_gallery}
                                />
                                {catalogueItems?.length !== 0 && (
                                    <ResourcesCatalogueGrid
                                        header='Catalogue'
                                        data={catalogueItems}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Container>
                )}
            </>
        </ResourcesPageLayout>
    );
};

export default ResourcesCollectionTemplate;
