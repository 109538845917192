import { Button } from '@mui/material';

type FileDownloaderProps = {
    fileData: {
        filename: string;
        url: string;
    };
};

const FileDownloader = ({ fileData }: FileDownloaderProps) => {
    const downloadImage = () => {
        const link = document.createElement('a');

        link.href = fileData.url;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    return (
        <>
            <Button
                variant='contained'
                onClick={() => {
                    downloadImage();
                }}
                disableElevation
                fullWidth
            >
                Download
            </Button>
        </>
    );
};

export default FileDownloader;
