import React from 'react'
import PaymentLayout from '../../components/shared/layout/PaymentLayout'
import PaymentContent from '../../components/shared/payment/PaymentContent'
import Error from '../../assets/error_pay.svg'


const PaymentFail = () => {
  return (
    <PaymentLayout>
        <PaymentContent 
        statusImage={Error}
        header=' It seems there was an issue'

subHeader='processing your payment.'
paragraghHeader='Please double-check your payment information and try again.'
        firstParagraph="If you have any questions or need further assistance, please don't hesitate to reach out to our support team for help"
        secondParagraph="We're here to ensure your shopping experience goes smoothly."

        />
    </PaymentLayout>
  )
}

export default PaymentFail