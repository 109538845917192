import OrderItem from "./OrderItem";
import {
  TextField,
  Box,
  Grid,
  InputAdornment,
  Button,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
function TableLayout({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  setGlobalFilter,
  page,
  state: { pageIndex, pageSize },
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  state,
  gotoPage,
  pageCount,
  setPageSize,
  setOpenModal,
  openModal,
  orderListData,
  itemId,
}: any) {
  const { globalFilter } = state;

  return (
    <>
      <Grid container justifyContent="flex-end" mb={{ xs: 1, lg: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", lg: "end" },
            background: "#FFF",
            width: "100%",
            py: { xs: "10%", lg: 0 },
            position: "static",
            mt: { xs: "25%", lg: "0" },
            zIndex: "999",
            m: "0",
          }}
        >
          <TextField
            size="small"
            type="text"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={"Search"}
            InputProps={{
              sx: {
                width: "100%",
                background: { xs: "#F2F4F7", lg: "transparent" },
                fontFamily: "Inter",
                color: "#667085",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: "1px solid #EAECF0",
          overflow: "auto",
          width: "100%",
        }}
      >
        <Table
          sx={{ minWidth: { xs: "100%", lg: 650 } }}
          aria-label="orders table"
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup: any) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: any, i: any) => {
              prepareRow(row);
              return (
                <TableRow
                  sx={{ backgroundColor: "#F9FAFB" }}
                  key={i}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        pb={{ xs: "20px", lg: "0" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pl: { xs: "10px", lg: "0" },
            gap: { xs: "10px", lg: "0" },
          }}
        >
          <Button
            sx={{
              display: { xs: "none", lg: "flex" },
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "16px",
              color: "#344054",
              textTransform: "capitalize",
              px: '10px',

            }}

            variant="outlined"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>{" "}
          <Button
            sx={{
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "16px",
              color: "#344054",
              textTransform: "capitalize",
              px: '10px',

            }}
            variant="outlined"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </Button>{" "}
          <Button
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "#344054",
              textTransform: "capitalize",              
              px: '10px',

            }}
            variant="outlined"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </Button>{" "}
          <Button
            sx={{
              display: { xs: "none", lg: "flex" },
              fontWeight: "600",
              fontSize: "16px",
              color: "#344054",
              textTransform: "capitalize",
              px: '10px',

            }}
            variant="outlined"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>{" "}
        </Box>
        <Box>
          <Typography
            variant="caption"
            sx={{
              pr: { xs: "10px", lg: "0" },
            }}
          >
            Page {pageIndex + 1} of {pageOptions.length}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center" }}>
          <Typography variant="caption" sx={{}}>
            {" "}
            Go to page:{" "}
          </Typography>

          <TextField
            style={{ width: "100px", marginLeft: "5px" }}
            size="small"
            type="number"
            inputProps={{
              sx: {
                fontFamily: "Inter",
              },
            }}
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
          />
          <FormControl size="small" style={{ marginLeft: "5px" }}>
            {" "}
            <Select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              inputProps={{
                sx: {
                  fontFamily: "Inter",
                },
              }}
            >
              {[10, 25, 50].map((pageSize) => (
                <MenuItem key={pageSize} value={pageSize}>
                  Show {pageSize}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      {/* Order Item Modal */}
      <OrderItem
        setOpenModal={setOpenModal}
        openModal={openModal}
        orderListData={orderListData}
        itemId={itemId}
      />
    </>
  );
}
export default TableLayout;
