import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import {
    Box,
    Button,
    AppBar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Toolbar,
    ListItemIcon,
    ListItemText,
    MenuList,
    Tooltip,
    Slide,
} from '@mui/material';

import navLogo from '../../../assets/logos/hertex-logo-long.png';
import MenuIcon from '@mui/icons-material/Menu';

import {
    Clear,
    LogoutOutlined,
    PersonOutlined,
    SettingsOutlined,
} from '@mui/icons-material';

function ResourcesNavBar() {
    const { login, logout, isLogin } = useAuth();

    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedPage, setSelectedPage] = useState<string | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const pages = [
        'Resources Home',
        'Product Images',
        'Catalogues',
        'General Info',
        'Price Lists',
    ];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleNavigate = (_: string, route?: string) => {
        switch (route) {
            case 'Resources Home':
                navigate('/resources');
                break;
            case 'Product Images':
                navigate('/resources/products');
                break;
            case 'Catalogues':
                navigate('/resources/catalogues');
                break;
            case 'General Info':
                navigate('/resources/general-info');
                break;
            case 'Price Lists':
                navigate('/resources/pricelists');
                break;
            default:
                navigate('/resources');
                break;
        }
    };

    return (
        <>
            <AppBar position='sticky' color='secondary' elevation={0}>
                {/* Relative positioning for mega menu*/}
                <Box sx={{ position: 'relative' }}>
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    lg: 'flex',
                                    alignItems: 'left',
                                },
                                ml: '2%',
                                width: { md: '15%', lg: '10%' },
                            }}
                        >
                            <img
                                style={{
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                                src={navLogo}
                                alt='Nav logo'
                                onClick={() => {
                                    navigate('/');
                                }}
                            />
                        </Box>

                        {/* Mobile View */}
                        <Box
                            sx={{
                                width: '100%',
                                px: '10px',
                                display: { xs: 'flex', lg: 'none' },
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box
                                sx={{
                                    display: {
                                        xs: 'flex',
                                        lg: 'none',
                                    },
                                    width: '150px',
                                    justifySelf: 'center',
                                    alignSelf: 'center',
                                    mr: 2,
                                }}
                            >
                                <img
                                    style={{
                                        width: '100%',
                                        cursor: 'pointer',
                                    }}
                                    src={navLogo}
                                    alt='Nav logo'
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                />
                            </Box>
                            <Box>
                                <IconButton
                                    size='large'
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={handleOpenNavMenu}
                                    color='inherit'
                                >
                                    {anchorElNav ? <Clear /> : <MenuIcon />}
                                </IconButton>
                            </Box>

                            {/* Mobile Menu */}
                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorElNav}
                                marginThreshold={0}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {
                                        xs: 'block',
                                        lg: 'none',
                                    },
                                }}
                                elevation={0}
                                PaperProps={{
                                    style: {
                                        width: '100%',
                                        maxWidth: '100%',
                                        height: '100%',
                                        maxHeight: 'calc(100vh - 50px)',
                                        marginTop: '50px',
                                        left: 0,
                                        right: 0,
                                        borderRadius: '0',
                                        overflow: 'auto',
                                    },
                                }}
                                TransitionComponent={Slide}
                                transitionDuration={300}
                            >
                                <Box>
                                    <MenuList>
                                        {pages.map((page) => (
                                            <MenuItem
                                                key={page}
                                                onClick={() => {
                                                    handleNavigate(
                                                        selectedPage!,
                                                        page
                                                    );
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                    >
                                                        <Box>
                                                            <ListItemText>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Sans-serif',
                                                                        fontWeight:
                                                                            'semibold',
                                                                        fontSize:
                                                                            '14px',
                                                                        textTransform:
                                                                            'uppercase',
                                                                        letterSpacing:
                                                                            '1.68px',
                                                                    }}
                                                                >
                                                                    {page}
                                                                </Typography>
                                                            </ListItemText>
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            background:
                                                                '#EEEEEE',
                                                            width: '1000px',
                                                            pl: '50px',
                                                            ml: '-50px',
                                                            overflow: 'hidden',
                                                        }}
                                                    ></Box>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                    {isLogin ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '10px',
                                                px: '10px',
                                            }}
                                        >
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                disableElevation
                                                sx={{
                                                    borderRadius: '0',
                                                    py: '20px',
                                                }}
                                                onClick={() => {
                                                    logout();
                                                }}
                                            >
                                                Logout
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '10px',
                                                px: '10px',
                                            }}
                                        >
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                disableElevation
                                                sx={{
                                                    borderRadius: '0',
                                                    py: '20px',
                                                }}
                                                onClick={() => {
                                                    login();
                                                }}
                                            >
                                                Login
                                            </Button>{' '}
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                disableElevation
                                                sx={{
                                                    borderRadius: '0',
                                                    py: '20px',
                                                }}
                                                onClick={() =>
                                                    navigate('/register')
                                                }
                                            >
                                                Register
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Menu>
                        </Box>

                        {/* Desktop Menu */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', lg: 'flex' },
                                justifyContent: 'center',
                                gap: '2%',
                            }}
                        >
                            {pages.map((page, index: number) => (
                                <Box key={index}>
                                    <Button
                                        key={page}
                                        onClick={() => {
                                            handleNavigate(selectedPage!, page);
                                        }}
                                        sx={{
                                            fontFamily: 'Sans-serif',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            px: '5px',
                                            letterSpacing: '3px',
                                        }}
                                    >
                                        {page}
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                display: { xs: 'none', lg: 'flex' },
                                mr: '2%',
                            }}
                        >
                            {isLogin ? (
                                <>
                                    <Tooltip title='Logout'>
                                        <IconButton
                                            sx={{ padding: '1rem' }}
                                            size='small'
                                            aria-label='logout'
                                            color='inherit'
                                            onClick={() => {
                                                logout();
                                            }}
                                        >
                                            <LogoutOutlined
                                                style={{
                                                    width: '23px',
                                                    height: '23px',
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Button
                                        sx={{ borderRadius: '0px', py: 0 }}
                                        disableElevation
                                        variant='contained'
                                        aria-label='login'
                                        onClick={handleClick}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                color: '#FFF',
                                                fontWeight: '700',
                                                textTransform: 'uppercase',
                                                letterSpacing: '1.68px',
                                            }}
                                        >
                                            Trade Portal
                                        </Typography>
                                    </Button>
                                    <Menu
                                        elevation={1}
                                        PaperProps={{
                                            sx: {
                                                width: anchorEl
                                                    ? anchorEl.clientWidth
                                                    : undefined,
                                                borderRadius: '0',
                                            },
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        id='trade-portal-menu'
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby':
                                                'trade-portal-button',
                                        }}
                                    >
                                        <MenuItem onClick={() => login()}>
                                            {' '}
                                            <ListItemIcon>
                                                <PersonOutlined
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: '#000',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    sx={{
                                                        color: '#000',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        textTransform:
                                                            'uppercase',
                                                        letterSpacing: '1.68px',
                                                    }}
                                                >
                                                    Login
                                                </Typography>
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                navigate('/register')
                                            }
                                        >
                                            {' '}
                                            <ListItemIcon>
                                                <SettingsOutlined
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: '#000',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    sx={{
                                                        color: '#000',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        textTransform:
                                                            'uppercase',
                                                        letterSpacing: '1.68px',
                                                    }}
                                                >
                                                    Register
                                                </Typography>
                                            </ListItemText>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            )}
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
        </>
    );
}

export default ResourcesNavBar;
