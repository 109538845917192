import { Box, Grid } from '@mui/material';
import CartDisplay from './CartDisplay';
function FormPageLayout({ children }: any) {
    return (
        <>
            <Grid container sx={{ height: '100vh' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column-reverse', lg: 'row' },
                        width: '100%',
                    }}
                >
                    <Box sx={{ width: '100%' }}>{children}</Box>
                    <Box sx={{ width: '100%', backgroundColor: '#F5F5F5' }}>
                        <CartDisplay />
                    </Box>
                </Box>
            </Grid>
        </>
    );
}

export default FormPageLayout;
