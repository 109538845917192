import {
    Box,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

export default function ImageCards({ images, hasBackgroundText }: any) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Box pb={10}>
            <ImageList cols={isLargeScreen ? 3 : 1} variant='standard' gap={10}>
                {images.map((item: any) => (
                    <ImageListItem key={item.img} sx={{
                        '&:hover img': {
                            transform: 'scale(1.03)', },
                            overflow: 'hidden',

                    }}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading='lazy'
                            style={{
                                transition: 'transform 0.5s ease',
                                width: '100%',
                                height: '436px'
                

                            }}
                        />
                        {hasBackgroundText && (
                            <ImageListItemBar
                                sx={{
                                    background:
                                    "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)",
                
                                    textAlign: 'center',
                                    color: '#fff',
                                    fontSize: '1rem',
                                    textTransform: 'uppercase',
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'end',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    
                                }}
                                onClick={() => {
                                    window.open(`${item.url}`, '_blank');
                                }}
                                title={
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            color: '#FFF',
                                            fontSize: '40px',
                                            fontWeight: '400',
                                            lineHeight: '38px',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                }
                            />
                        )}
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
}
