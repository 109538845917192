import TextHeader from '../../components/shared/textHeader/TextHeader';
import ResourcesDownloadItems from './components/ResourcesDownloadItems';
import { Box, Container } from '@mui/material';
import { useQuery } from 'react-query';
import {
    apiGetInternationalsPricelist,
    apiGetPricelist,
} from '../../api/apiResources';
import MissingPage from '../../components/shared/errorMessages/MissingPage';
import LoadingScreen from '../../components/shared/loadingScreen/LoadingScreen';

import { useEffect } from 'react';
import ResourcesPageLayout from '../../components/shared/layout/ResourcesPageLayout';
function ResourcesPricelists() {
    const {
        isSuccess,
        isError,
        isLoading,
        data: pricelistData,
    } = useQuery<any, Error>(['pricelist'], () => apiGetPricelist());
    const { data: interPricelistData } = useQuery<any, Error>(
        ['international pricelist'],
        () => apiGetInternationalsPricelist()
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <ResourcesPageLayout>
            <>
                {isLoading && <LoadingScreen />}
                {isError && <MissingPage />}
                {isSuccess && (
                    <Container maxWidth='xl'>
                        {pricelistData?.length !== 0 && (
                            <>
                                <TextHeader
                                    heading={'Price Lists'}
                                    content={
                                        'Please note that the pricelists available for download on this page are subject to change and may not reflect live prices. For current prices, please visit the specific products page.'
                                    }
                                    isCentered={false}
                                    width='100%'
                                    marginTop={10}
                                    marginBottom={0}
                                />
                                <Box>
                                    <ResourcesDownloadItems
                                        data={pricelistData}
                                    />
                                </Box>
                            </>
                        )}
                        {interPricelistData?.length !== 0 && (
                            <>
                                <TextHeader
                                    heading={'International Pricelists'}
                                    isCentered={false}
                                    width='100%'
                                    marginTop={0}
                                    marginBottom={0}
                                />
                                <Box>
                                    <ResourcesDownloadItems
                                        data={interPricelistData}
                                    />
                                </Box>
                            </>
                        )}
                    </Container>
                )}
            </>
        </ResourcesPageLayout>
    );
}
export default ResourcesPricelists;
