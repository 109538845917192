import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const MissingPage = () => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                height: '90vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Typography fontWeight={'bold'} fontSize={'2rem'}>
                Whoops! This page has gone missing.            </Typography>
            <Typography fontWeight={'bold'} fontSize={'2rem'}>
                Please click back to our HOME page.
            </Typography>
            <Button variant='contained' sx={{ mt: 2 }} onClick={() => navigate("/")}>Home</Button>
        </Box >
    );
};

export default MissingPage;
