import { useForm } from "react-hook-form";
import AdminPortalPageLayout from "../components/AdminPortalPageLayout";
// import InputField from '../../../components/shared/formFields/InputField';
import { Typography, Box, Stack, TextField } from "@mui/material";
import DashboardHeader from "../components/header/DashboardHeader";
import SettingsCard from "../components/card/SettingsCard";

// !We need additional information for this page - reverts may take place

function SettingsPage() {
  const user = JSON.parse(localStorage.getItem("user") || "");

  const {
    // register,
    handleSubmit,
    // formState: { errors },
  } = useForm<any>();

  const formSubmit = handleSubmit((data, e) => {
    e?.preventDefault();
  });

  return (
    <AdminPortalPageLayout>
      <DashboardHeader
        title="        Account Settings
      "
        subTitle="        Settings associated with your Hertex Trade Account
      "
      />
      <form onSubmit={formSubmit}>
        <Box sx={{ mt: { xs: "20vh", lg: "0" } }}>
          <SettingsCard title="Account Details">
            <Box sx={{ display: "flex", mt: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mr: { xs: 0, lg: 2 },
                  width: { xs: "100%", lg: "49%" },
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    color: "#344054",
                    mb: "10px",
                  }}
                >
                  Email
                </Typography>

                <TextField
                  defaultValue={user?.email}
                  type="email"
                  id="email"
                  name="email"
                  placeholder={user?.email}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#667085",
                    },
                  }}
                  size="medium"
                />
              </Box>
            </Box>
          </SettingsCard>

          {/* ! May Revert */}
          {/* <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                ml: 2,
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '1.125rem',
                                    lineHeight: '1.25rem',
                                }}
                            >
                                Password
                            </Typography>

                            <InputField
                                defaultValue={''}
                                marginTop={1}
                                type='password'
                                id='password'
                                name='password'
                                placeholder='Enter password'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter your password'
                            />
                        </Box> */}

          {/* 2 */}
          <SettingsCard title="Contact Person/s for Account">
            <Stack sx={{ mt: 4 }}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "500",
                  color: "#344054",
                  mb: "10px",
                }}
              >
                * Primary Contact Person/s
              </Typography>
              <TextField
                defaultValue={user?.given_name}
                type="text"
                id="name"
                name="name"
                placeholder="Enter name"
                InputProps={{
                  readOnly: true,
                  sx: {
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#667085",
                  },
                }}
                size="medium"
              />
            </Stack>
            {/* !May Revert */}
            {/* <Box sx={{ display: 'flex', mt: 4 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mr: 2,
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '1.125rem',
                                    lineHeight: '1.25rem',
                                }}
                            >
                                Telephone
                            </Typography>

                            <InputField
                                defaultValue={''}
                                marginTop={1}
                                type='tel'
                                id='telephone'
                                name='telephone'
                                placeholder='Enter telephone number'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter the telephone number'
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                ml: 2,
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '1.125rem',
                                    lineHeight: '1.25rem',
                                }}
                            >
                                Cellphone
                            </Typography>

                            <InputField
                                defaultValue={''}
                                marginTop={1}
                                type='tel'
                                id='cellphone'
                                name='cellphone'
                                placeholder='Enter cellphone number'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter the cellphone number'
                            />
                        </Box>
                    </Box> */}
            <Box
              sx={{
                display: "flex",
                mt: 4,
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mr: { xs: 0, lg: 2 },
                  width: "100%",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    color: "#344054",
                    mb: "10px",
                  }}
                >
                  Account email (invoices, statements)
                </Typography>
                <TextField
                  defaultValue={user?.email}
                  type="email"
                  id="accountEmail"
                  name="accountEmail"
                  placeholder={user?.email}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#667085",
                    },
                  }}
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  ml: { xs: 0, lg: 2 },
                  width: "100%",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "500",
                    color: "#344054",
                    mb: "10px",
                  }}
                >
                  Sales email (marketing info)
                </Typography>
                <TextField
                  defaultValue={user?.email}
                  type="email"
                  id="salesEmail"
                  name="salesEmail"
                  placeholder={user?.email}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#667085",
                    },
                  }}
                  size="medium"
                />

                {/* <InputField
                                defaultValue={''}
                                marginTop={1}
                                type='email'
                                id='salesEmail'
                                name='salesEmail'
                                placeholder='Enter sales email'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Email invalid'
                                validationPattern={
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                }
                            /> */}
              </Box>
            </Box>{" "}
          </SettingsCard>

          {/* !May revert */}
          {/* <Button type='submit' variant='contained' sx={{ mt: 2 }}>
                    Submit
                </Button> */}
        </Box>
      </form>
    </AdminPortalPageLayout>
  );
}

export default SettingsPage;
