import PageLayout from '../layout/PageLayout';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { apiGetSingleBrand } from '../../../api/apiBrands';
import LoadingScreen from '../loadingScreen/LoadingScreen';
import TextHeader from '../textHeader/TextHeader';
import DesignsGrid from '../grid/DesignsGrid';
import Gallery from '../gallery/Gallery';
import { useEffect } from 'react';
import RecommendedGrid from '../grid/RecommendedGrid';
import MissingPage from '../errorMessages/MissingPage';
import BrandsGrid from '../grid/BrandsGrid';
import FeaturedGallery from '../gallery/FeaturedGallery';
import CarouselBanner from '../banner/CarouselBanner';
import CollectionsGrid from '../grid/CollectionsGrid';
import { captureAnalytics } from '../../../utilities/Analytics';

function BrandsTemplate() {
    const { item_group, brand } = useParams();
    const location = useLocation();

    const argumentDataStructure = {
        category: { item_group: item_group, brand: brand },
    };

    const {
        isSuccess,
        isError,
        isLoading,
        data: brandData,
    } = useQuery<any, Error>([`brands-${brand}`], () =>
        apiGetSingleBrand(argumentDataStructure)
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [brand]);

    useEffect(() => {
        captureAnalytics('pageview', location.pathname, `brands-${brand}`);
    }, [location.pathname, brand]);

    return (
        <PageLayout>
            {isError && <MissingPage />}
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    {' '}
                    {isSuccess && (
                        <>
                            <CarouselBanner
                                images={brandData?.section_hero}
                                video={brandData?.section_hero_video?.video_url}
                            />
                            <TextHeader
                                heading={brandData?.page_title}
                                content={brandData?.description}
                                isCentered={false}
                                width='70%'
                                marginTop={10}
                                marginBottom={5}
                            />

                            <FeaturedGallery
                                images={brandData?.section_featured_collection}
                                hasVideo={false}
                                invert={false}
                                marginTop={5}
                                marginBottom={5}
                                title={
                                    brandData?.featured_collection_title
                                        ?.collection_name
                                }
                                description={
                                    brandData?.featured_collection_description
                                }
                                itemGroup={
                                    brandData?.featured_collection_title
                                        ?.item_group_code
                                }
                                application={
                                    brandData?.featured_collection_title
                                        ?.application_name
                                }
                                collection={
                                    brandData?.featured_collection_title
                                        ?.collection_name
                                }
                            />

                            {/* <BrandsGrid
                                data={brandData}
                                marginTop='5rem'
                                marginBottom='5rem'
                            /> */}
                            <BrandsGrid
                                data={brandData?.section_collections_in_a_brand}
                                applicationName={
                                    brandData?.section_collections_in_a_brand[0]
                                        ?.brand_name
                                }
                                marginTop={5}
                                marginBottom={5}
                            />

                            {/* <Gallery
                                images={collectionData?.section_gallery}
                                galleryLayout={'masonry'}
                                columns={2}
                                title={'title'}
                                secondaryText={'2bd'}
                                hasTitle={false}
                                hasSecondaryText={false}
                            /> */}

                            {/* <DesignsGrid
                                data={collectionData?.section_designs}
                                marginTop='5rem'
                                marginBottom='5rem'
                            /> */}

                            {/* <RecommendedGrid
                                header='You may also like'
                                data={collectionData?.section_you_may_also_like}
                                hasTitles={true}
                                hasButtons={true}
                                hasBackgroundText={false}
                                spacingValue={4}
                                marginTop={'5rem'}
                                marginBottom={'5rem'}
                            /> */}
                        </>
                    )}
                </>
            )}
        </PageLayout>
    );
}

export default BrandsTemplate;
